import React from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

function Section(props) {
  return (
    <div className="flex flex-col items-center justify-center w-full sm:w-11/12 md:w-10/12 lg:w-10/12  mx-auto my-2 max-h-full">
      <div
        className="bg-[#5ab963] p-4 cursor-pointer transition-transform w-full text-lg sm:text-xl md:text-2xl font-bold leading-tight sm:leading-snug text-white flex justify-between items-center"
        onClick={props.onClick}
      >
        <div>{props.header}</div>
        <div>
          {props.isOpen ? (
            <IoIosArrowUp className="text-2xl sm:text-3xl md:text-4xl" />
          ) : (
            <IoIosArrowDown className="text-2xl sm:text-3xl md:text-4xl" />
          )}
        </div>
      </div>
      <div
        className={`overflow-hidden transition-all duration-500 ${
          props.isOpen ? "max-h-[100%] opacity-100" : "max-h-0 opacity-0"
        } w-full`}
      >
        <div className="py-4">{props.data}</div>
      </div>
    </div>
  );
}

export default Section;