import React from "react";
import Heading from "../headingContainer/Heading";
import {
  headingData,
  slidingBoxRight,
  slidingBoxLeft,
  imgContentData,
} from "./useAkiyaConcierge";
import WithLeftBox from "../SlidingImgWithBox/WithLeftBox";
import WithRightBox from "../SlidingImgWithBox/WithRightBox";
import QuestionBox from "../QuestionBox/QuestionBox";
import ImgContent from "../ImgContent/ImgContent";
import { Link } from "react-router-dom";

const AkiyaConcierge = () => {
  const header1 = "Everyone knows there are over 8 million akiya";
  const header2 = "Here's the fuss-free way to actually own one.";

  return (
    <div className="flex justify-center my-8">
      <div className="max-w-[1440px] w-full flex flex-col gap-8">
        {/* Heading Section */}
        <div className="mx-4 lg:mx-8">
          <Heading data={headingData[0]} />
        </div>

        {/* WithRightBox Section */}
        <div className="my-3">
          <WithRightBox data={slidingBoxRight} />
        </div>

        {/* Second Heading */}
        <div className="flex flex-col gap-5 mx-4 lg:mx-8">
          <div className="w-full">
            <Heading data={headingData[1]} />
          </div>
        </div>

        {/* WithLeftBox Section */}
        <div className="my-3">
          <WithLeftBox data={slidingBoxLeft} />
        </div>

        {/* Third Heading */}
        <div className="flex flex-col gap-5 mx-4 lg:mx-8">
          <div className="w-full">
            <Heading data={headingData[3]} />
          </div>
        </div>

        {/* QuestionBox Section */}
        <div>
          <QuestionBox />
        </div>

        {/* ImgContent Sections */}
        <div className="flex flex-col justify-center gap-10 mx-4 lg:mx-8">
          <ImgContent data={imgContentData[0]} />
          <ImgContent data={imgContentData[1]} />
        </div>

        {/* Final Call-to-Action Section */}
        <div className="flex flex-col lg:flex-row items-center mb-12 mt-24 mx-4 lg:mx-8">
          <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
            <h2 className="dark-header mb-0">{header1}</h2>
            <p className="light-header mt-0">{header2}</p>
          </div>
          <div className="w-full lg:w-1/2 flex justify-center">
            <Link to={"/services/how-it-works"}>
              <button className="rounded py-4 px-8 bg-[#5ab963] font-bold text-lg text-white leading-6">
                Our Services
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AkiyaConcierge;