import React from 'react';

const StructuredData = () => {
  const jsonData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Akiya2.0 K.K.",
    "url": "https://www.akiya2.com",
    "logo": "https://www.akiya2.com/images/MainLogo.webp",
    "sameAs": [
      "https://www.linkedin.com/company/akiya2/",
      "https://www.instagram.com/akiya2.0/",
      "https://www.youtube.com/channel/UCU9UXi25eRRuBUT0k9GeDzg"
    ],
    "description": "Akiya2.0 specializes in revitalizing Japan's vacant houses (akiya) by offering services in property search, renovation, and investment opportunities, aiming to preserve cultural heritage and rejuvenate communities.",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+81-50-1791-0477",
      "email": "lester.goh@akiya2.com",
      "contactType": "Customer Service",
      "areaServed": "Worldwide",
      "availableLanguage": "English"
    },
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Seika Bldg. 5F, 3-5-17 Kojimachi",
      "addressLocality": "Chiyoda-ku",
      "addressRegion": "Tokyo",
      "postalCode": "102-0083",
      "addressCountry": "JP"
    },
    "keywords": "akiya, akiya renovation, buy house in Japan, house in Japan, Japanese real estate, vacant houses Japan, property investment Japan",
    "founders": [
      {
        "@type": "Person",
        "name": "Terrie Lloyd",
        "description": "Co-founder of Akiya2.0 with over 40 years of experience in Japan and founder of 19 companies in the country."
      },
      {
        "@type": "Person",
        "name": "Lester Goh",
        "description": "Co-founder of Akiya2.0 and a trained professional architect."
      }
    ]
  };

  return (
    <script type="application/ld+json">
      {JSON.stringify(jsonData)}
    </script>
  );
};

export default StructuredData;