import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Gallery from "./Gallery";
import AllInOne from "./AllInOne";
import Section from "./Section";
import FirstSection from "./Three_Sections_Data/FirstSection";
import SecondSection from "./Three_Sections_Data/SecondSection";
import ThirdSection from "./Three_Sections_Data/ThirdSection";
import Loader from "../Loader/Loader";

function IndividProp() {
  const { propertyId } = useParams();
  const [openSectionIndex, setOpenSectionIndex] = useState(0); // Set to 0 to open the first section by default
  const [propertyData, setPropertyData] = useState(null);

  const handleSectionClick = (index) => {
    setOpenSectionIndex(index === openSectionIndex ? null : index);
  };

  useEffect(() => {
    axios
      .get(`https://app.akiya2.com/one_property/${propertyId}`)
      .then((response) => {
        setPropertyData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [propertyId]);

  if (!propertyData) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-[1440px] px-4">
        <Gallery images={propertyData.images} video={propertyData.video} />
        <AllInOne property={propertyData} />
        <Section
          header="Maps and Access"
          data={<FirstSection data={propertyData} />}
          isOpen={openSectionIndex === 0}
          onClick={() => handleSectionClick(0)}
        />
        <Section
          header="Amenities"
          data={<SecondSection data={propertyData} />}
          isOpen={openSectionIndex === 1}
          onClick={() => handleSectionClick(1)}
        />
        <Section
          header="Blogs"
          data={<ThirdSection data={propertyData} />}
          isOpen={openSectionIndex === 2}
          onClick={() => handleSectionClick(2)}
        />
        <div className="flex justify-center items-center w-full h-full mt-8 mb-8">
          <Link to={"/our-projects"}>
            <button className="bg-[#5ab963] rounded font-bold text-white px-6 py-3">
              Go Back
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default IndividProp;