import React from 'react';

function FamousPlaces({ data }) {
  // console.log("data");
  // console.log(data);

  return (
    <div className='max-w-[100%] r_xs:max-w-[100%] r_xxs:max-w-[100%] w-[100%] mx-[1%] shadow-[rgba(0,0,0,0.05)_0px_0px_50px_10px] p-[1.5rem] r_xs:p-0 r_xxs:p-0 r_xxs:mx-0 r_xs:mx-0'>
      <div>
        {data && <img src={data.mainImageUrl} alt={`Image of ${data.placeName}`} />}
      </div>
      {data && <h2 className='text-[#5ab963] text-[24px] font-[800] my-[1rem]'>{data.placeName}</h2>}
      {data && <p className='h-[25%] text-[#949995] text-[16px] pb-[3rem]'>{data.aboutThePlace}</p>}
    </div>
  );
}

export default FamousPlaces;
