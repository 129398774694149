import React from "react";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import FirstSOV from "./FirstSOV";
import SecondSOV from "./SecondSOV";
import ThirdSOV from "./ThirdSOV";
import FourthSOV from "./FourthSOV";
import FifthSOV from "./FifthSOV";
import "./styles.css";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

function OurProjects() {
  const firstHeading = "Our Akiya Projects";
  const secondHeading = (
    <>
      Carefully selected.
      <br />
      Sustainably curated.
    </>
  );
  const header1 = "Everyone knows there are over 8 million akiya";
  const header2 = "Here's the fuss-free way to actually own one.";

  return (
    <>
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
      <div className="flex justify-center">

    <div className="home-container h-[100%] w-[100vw] max-w-[1440px] mt-[1rem]">

          <FirstSOV />


          <FourthSOV />
          <FifthSOV />
          <div className="w-[100vw] h-[1rem] m-[3.5rem]"></div>
          <SecondSOV />
          <ThirdSOV />
          <div className="flex mb-[3rem] mt-[6rem] r_xs:flex-col r_xxs:flex-col ">
            <div className="w-[50%] r_xs:w-[100%] r_xxs:w-[100%]">
              <h1 className="dark-header mb-0 r_xs:mr-3">{header1}</h1>
              <p className="light-header mt-0 r_xs:mr-3">{header2}</p>
            </div>
            <div className="w-[50%] r_xs:w-[100%] r_xxs:w-[100%] flex justify-center items-center md:mr-[8rem]">
            <Link to ={"/services/how-it-works"}>
              <button className=" w-[100vw] xxs:w-auto xxs:rounded-md py-[15px] px-[30px] bg-[#5ab963] font-[800] text-[16px] text-white leading-[30px]">
                Our Services
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurProjects;
