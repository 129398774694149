import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getMetaTags } from '../Utils/metaTagManager';

const MetaTagsUpdater = () => {
  const location = useLocation();
  // console.log("newone",location)

  // Safely initialize metaTags without accessing window during SSR
  const [metaTags, setMetaTags] = useState(
    typeof window !== 'undefined' ? window.__INITIAL_META_TAGS__ || {} : {}
  );

  useEffect(() => {
    const updateMetaTags = async () => {
      // Ensure meta tags are fetched based on the updated path
      const newMetaTags = await getMetaTags(location.pathname, location.state);
      // console.log("Fetched Meta Tags:", newMetaTags); // Log the fetched meta tags
      setMetaTags(newMetaTags);
  
      // Update title
      document.title = newMetaTags.title || 'Default Title';
  
      // Update meta description
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) metaDescription.setAttribute('content', newMetaTags.description || '');
  
      // Update meta keywords
      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) metaKeywords.setAttribute('content', newMetaTags.keywords || '');
  
      // Update OG tags
      const ogTitle = document.querySelector('meta[property="og:title"]');
      if (ogTitle) ogTitle.setAttribute('content', newMetaTags.ogTitle || '');
  
      const ogDescription = document.querySelector('meta[property="og:description"]');
      if (ogDescription) ogDescription.setAttribute('content', newMetaTags.ogDescription || '');
  
      const ogImage = document.querySelector('meta[property="og:image"]');
      if (ogImage) ogImage.setAttribute('content', newMetaTags.ogImage || '');
    };
  
    // Update meta tags after client-side navigation
    updateMetaTags();
  }, [location.pathname]); // Run every time the path changes
  return null;
};

export default MetaTagsUpdater;