// import { Navigate } from "react-router-dom";
// import { useUser } from "../Context/UserContext";
// import PageNotFound from "./extraPages/PageNotFound";
// import { useEffect, useState } from "react";
// import Loader from "./Loader/Loader";

// const Protected = ({ children }) => {
//   const user = useUser();
//   const [isToken, setIsToken] = useState(true);
//   const [isAdmin, setIsAdminn] = useState(true);

//   useEffect(() => {
//     const access_token = localStorage.getItem("access_token");
//     setIsAdmin(localStorage.getItem("isAdmin"));

//     if (!access_token) {
//       setIsToken(false);
//     }
//     if (access_token) {
//       setIsToken(true);
//     }
//   }, [user]);

//   if (!isToken) {
//     return <PageNotFound />;
//   }

//   if (!isAdmin) {
//     return <PageNotFound />;
//   }

//   if (!user) {
//     return <Loader />;
//   }

//   // If user is authenticated, render children
//   return children;
// };

// export default Protected;

import React, { useState } from "react";
import Loader from "./Components/Loader/Loader";
import PageNotFound from "./Components/extraPages/PageNotFound";

const AdminProtected = ({ children, password }) => {
  const [inputPassword, setInputPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attemptFailed, setAttemptFailed] = useState(false);

  const handlePasswordChange = (e) => {
    setInputPassword(e.target.value);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setAttemptFailed(false);

    // Simulate a delay for password verification
    await new Promise((resolve) => setTimeout(resolve, 1000));

    setIsLoading(false);
    if (inputPassword === password) {
      setIsAuthenticated(true);
    } else {
      setAttemptFailed(true);
      setInputPassword("");
    }
  };

  if (isAuthenticated) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (attemptFailed) {
    return <PageNotFound />;
  }

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-full max-w-[1440px] flex justify-center items-center h-[100vh] max-h-[540px]">
        <div className="rounded h-full max-h-[300px] bg-black/60 max-w-[20rem] w-full">
          <h2 className="text-center text-[24px] text-white my-3 font-[800]">Admin Access</h2>
          <form className="flex flex-col" onSubmit={handlePasswordSubmit}>
            
            <input
              type="password"
              className="px-1 py-[10px] mx-[3.5rem] mt-[3rem] rounded font-[600] outline-none"
              value={inputPassword}
              onChange={handlePasswordChange}
              placeholder="Enter admin password"
              required
            />
            <div className="flex justify-center items-center">
            <button className="w-[fit-content] px-3 py-2 font-[600] bg-green-400 rounded text-white mt-4" type="submit">Submit</button>

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminProtected;
