import React from "react";
import { Link } from "react-router-dom";
function AllInOne(props) {
  return (
    <div className="flex flex-col items-center justify-center w-full sm:w-11/12 md:w-10/12 lg:w-10/12 mx-auto px-4 mt-8">
      <div className="flex flex-col md:flex-row w-full">
        {/* Property Name Section */}
        <div className="w-full md:w-10/12">
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-extrabold text-[#333] text-center md:text-left">
            {props.property.propertyName}
          </h1>
        </div>

        {/* Button Section */}
        
        <div className="w-full md:w-2/12 flex justify-center items-center my-auto ">
          <button className="px-6 py-3 bg-[#5ab963] rounded text-white font-bold text-base md:text-lg">
          <Link to={"/about-us/#form"}>
            Contact us
        </Link>

          </button>
        </div>

      </div>
    </div>
  );
}

export default AllInOne;