import React, { useState, useEffect, useRef } from "react";
import "./individualProperty.css";

const placeholderImage = "/images/MainLogo.webp";

function Gallery({ images, video }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [visibleThumbnails, setVisibleThumbnails] = useState(3);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(
    Array(images.length).fill(false)
  );
  const mainImageRef = useRef(null);
  const thumbnailsRef = useRef(null);
  const [mainImageHeight, setMainImageHeight] = useState(0);

  useEffect(() => {
    const updateMainImageHeight = () => {
      if (mainImageRef.current) {
        setMainImageHeight(mainImageRef.current.offsetHeight);
      }
    };
    updateMainImageHeight();
    window.addEventListener("resize", updateMainImageHeight);
    return () => window.removeEventListener("resize", updateMainImageHeight);
  }, []);

  useEffect(() => {
    const updateVisibleThumbnails = () => {
      if (window.innerWidth >= 1200) {
        setVisibleThumbnails(9);
      } else if (window.innerWidth >= 1024) {
        setVisibleThumbnails(7);
      } else if (window.innerWidth >= 768) {
        setVisibleThumbnails(5);
      } else if (window.innerWidth >= 640) {
        setVisibleThumbnails(4);
      } else {
        setVisibleThumbnails(3);
      }
    };
    updateVisibleThumbnails();
    window.addEventListener("resize", updateVisibleThumbnails);
    return () => window.removeEventListener("resize", updateVisibleThumbnails);
  }, []);

  useEffect(() => {
    if (images.length > visibleThumbnails) {
      setShowScrollIndicator(true);
      const interval = setInterval(() => {
        setShowScrollIndicator(true);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [images.length, visibleThumbnails]);

  const handleScroll = () => {
    if (images.length > visibleThumbnails) {
      setShowScrollIndicator(false);
    }
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const changeImage = (index) => {
    setCurrentImageIndex(index);
  };

  const handleThumbnailLoad = (index) => {
    setThumbnailLoaded((loaded) =>
      loaded.map((value, i) => (i === index ? true : value))
    );
  };

  return (
    <div className="flex flex-col items-center justify-center w-full sm:w-11/12 md:w-10/12 lg:w-10/12 mx-auto mt-8">
      {/* Main Image Section */}
      <div className="main-image relative w-full overflow-hidden" ref={mainImageRef}>
        <button
          className="prev-btn absolute top-1/2 transform -translate-y-1/2 left-4 text-white bg-[#5ab963] opacity-75 rounded-full p-2 hover:opacity-100 hover:pr-6 hover:-translate-x-1 transition-all text-lg sm:text-xl z-10"
          onClick={prevImage}
        >
          &#10094;
        </button>
        <div className="w-full">
          <img
            loading="lazy"
            className="h-auto w-full max-h-[70vh] min-h-[300px] object-cover"
            src={mainImageLoaded ? images[currentImageIndex] : placeholderImage}
            alt="Akiya2.0 property"
            onLoad={() => setMainImageLoaded(true)}
          />
        </div>
        <button
          className="next-btn absolute top-1/2 transform -translate-y-1/2 right-4 text-white bg-[#5ab963] opacity-75 rounded-full p-2 hover:opacity-100 hover:pl-6 hover:translate-x-1 transition-all text-lg sm:text-xl z-10"
          onClick={nextImage}
        >
          &#10095;
        </button>
      </div>

      {/* Thumbnails Section */}
      <div className="w-full mt-4 relative">
        <div
          className="flex overflow-x-auto space-x-2 thumbnails"
          onScroll={handleScroll}
          ref={thumbnailsRef}
          style={{
            width: "100%",
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="flex-shrink-0 cursor-pointer"
              style={{
                width: `calc(100% - ${visibleThumbnails} - 0.5rem)`,
              }}
              onClick={() => changeImage(index)}
            >
              <img
                loading="lazy"
                src={thumbnailLoaded[index] ? image : placeholderImage}
                alt={`Akiya2.0 property thumbnail ${index}`}
                className={`w-full h-20 object-cover rounded ${
                  index === currentImageIndex ? "border-4 border-[#5ab963]" : ""
                }`}
                onLoad={() => handleThumbnailLoad(index)}
              />
            </div>
          ))}
        </div>

        {/* Right Gradient Overlay and Scroll Indicator Icon */}
        {images.length > visibleThumbnails && (
          <>
            <div className="right-gradient-overlay"></div>
            {/* {showScrollIndicator && (
              <FaChevronRight className="scroll-indicator-icon" />
            )} */}
          </>
        )}
      </div>

      {/* Video Section */}
      {video && (
        <div className="w-full mt-8 max-h-[500px] mdlg:h-[60vh] h-[40vh] min-h-[300px]">
          <div
            className="w-full h-full"
            dangerouslySetInnerHTML={{ __html: video }}
          />
        </div>
      )}
    </div>
  );
}

export default Gallery;