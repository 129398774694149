const propertyData =[
    {
        id:"SP0",
        propertyName: "Aitaka Azalea Blooming Villa",
        location: "Shizuoka Prefecture, Susono City, Juriki Minami Fuji",
        price: "29.8 million yen",
        houseType: "3LDK",
        about: "This is a used villa in the Jurigi Minami Fuji villa area, located in the Jurigi Plateau, southeast of Mt. Fuji. The interior and decor of each 3LDK unit are unique and tastefully arranged. The kitchen and interior were renovated in 2015, and soundproofing work was carried out in 2020, with soundproof walls and double sashes. The 18-tatami living room is equipped with a Norwegian wood stove (Jotul F400), providing warmth and a soft flickering flame. There is one toilet on each floor. Every room has a large window, allowing for excellent lighting (and the white lattice-design windows look beautiful). The second floor is the entrance and living room, and if you go down the stairs to the first floor, there are two Western-style rooms. There is a space next to the first floor where you can have a BBQ or work. In addition, since the property owns two plots, there is ample parking space and can accommodate at least five cars, two of which are covered. If the trees aren't overgrown, you can see Mt. Fuji from the second floor and parts of the first floor. You'll have to climb a flight of stairs, but you'll enjoy the benefits of being on high ground, such as being able to see nature from the bathroom and being able to enjoy the openness of the living room and kitchen even with the curtains open.",
        tableData: {
          propertyNumber: "1470",
          location: "Suyama, Susono City, Shizuoka Prefecture",
          traffic: "20km from Gotemba Station on the JR Gotemba Line",
          landArea: "(Public register) 1104.75㎡",
          buildingStructure: "wooden",
          buildingScale: "Two-story building",
          buildingArea: "111.27㎡",
          landRights: "Ownership",
          landUse: "Residential land",
          urbanPlanning: "Urbanization Control Area",
          zoning: "None",
          buildingCoverageRatio: "(Villa rules) 30%",
          floorAreaRatio: "(Villa rules) 60%",
          geography: "Heights",
          environment: "None",
          otherRestrictions: "Landscape law and Juriki Villa construction regulations apply",
          constructionDate: "June 1991",
          parking: "Yes, 5 cars available",
          currentStatus: "Currently residing",
          deliveryDate: "consultation",
          accessToRoad: "West side 7m wide",
          facilities: "Water supply, propane gas, central septic tank, wood stove, air conditioner in all rooms, bath thermostatic faucet (2016), toilet on each floor",
          deliveryConditions: "Delivery as is",
          remarks: "Management fee: 118,468 yen/year (including management fee for 2 plots, sewage treatment fee, and water supply facility reserve fund)\nWater fee: 2,420 yen/month (basic fee + fixed usage fee)\nFixed asset tax: 46,800 yen/year (2024)",
          privateLodging: "Not allowed",
          transactionType: "General intermediation"
        },
        images: [
          "https://img.resort-estate.com/img/bukkens/1470_1.jpg?20241008165000",
          "https://img.resort-estate.com/img/bukkens/1470_3.jpg?20241008165000",
          "https://img.resort-estate.com/img/bukkens/1470_5.jpg?20241008165000",
          "https://img.resort-estate.com/img/bukkens/1470_6.jpg?20241008165000",
          "https://img.resort-estate.com/img/bukkens/1470_7.jpg?20241008165000",
          "https://img.resort-estate.com/img/bukkens/1470_9.jpg?20241008165000",
          "https://img.resort-estate.com/img/bukkens/1470_12.jpg?20241008165000",
          "https://img.resort-estate.com/img/bukkens/1470_10.jpg?20241008165000",          
          "https://img.resort-estate.com/img/bukkens/1470_11.jpg?20241008165000",
          "https://img.resort-estate.com/img/bukkens/1470_17.jpg?20241008165000",
        ],
        video: `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/dq8P51A7cAM?si=orJbsmSTv-zzEj6U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
        contactDetails: {
          phone: "03-6451-3960",
          availableHours: "Monday to Friday 9:00-18:00, Saturday 9:00-15:00"
        },
        coordinates: [35.1234, 138.5678]
      },
 
] 
  
  export default propertyData;