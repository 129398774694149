import React from "react";
import "./MidSection.css";

function MidSection() {
  const heading = "A new type of consulting";
  const paragraph = (
    <>
      Our experts will help you buy and renovate your Akiya! Akiya2.0’s
      consultancy service will pair you with the perfect kominka that is right
      for you. We use our connections and resources in Japan to connect you to
      your authentic Japanese property without the hassle of researching akiya
      and working with municipal governments. We enable both you and the
      community to meaningfully activate properties, benefitting our clients
      with up-and-coming real estate, and the community surrounding it by
      reviving an empty property.
    </>
  );

  return (
    <div className="text-justify mx-[30px] text-[22px] leading-[36px] my-[3rem]">
      <div>
        <h3 className="font-[800] text-[32px] mb-3">{heading}</h3>
      </div>
      <div>
        <h5 className="text-[#949995] font-[300]">{paragraph}</h5>
      </div>
    </div>
  );
}

export default MidSection;
