import React from "react";
import TimeOutImages from "../sliderImagesContainer/SliderImagesContainer";

export default function WithLeftBox({ data }) {
  const { boxHeader, boxHeader2, boxPara, images } = data;

  return (
    <div className="w-full relative h-auto flex flex-col-reverse lg:flex-row">
      {/* Text Box */}
      <div className="w-full lg:w-1/3 lg:absolute lg:top-[-20px] lg:left-0 z-20 bg-white shadow-[10px_35px_60px_10px_rgba(0,0,0,0.3)]  px-6 pb-10 pt-5 flex flex-col justify-center h-[fit-content]">
        <h2 className="dark-header mb-0 text-left ml-0">{boxHeader}</h2>
        <h2 className="mb-4 text-[#5ab963] text-2xl font-light leading-9 text-left">
          {boxHeader2}
        </h2>
        <p className="text-justify text-[#949995] text-base font-light leading-6">
          {boxPara}
        </p>
      </div>
      {/* Image Container */}
      <div className="w-full h-64 sm:h-96 md:h-[30rem] lg:h-[50rem]">
        <TimeOutImages images={images} />
      </div>
    </div>
  );
}