// import React from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import { IoIosArrowUp } from "react-icons/io";
// import { IoIosArrowDown } from "react-icons/io";
 

// function Section(props) {
//   return (
//     <div className="flex flex-col items-center justify-center mx-[4rem] max-h-[100%] r_xs:w-[93%] r_xs:mx-3 r_xxs:w-[93%] r_xxs:mx-3">
//       <div
//         className={`bg-[#5ab963] p-4 cursor-pointer transition-transform w-[100%] text-[24px] r_xs:text-[20px] r_xxs:text-[20px] font-[800] leading-[36px] text-white flex justify-between`}
//         onClick={props.onClick} // Pass onClick handler from props
//       >
//         <div>{props.header}</div>
//         <div className=""> {props.isOpen ?<IoIosArrowUp className="font-[600] text-[3rem]" /> : <IoIosArrowDown className="font-[600] text-[3rem]" />}</div>
//       </div>
//       <div
//         className={`overflow-hidden transition-all duration-500 ${props.isOpen ? "h-[100%] opacity-100" : "h-0 opacity-0"} w-[100%]`}
//       >
//         <div className="py-4 max-h-[100%]">
//           {props.data}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Section;


import React from "react";


function Section(props) {
  return (
    <div className="flex flex-col items-center justify-center w-full sm:w-11/12 md:w-10/12 lg:w-10/12  mx-auto my-2 max-h-[100%]">
      <div
        className="bg-[#5ab963] p-4 cursor-pointer transition-transform w-full text-lg sm:text-xl md:text-2xl font-bold leading-tight sm:leading-snug text-white flex justify-between items-center"
        onClick={props.onClick}
      >
        <div>{props.header}</div>
        {/* <div>
          {props.isOpen ? (
            <IoIosArrowUp className="text-2xl sm:text-3xl md:text-4xl" />
          ) : (
            <IoIosArrowDown className="text-2xl sm:text-3xl md:text-4xl" />
          )}
        </div> */}
      </div>
      <div
        className={`overflow-hidden transition-all duration-500 ${
          props.isOpen ? "max-h-full opacity-100" : "max-h-0 opacity-0"
        } w-full`}
      >
        <div className="py-4">{props.data}</div>
      </div>
    </div>
  );
}

export default Section;