import React, { useState, useRef, useEffect } from "react";
import DropdownIcon from "../Svgs/Dropdown";

const SortDropDown = ({ options, text, handleChange, color }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 500);
  };

  const handleOptionClick = (option) => {
    handleChange(option);
    setIsDropdownOpen(false); // Close the dropdown upon option selection
  };

  return (
    <div
      className="relative inline-block transition-all duration-300"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`${
          isDropdownOpen ? "rounded-b-none" : ""
        } rounded-md custom-button flex justify-center items-center border-2  border-[${color.primary}]  text-[${color.primary}] font-semibold h-[2.5rem] xs:h-[2.5rem]  md:w-[7rem] lg:w-[10rem] cursor-pointer transition-all duration-300`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown
      >
        <p className="">{text}</p>
        <DropdownIcon />
      </div>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute top-full left-0 text-white border border-[#999999]  shadow-lg z-10 transition-all duration-300"
          style={{
            width: "100%",
            maxHeight: "20rem",
            overflowY: "auto",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {options.map((item, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(item)} // Call handleOptionClick with the selected item
              className="cursor-pointer text-sm text-center py-2 w-full border-[var(--medium-sea-green)] hover:text-white text-[#999999] hover:bg-[#999999]   bg-white  transition-all duration-300"
            >
              {item.display}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortDropDown;
