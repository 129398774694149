import React, { useEffect, useState } from "react";
import { fetchData } from "../../Utils/CommonFunctions";
import cheerio from "cheerio";

const useAllBlogsHook = () => {
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const data = await fetchData(`https://app.akiya2.com/blogs`);
        const formattedBlogs = [];

        // Iterate over each blog item in the data array
        for (const blog of data) {
          // Load HTML content into cheerio
          const $ = cheerio.load(blog.blog_content);

          // Extract formatted date
          const formattedDate = new Date(blog.createdAt).toLocaleDateString(
            "en-US",
            {
              month: "long",
              day: "numeric",
              year: "numeric",
            }
          );

          // Extract all text content and limit it to 30 words
          const content = $.text()
            .split(" ")
            .slice(0, 30)
            .join(" ");

          // Push formatted blog data to formattedBlogs array
          formattedBlogs.push({
            author: blog.author,
            date: formattedDate,
            title: blog.blog_heading,
            content,
            imageUrls: blog.coverImage,
            index: blog.serial_number,
          });
        }

        // Set state with formatted blog data
        setBlogs(formattedBlogs);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogs();
  }, []);

  return { blogs, setBlogs };
};

export default useAllBlogsHook;