import React, { useContext, useState } from "react";
import axios from "axios";
import MessageBox from "./MessageBox";
import { UserContext } from "../../../Context/UserContext";

const LoginForm = ({ onToggle, onForgetPassword }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const { setIsToken, setShowModal } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://app.akiya2.com/login",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMessage("Login successful!");
      setMessageType("success");

      if (response.data.access_token) {
        localStorage.setItem("access_token", response.data.access_token);
        setShowModal(false);
        setIsToken(true);
      }
    } catch (error) {
      setMessage(error.response.data.detail);
      setMessageType("error");
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Login</h2>
      {message && <MessageBox message={message} type={messageType} />}
      <form onSubmit={handleLogin}>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border border-gray-400 p-2 w-full focus:outline-[#5ab963]"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border border-gray-400 p-2 w-full focus:outline-[#5ab963]"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-[#5ab963] text-white py-2 px-4 rounded hover:bg-[#48944f]"
        >
          Login
        </button>
      </form>
      <p className="mt-4">
        Forgot your password?{" "}
        <button onClick={onForgetPassword} className="text-[#5ab963] hover:underline">
          Forgot Password?
        </button>
      </p>
      <p className="mt-4">
        Don't have an account?{" "}
        <button onClick={onToggle} className="text-[#5ab963] hover:underline">
          Sign Up
        </button>
      </p>
    </div>
  );
};

export default LoginForm;
