import React, { useContext, useState } from "react";
import axios from "axios";
import MessageBox from "./MessageBox";
import { UserContext } from "../../../Context/UserContext";

const RegisterForm = ({ onToggle }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const { setIsToken, setShowModal } = useContext(UserContext);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (username && email && password) {
      try {
        const response = await axios.post(
          "https://app.akiya2.com/register",
          {
            email: email,
            password: password,
            name: username,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setMessage("Registration successful!");
        setMessageType("success");

        if (response.data.access_token) {
          localStorage.setItem("access_token", response.data.access_token);
          setShowModal(false);
          setIsToken(true);
        }
      } catch (error) {
        setMessage(error.response.data.detail);
        setMessageType("error");
        console.error("Error:", error);
      }
    } else {
      setMessage("Username, email, and password are required");
      setMessageType("error");
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Register</h2>
      {message && <MessageBox message={message} type={messageType} />}
      <form onSubmit={handleRegister}>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="border border-gray-400 p-2 w-full focus:outline-[#5ab963]"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border border-gray-400 p-2 w-full focus:outline-[#5ab963]"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border border-gray-400 p-2 w-full focus:outline-[#5ab963]"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-[#5ab963] text-white py-2 px-4 rounded hover:bg-[#48944f]"
        >
          Register
        </button>
      </form>
      <p className="mt-4">
        Already have an account?{" "}
        <button onClick={onToggle} className="text-[#5ab963] hover:underline">
          Login
        </button>
      </p>
    </div>
  );
};

export default RegisterForm;
