// data.js
export const pricingData = {
    search: [
      {
        service: "Akiya Search Subscription",
        plan: "Standard",
        level: "S1A",
        duration: "3 months",
        price: "¥120,000 / Month",
        notes: "(Fortnightly meeting)"
      },
      {
        service: "Akiya Search Subscription",
        plan: "Standard",
        level: "S1B",
        duration: "6 months",
        price: "¥100,000 / Month",
        notes: "(Fortnightly meeting)"
      },
      {
        service: "Akiya Search Subscription",
        plan: "Standard",
        level: "S1C",
        duration: "12 months",
        price: "¥80,000 / Month",
        notes: "(Fortnightly meeting)"
      },
      {
        service: "Akiya Search Subscription",
        plan: "Premium",
        level: "S2A",
        duration: "3 months",
        price: "¥180,000 / Month",
        notes: "(Weekly meeting)"
      },
      {
        service: "Akiya Search Subscription",
        plan: "Premium",
        level: "S2B",
        duration: "6 months",
        price: "¥150,000 / Month",
        notes: "(Weekly meeting)"
      },
      {
        service: "Akiya Search Subscription",
        plan: "Premium",
        level: "S2C",
        duration: "12 months",
        price: "¥120,000 / Month",
        notes: "(Weekly meeting)"
      },
      {
        service: "Akiya Search Subscription",
        plan: "Standard",
        level: "S3A",
        duration: "1 month",
        price: "¥200,000 / Month",
        notes: "(One-time consultation)"
      }
    ],
    contractSigning: [
      {
        service: "Contract Signing Support",
        plan: "Standard",
        level: "C1",
        duration: "2 months retainer",
        price: "¥150,000 On Engagement",
        notes: "Support for paperwork, coordination, translations, and basic interpretation"
      },
      {
        service: "Contract Signing Support",
        plan: "Premium",
        level: "C2",
        duration: "2 months retainer",
        price: "¥300,000 On Engagement",
        notes: "Support for paperwork, coordination, translations, and detailed interpretation"
      },
      {
        service: "Contract Signing Support",
        plan: "Standard",
        level: "C3",
        duration: "Per transaction",
        price: "¥100,000 / Transaction",
        notes: "Basic support for contract signing"
      }
    ],
    marketAnalytics: [
      {
        service: "Expert analysis of properties in chosen municipality",
        plan: "Up to 5 properties in 1 prefecture",
        level: "AS1",
        duration: "2 weeks",
        price: "¥50,000 / Report",
        notes: "(Analysis of DAOability / fund ability and ROI)"
      },
      {
        service: "Expert analysis of properties in chosen municipality",
        plan: "Up to 10 properties in 1 prefecture",
        level: "AS2",
        duration: "2 weeks",
        price: "¥80,000 / Report",
        notes: "(Analysis of DAOability / fund ability and ROI)"
      },
      {
        service: "Expert analysis of properties in chosen municipality",
        plan: "Unlimited properties in 1 prefecture",
        level: "AS3",
        duration: "1 month",
        price: "¥100,000 / Report",
        notes: "(Analysis of DAOability / fund ability and ROI)"
      }
    ]
  };