import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Nav from "./Components/navbar/Nav";
import BotNav from "./Components/bottomNavbar/BotNav"
import HomePage from "./Components/homePage/HomePage";
import Comm from "./Components/community/Comm";
import TopSection from "./Components/Our-services/Topsection/TopSection";
import ScrollToTop from "./ScrollToTop";
import OurProjects from "./Components/ourProjects/OurProjects";
import AboutUs from "./Components/aboutUs/AboutUs";
import SearchAkiya from "./Components/akiyaSearch/SearchAkiya";
import Prefec from "./Components/prefecture/Prefec";
import Intern from "./Components/Internship/TopSection/Intern";
import IndividProp from "./Components/individualProperty/IndividProp";
import IndiviualAkiya from "./Components/individualAkiya/IndividualAkiya";
import BlogWriting from "./Components/BlogWriting/BlogWriting";
import AllBlogs from "./Components/AllBlogs/AllBlogs";
import SingleBlog from "./Components/SingleBlog/SingleBlog";
import Terms from "./Components/Terms/Terms";
import Profile from "./Components/Profile/Profile";
import Protected from "./Components/Protected";
import ProfileSetting from "./Components/profileSettings/ProfileSetting";
import AkiyaConcierge from "./Components/akiyaConcierge/AkiyaConcierge";
import PageNotFound from "./Components/extraPages/PageNotFound";
import Kominka from "./Components/KominkaSmile/Kominka";
import IndiviualKominkaAkiya from "./Components/individualKominkaAkiya/IndividualKominkaAkiya";
import AkiyaOnNews from "./Components/AkiyaOnNews/AkiyaOnNews";
import AkiyaTweak from "./Components/AkiyaTweak/AkiyaTweak";
import AdminProtected from "./AdminProtected";
import Test from "./Components/Misc/Test";
import Acquisition from "./Components/Misc/Acquisition/Acquisition";
import Renovation from "./Components/Misc/Renovation/Renovation";
import SellerAndAssetManagement from "./Components/Misc/SellerAndAssetManagement/SellerAndAssetManagement";
import HowItWorks from "./Components/Our-services/HowItWorks/HowItWorks";
import WhatItCosts from "./Components/Our-services/WhatItCosts/WhatItCosts";
import InvestInAkiya from "./Components/InvestInAkiya/InvestInAkiya";
import DownloadProspectusFile from "./Components/Our-services/DownloadProspectus/DownloadProspectusFile";
import ReactGA4 from "react-ga4";
import MetaTagsUpdater from "./Components/MetaTagsUpdater";
import SpecialIndividProp from "./Components/SpecialindividualProperty/SpecialIndividProp";
import StructuredData from './StructuredData';

// // console.log("Nav:", Nav);
// // console.log("BotNav:", BotNav);
// // console.log("HomePage:", HomePage);
// // console.log("Comm:", Comm);
// // console.log("TopSection:", TopSection);
// // console.log("ScrollToTop:", ScrollToTop);
// // console.log("OurProjects:", OurProjects);
// // console.log("AboutUs:", AboutUs);
// // console.log("AkiyaSearch:", SearchAkiya);
// // console.log("Prefecture:", Prefec);
// // console.log("Top:", Intern);
// // console.log("IndividualProperty:", IndividProp);
// // console.log("IndiviualAkiya:", IndiviualAkiya);
// // console.log("BlogWriting:", BlogWriting);
// // console.log("AllBlogs:", AllBlogs);
// // console.log("SingleBlog:", SingleBlog);
// // console.log("Terms:", Terms);
// // console.log("Profile:", Profile);
// // console.log("Protected:", Protected);
// // console.log("ProfileSetting:", ProfileSetting);
// // console.log("AkiyaConcierge:", AkiyaConcierge);
// // console.log("PageNotFound:", PageNotFound);
// // console.log("Kominka:", Kominka);
// // console.log("IndiviualKominkaAkiya:", IndiviualKominkaAkiya);
// // console.log("AkiyaOnNews:", AkiyaOnNews);
// // console.log("AkiyaTweak:", AkiyaTweak);
// // console.log("AdminProtected:", AdminProtected);
// // console.log("Test:", Test);
// // console.log("Acquisition:", Acquisition);
// // console.log("Renovation:", Renovation);
// // console.log("SellerAndAssetManagement:", SellerAndAssetManagement);
// // console.log("HowItWorks:", HowItWorks);
// // console.log("WhatItCosts:", WhatItCosts);
// // console.log("InvestInAkiya:", InvestInAkiya);
// // console.log("DownloadProspectusFile:", DownloadProspectusFile);

const TRACKING_ID = "G-9X7C3TVRWJ"; // YOUR_TRACKING_ID

function App() {


  useEffect(() => {
    if (typeof window !== "undefined") {
      // Initialize GA4 with the tracking ID
      ReactGA4.initialize(TRACKING_ID);

      // Log the pageview
      ReactGA4.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }, []);

  return (
    <div className="App overflow-x-hidden main-content">
      <ScrollToTop />
      <StructuredData />

      <div className="content">
        <header>

          <Nav />
        </header>
        <MetaTagsUpdater />

        <main>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/our-services" element={<TopSection />} />
            <Route path="/our-projects" element={<OurProjects />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/Internship" element={<Intern />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/property/:propertyId" element={<IndividProp />} />
            <Route path="/special-property/:propertyId" element={<SpecialIndividProp />} />

            {/* <Route path="buy-an-akiya" element={<BuyAnAkiya />} />
        <Route path="grow-your-akiya" element={<GrowYourAkiya />} /> */}
            <Route path="/community" element={<Comm />} />
            <Route
              path="/blog-writing"
              element={
                <Protected>
                  <BlogWriting />
                </Protected>
              }
            />
            <Route path="/blogs" element={<AllBlogs />} />
            <Route path="/blogs/:blogId/:blogSlug" element={<SingleBlog />} />
            <Route path="/akiya-search" element={<SearchAkiya />} />
            <Route path="*" element={<PageNotFound />} />
            <Route
              path="/prefecture-listing/:prefecture_name"
              element={<Prefec />}
            />
            <Route
              path="/individual-akiya/:akiya_id"
              element={<IndiviualAkiya />}
            />
            <Route
              path="/individual_kominka/:akiya_id"
              element={<IndiviualKominkaAkiya />}
            />
            <Route
              path="/profile-page"
              element={
                <Protected>
                  <Profile />
                </Protected>
              }
            />
            <Route
              path="/profile-settings"
              element={
                <Protected>
                  <ProfileSetting />
                </Protected>
              }
            />
            <Route path="/akiya-concierge" element={<AkiyaConcierge />} />
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="/kominka" element={<Kominka />} />
            <Route path="/news" element={<AkiyaOnNews />} />
            <Route
              path="/tweak/:id"
              element={
                <AdminProtected password="aezakmi">
                  <AkiyaTweak />
                </AdminProtected>
              }
            />
            <Route
              path="/miscTesting"
              element={
                <AdminProtected password="valdrion">
                  <Test />
                </AdminProtected>
              }
            />
            <Route
              path="/service/acquisition-service"
              element={
                <AdminProtected password="valdrion">
                  <Acquisition />
                </AdminProtected>
              }
            />
            <Route
              path="/service/renovation-service"
              element={
                <AdminProtected password="valdrion">
                  <Renovation />
                </AdminProtected>
              }
            />
            <Route
              path="/service/asset-service"
              element={
                <AdminProtected password="valdrion">
                  <SellerAndAssetManagement />
                </AdminProtected>
              }
            />
            <Route path="/services/how-it-works" element={<HowItWorks />} />
            <Route path="/services/what-it-costs" element={<WhatItCosts />} />
            <Route path="/invest-in-akiya" element={<InvestInAkiya />} />
            <Route
              path="/download-prospectus"
              element={<DownloadProspectusFile />}
            />
          </Routes>

        </main>
      </div>
      <footer>
        <BotNav />
      </footer>
    </div>
  );
}

export default App;
