import { Navigate } from "react-router-dom";
import { useUser } from "../Context/UserContext";
import PageNotFound from "./extraPages/PageNotFound";
import { useEffect, useState } from "react";
import Loader from "./Loader/Loader";

const Protected = ({ children }) => {
  const user = useUser();
  const [isToken, setIsToken] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    setIsAdmin(localStorage.getItem("isAdmin"));

    if (!access_token) {
      setIsToken(false);
    }
    if (access_token) {
      setIsToken(true);
    }
  }, [user]);

  if (!isToken) {
    return <PageNotFound />;
  }
  if (!isAdmin) {
    return <PageNotFound />;
  }

  if (!user) {
    return <Loader />;
  }

  // If user is authenticated, render children
  return children;
};

export default Protected;
