// src/Components/BlogWriting/BlogWriting.jsx

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { fetchDataWithToken } from '../../Utils/CommonFunctions';
import { FaTimes } from 'react-icons/fa';
import HeadingForAll from '../HeadingForAll/HeadingForAll';
import './styles.css';

export default function BlogWriting() {
  const [JoditEditor, setJoditEditor] = useState(null);
  const [editorConfig, setEditorConfig] = useState(null);
  const [author, setAuthor] = useState('');
  const [greeting, setGreeting] = useState('');
  const [header, setHeader] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const [isError, setIsError] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null); // New state for image preview
  const [userData, setUserData] = useState({});
  const [responseMessage, setResponseMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const [fullNameInput, setFullNameInput] = useState(''); // State for the full name input
  const [nameMissing, setNameMissing] = useState(false); // State to check if name is missing

  const editorRef = useRef(null); // Create a ref for the editor

  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    fetchDataWithToken('https://app.akiya2.com/get-user-data', access_token).then(
      (data) => {
        if (data) {
          setUserData(data);
          if (data.first_name && data.last_name) {
            setAuthor(`${data.first_name} ${data.last_name}`);
            setNameMissing(false);
          } else {
            setNameMissing(true);
          }
        } else {
          localStorage.removeItem('access_token');
        }
      }
    );
  }, []);

  useEffect(() => {
    if (isChanged) {
      const timeoutId = setTimeout(() => {
        window.location.href = '/';
      }, 2000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isChanged]);

  useEffect(() => {
    function getGreeting() {
      const currentHour = new Date().getHours();
      if (currentHour >= 5 && currentHour < 12) {
        return 'Good morning';
      } else if (currentHour >= 12 && currentHour < 18) {
        return 'Good afternoon';
      } else {
        return 'Good evening';
      }
    }
    setGreeting(getGreeting());
  }, []);

  // Dynamically load JoditEditor and editorConfig only on the client side
  useEffect(() => {
    if (typeof window !== 'undefined') {
      Promise.all([
        import('jodit-react'),
        import('./config').then((module) => module.getEditorConfig()),
      ])
        .then(([joditReactModule, config]) => {
          setJoditEditor(joditReactModule.default);
          setEditorConfig(config);
        })
        .catch((error) => {
          console.error('Error loading JoditEditor or config:', error);
        });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get the content from the editor
    const editorContent = editorRef.current?.value;

    // Validation: Ensure cover image and blog content have images
    if (!coverImage) {
      setResponseMessage('Please add a cover image.');
      setIsError(true);
      setShowMessage(true);
      return;
    }

    if (!header || !editorContent) {
      setResponseMessage('Please fill in all required fields.');
      setIsError(true);
      setShowMessage(true);
      return;
    }

    // Check if the blog content contains at least one image
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = editorContent;
    const imgTags = tempDiv.getElementsByTagName('img');
    if (imgTags.length === 0) {
      setResponseMessage('Please add at least one image in your blog content.');
      setIsError(true);
      setShowMessage(true);
      return;
    }

    // If name is missing, use the fullNameInput
    let finalAuthor = author;
    if (nameMissing) {
      if (!fullNameInput.trim()) {
        setResponseMessage('Please enter your full name.');
        setIsError(true);
        setShowMessage(true);
        return;
      } else {
        finalAuthor = fullNameInput.trim();
      }
    }

    const formData = new FormData();
    formData.append('AuthorName', finalAuthor || '');
    formData.append('BlogHeading', header || '');
    formData.append('BlogContent', editorContent || '');
    formData.append('CoverImage', coverImage);

    try {
      const response = await axios.post('https://app.akiya2.com/addBlog', formData);

      setResponseMessage('Blog added successfully!');
      setIsError(false);
      setIsChanged(true);
      setShowMessage(true);
    } catch (error) {
      console.error(error);
      setResponseMessage('An error occurred while adding the blog. Please try again.');
      setIsError(true);
      setShowMessage(true);
    }
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    setCoverImage(file);

    // Revoke the old URL if it exists
    if (coverImagePreview) {
      URL.revokeObjectURL(coverImagePreview);
    }

    // Generate a new preview URL
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setCoverImagePreview(previewUrl);
    } else {
      setCoverImagePreview(null);
    }
  };

  useEffect(() => {
    return () => {
      // Clean up the URL object when the component unmounts or coverImagePreview changes
      if (coverImagePreview) {
        URL.revokeObjectURL(coverImagePreview);
      }
    };
  }, [coverImagePreview]);

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const firstHeading = `${greeting}, ${userData.first_name || 'User'}`;
  const secondHeading = 'Ready to write something new?';

  return (
    <div className="blog-writing-container">
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />

      <div className="form-container mx-auto my-8 max-w-4xl p-4 bg-white shadow-md rounded">
        <form onSubmit={handleSubmit}>
          {/* If name is missing, show the input field */}
          {nameMissing && (
            <div className="mb-6">
              <label className="block text-gray-700 font-bold mb-2">
                Your Full Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter your full name"
                value={fullNameInput}
                onChange={(e) => setFullNameInput(e.target.value)}
                className="w-full border border-gray-300 p-3 rounded focus:outline-[#5ab963]"
                required
              />
            </div>
          )}

          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2">
              Blog Heading <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter the title of your blog"
              onChange={(e) => setHeader(e.target.value)}
              className="w-full border border-gray-300 p-3 rounded focus:outline-[#5ab963]"
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2">
              Cover Image <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleCoverImageChange}
              className="w-full border border-gray-300 p-3 rounded focus:outline-[#5ab963]"
              required
            />
            {/* Image Preview */}
            {coverImagePreview && (
              <div className="mt-4">
                <p className="text-gray-700 font-bold mb-2">Cover Image Preview:</p>
                <img
                  src={coverImagePreview}
                  alt="Cover Preview"
                  className="max-w-full h-auto border border-gray-300 rounded"
                />
              </div>
            )}
          </div>

          <div className="mb-6">
            <p className="text-gray-700 font-bold mb-2">
              Blog Content <span className="text-red-500">*</span>
            </p>
            {JoditEditor && editorConfig ? (
              <JoditEditor ref={editorRef} config={editorConfig} />
            ) : (
              <p>Loading editor...</p>
            )}
            <p className="text-gray-500 mt-2">
              Please include at least one image in your blog content.
            </p>
          </div>

          <div className="mb-6">
            <button
              type="submit"
              className="w-full bg-[#5ab963] hover:bg-[#48944f] text-white font-bold py-3 rounded focus:outline-none"
            >
              Submit Blog
            </button>
          </div>
        </form>
      </div>

      {/* Response Message */}
      {showMessage && (
        <div
          className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50`}
        >
          <div className="bg-white p-6 rounded shadow-lg relative max-w-md w-full">
            <button
              onClick={handleCloseMessage}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FaTimes className="h-6 w-6" />
            </button>
            <p
              className={`text-center text-lg font-semibold ${
                isError ? 'text-red-600' : 'text-green-600'
              }`}
            >
              {responseMessage}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}