import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import createSlug from "../../createSlug";
const FirstSOVFullImage = "/images/MainLogo.webp";

const parseContent = (blogContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = blogContent;
  const content = tempDiv.textContent || tempDiv.innerText || "";
  return content.slice(0, 150) + (content.length > 150 ? "..." : ""); // Limit to 150 characters for better control
};

const BlogCard = ({ item }) => {
  const slug = useMemo(() => createSlug(item.blog_heading), [item.blog_heading]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const {
    blog_heading,
    coverImage,
    createdAt,
    blog_content = "",
    serial_number,
  } = item;

  // Memoize date formatting
  const formattedDate = useMemo(() => {
    return new Date(createdAt).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }, [createdAt]);

  const content = useMemo(() => parseContent(blog_content), [blog_content]);

  return (
    <Link
      to={`/blogs/${serial_number}/${slug}`}
      className="flex shadow-xl w-full md:w-[100%] lg:w-[100%] justify-around cursor-pointer"
    >
      <div className="flex flex-col w-full">
        <div className="h-[18rem] w-full">
          <img
            loading="lazy"
            src={imageLoaded ? coverImage : FirstSOVFullImage}
            alt="Akiya2.0 blogs"
            className="w-full h-full object-cover"
            onLoad={() => setImageLoaded(true)}
          />
        </div>

        <div className="mx-3">
          <p className="mt-2 text-[--medium-sea-green]">{formattedDate}</p>
          <p className="mt-2 font-bold text-xl mb-2">{blog_heading}</p>
          <p className="font-light text-md text-[--dark-grey] mb-10 break-words overflow-hidden">
            {content}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;