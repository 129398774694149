import React, { useState } from "react";
import Single_property from "./singleProperty";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import propertyData from "./data";
import { Loader } from "lucide-react";

function Properties_listing() {
    const [properties] = useState(propertyData);
    const [sliderRef, setSliderRef] = useState(null);
    const prevBu = "<<";
    const nextBu = ">>";

    const NextArrow = ({ onClick }) => (
        <div
            className="absolute top-1/2 right-0 transform -translate-y-1/2 z-10 cursor-pointer md:block hidden"
            onClick={onClick}
        >
            <button className="next-btn absolute top-1/2 transform -translate-y-1/2 right-1 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pl-[1rem] hover:translate-x-[0.5rem] transition-all text-[20px]">
                &#10095;
            </button>
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div
            className="absolute top-1/2 left-[-0.7rem] transform -translate-y-1/2 z-10 cursor-pointer md:block hidden"
            onClick={onClick}
        >
            <button className="prev-btn absolute top-1/2 transform -translate-y-1/2 left-4 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pr-[1rem] hover:translate-x-[-0.5rem] transition-all text-[20px]">
                &#10094;
            </button>

        </div>
    );

    const BottomNextButton = () => (
        <button
            className="block md:hidden bg-[#5ab963] font-[800] text-white rounded p-2 mb-0 mr-[1.2rem] ml-1"
            onClick={() => sliderRef.slickNext()}
        >
            {nextBu}
        </button>
    );

    const BottomPrevButton = () => (
        <button
            className="block md:hidden bg-[#5ab963] font-[800] text-white mb-0 rounded p-2 ml-[1.6rem]"
            onClick={() => sliderRef.slickPrev()}
        >
            {prevBu}
        </button>
    );

    // Dynamic settings based on number of properties
    const settings = {
        dots: false,
        infinite: properties.length > 3, // Infinite only if more than 3 properties
        speed: 500,
        slidesToShow: properties.length > 2 ? 3 : properties.length, // Show 3 slides if more than 2 properties
        slidesToScroll: 1,
        nextArrow: properties.length > 3 && <NextArrow />,
        prevArrow: properties.length > 3 && <PrevArrow />,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024, // Tablet view
                settings: {
                    infinite: properties.length > 2, // Infinite if more than 2 properties
                    slidesToShow: properties.length >= 2 ? 2 : 1, // Show 2 slides if more than 1 property
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // Mobile view
                settings: {
                    infinite: properties.length > 1, // Infinite if more than 1 property
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false, // Hide arrows for mobile view
                },
            },
        ],
    };

    return (
        <div className="max-w-[100vw] flex flex-col items-center">
            <div className="mx-[15px] md:mb-[4rem] w-full max-w-[1440px] relative">
                {properties.length > 0 ? (
                    <>
                        <Slider {...settings} className="w-full" ref={setSliderRef}>
                            {properties.map((property) => (
                                <div className="px-[10px] " key={property.id}>
                                    <a
                                        href={`/special-property/${property.id}`}
                                        className="block md:hidden mb-0"
                                    >
                                        <Single_property property={property} hideKnowMore={true} />
                                    </a>
                                    <div className="hidden md:block">
                                        <Single_property property={property} hideKnowMore={false} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className="flex justify-center items-center h-[100%] w-[100vw] md:hidden mb-5">
                            <BottomPrevButton />
                            <BottomNextButton />
                        </div>
                    </>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
}

export default Properties_listing;