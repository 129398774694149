import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import CustomButt from "../CustomButton/CustomButt";

const fields = [
  { type: "text", placeholder: "Your name", name:"name"},
  { type: "email", placeholder: "Your email", name:"mail" },
  { type: "text", placeholder: "Your Country", name:"country" },
  { type: "text", placeholder: "Tell us all about it",name:"query" },
];

const Form = () => {
  const form = useRef();
  const [showMessage, setShowMessage] = useState(false); // State to manage message box visibility

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_sf1j1kl', 'template_4hxx0oe', form.current, {
        publicKey: 's0xkd41sUOcqpCOVk',
      })
      .then(() => {
        // console.log('SUCCESS!');
        setShowMessage(true); // Show message box
        setTimeout(() => {
          setShowMessage(false); // Hide message box after 5 seconds
          window.location.href = '/'; // Redirect to home page
        }, 5000);
      })
      .catch((error) => {
        // console.log('FAILED...', error.text);
      });
  };

  return (
    <div className="relative">
      <form ref={form} onSubmit={sendEmail} className="w-[100%] flex flex-col gap-4">
        {fields.map((field, index) => (
          <input
            key={index}
            type={field.type}
            placeholder={field.placeholder}
            name={field.name}
            className="w-full h-16 border-b-[1px] text-sm placeholder-sm:text-sm outline-none focus:outline-none"
          />
        ))}
        
        <div>
          <CustomButt text={"Submit "} />
        </div>
      </form>
      
      {/* Message box */}
      {showMessage && (
        <div className="absolute bottom-4 left-4 bg-white py-2 px-4 rounded-lg border border-gray-300">
          <p>Your message is received. You will be contacted soon.</p>
        </div>
      )}
    </div>
  );
};

export default Form;
