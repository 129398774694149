import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { UserContext, useUser } from "../../Context/UserContext";
import { useNavigate, useParams } from "react-router-dom";

const fetchData = async (url) => {
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    // console.log(err);
    return [];
  }
};

const usePrefectureHook = () => {
  const user = useUser();
  const { prefecture_name } = useParams();
  const { setShowModal } = useContext(UserContext);
  const navigate = useNavigate();
  const [akiyaData, setAkiyaData] = useState(null);
  const sortDropDownOptions = [
    { display: "By Price", option: "price" },
    { display: "By Area", option: "area" },
    { display: "By Year", option: "year" },
  ];
  const sortingType = [
    { display: "Higher to Lower", option: "decending" },
    { display: "Lower to Higher", option: "acending" },
  ];
  useEffect(() => {
    fetchData(`https://app.akiya2.com/prefecture/${prefecture_name}`).then(
      (data) => {
        // console.log(data);
        setAkiyaData(data);
      }
    );
  }, []); // Empty dependency array to ensure the effect runs only once

  const handleFavoriteClick = () => {
    if (!user) {
      setShowModal(true);
    } else {
      navigate("/profile-page");
    }
  };

  return {
    akiyaData,
    setAkiyaData,
    sortDropDownOptions,
    sortingType,
    handleFavoriteClick,
  };
};

export default usePrefectureHook;
