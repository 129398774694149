import React from "react";

function ThirdSection({ data }) {
  const tableData = data.tableData;

  return (
    <div className="max-w-5xl mx-auto my-8 p-4 bg-white rounded-lg shadow-md border border-gray-200 ">
      {/* <h2 className="text-2xl font-bold text-[#5ab963] mb-4">Listing Details</h2> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
        <table className="w-full border-collapse">
          <tbody>
            {Object.entries(tableData).slice(0, Math.ceil(Object.entries(tableData).length / 2)).map(([key, value], index) => (
              <tr key={index} className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
                <td className="p-2 font-semibold text-gray-700 capitalize border border-gray-200 w-1/2">
                  {formatLabel(key)}
                </td>
                <td className="p-2 text-gray-600 border border-gray-200">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="w-full border-collapse">
          <tbody>
            {Object.entries(tableData).slice(Math.ceil(Object.entries(tableData).length / 2)).map(([key, value], index) => (
              <tr key={index} className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
                <td className="p-2 font-semibold text-gray-700 capitalize border border-gray-200 w-1/2">
                  {formatLabel(key)}
                </td>
                <td className="p-2 text-gray-600 border border-gray-200">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function formatLabel(label) {
  return label
    .replace(/([A-Z])/g, " $1")
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

export default ThirdSection;