import React, { useState } from "react";
import { Link } from "react-router-dom";

const placeholderImage = "/images/MainLogo.webp";

const NewsCard = ({ item }) => {
  const { news_heading, coverImage, time, news_content, link } = item;
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Link
      to={link}
      target="_blank"
      className="flex shadow-xl w-full md:w-[50%] lg:w-[31%] justify-around cursor-pointer"
    >
      <div className="flex flex-col w-full">
        <div className="h-[18rem] w-full">
          <img
            src={imageLoaded ? coverImage : placeholderImage}
            alt="Akiya2.0 news"
            className="w-full h-full object-cover"
            onLoad={() => setImageLoaded(true)}
          />
        </div>

        <div className="mx-3 w-full">
          <p className="mt-2 text-[--medium-sea-green]">{time}</p>
          <p className="mt-2 font-bold text-xl mb-2">{news_heading}</p>
          <p className="font-light text-md text-[--dark-grey] mb-10 mr-5 break-words">
            {news_content}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;