import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

function TeamList({ item = {} }) {
  const { about, image, linkToBlog, linkToLinkedin, name, post } = item;

  return (
    <div className="bg-white w-full flex flex-col justify-between shadow-lg h-full ">
      <div className="px-6 py-8">
        <img
          className="w-full h-56 md:h-64 lg:h-80 object-cover mb-6"
          src={image}
          alt={`Akiya2.0 member - ${{name}}`}
        />
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-[#5ab963] text-xl md:text-2xl font-bold">{name}</h1>
            <p className="text-[#949995] text-sm md:text-base">{post}</p>
          </div>
          <div>
            <Link to={linkToLinkedin} target="_blank">
              <FaLinkedin className="text-2xl md:text-3xl text-[#5ab963] hover:text-[#0077b5] transition duration-300" />
            </Link>
          </div>
        </div>
        <p className="text-sm md:text-base text-[#949995]">{about}</p>
      </div>
    </div>
  );
}

export default TeamList;
