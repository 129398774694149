

import React from "react";
import "../subHeadingContainer/SubHeadingContainer.css";

const SubHeadCont = ({ data }) => {
  const { number, title, subtitle, content, imageUrls } = data;

  return (
    <div className=" mb-10 flex flex-col md:flex-row">
      <div className="w-full md:w-2/3 mx-3">
        {/* Left section */}
        <h1 className="heading">{number}</h1>
        <h1 className="heading">{title}</h1>
        {subtitle.map((item, index) => (
          <div className="mt-3" key={index}>
            <h1 className="subheading">{subtitle[index]}</h1>
            <p className="paragraph pr-4">{content[index]}</p>
          </div>
        ))}
      </div>
      <div className="w-full  md:w-1/3 lg:w-1/3 py-2 flex gap-4 md:flex-col justify-around flex-row  ">
        {/* Right section */}
        {imageUrls.map((item, index) => (
          <div className="mx-2 w-[50%] md:w-full" key={index}>
            <img loading="lazy" src={imageUrls[index]} alt="Akiya" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubHeadCont;
