import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../useWindowDimensions";

const CardSlider = ({ data, Card, endValues }) => {
  const { width } = useWindowDimensions();
  const { sm, md, lg, xs } = endValues;
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(3);
  const [eventData, setEventData] = useState([]);
  const [showRight, setShowRight] = useState(false);
  const [showLeft, setShowLeft] = useState(false);

  useEffect(() => {
    let endValue;
    if (width >= 991) {
      endValue = lg;
    } else if (width >= 640) {
      endValue = md;
    } else if (width >= 400 && sm) {
      endValue = sm;
    } else {
      endValue = xs;
    }

    setEnd(start + endValue);
    const tempData = data.slice(start, start + endValue);
    setEventData(tempData);

    setShowLeft(start > 0);
    setShowRight(start + endValue < data.length);
  }, [width, start, data, sm, md, lg, xs]);

  const handleRight = () => {
    if (end < data.length) {
      setStart(start + 1);
    }
  };

  const handleLeft = () => {
    if (start > 0) {
      setStart(start - 1);
    }
  };

  return (
    <div className={`flex ${eventData.length > 2 ? "justify-between" : "justify-start"} gap-3 relative`}>
      {eventData.map((item, index) => (
        <Card key={index} item={item} />
      ))}
      {showRight && (
        <button
          onClick={handleRight}
          className="next-btn absolute top-1/3 transform -translate-y-1/2 right-4 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pl-[1rem] hover:translate-x-[0.5rem] transition-all text-[20px]"
        >
          {/* {">"} */}
          &#10095;
        </button>
      )}
      {showLeft && (
        <button
          onClick={handleLeft}
          className="prev-btn absolute top-1/3 transform -translate-y-1/2 left-4 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pr-[1rem] hover:translate-x-[-0.5rem] transition-all text-[20px]"
        >
          {/* {"<"} */}
          &#10094;
        </button>
      )}
    </div>
  );
};

export default CardSlider;
