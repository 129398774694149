import React, { useState, useEffect, memo } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const KominkaMarker = "/images/Kominka/KominkaMark.png";

const CustomMarker = memo(
  ({ position, title, id, isHovered, handleMarkerClick, handleMouseOver, handleMouseOut }) => {
    const scaledSize = isHovered
      ? new window.google.maps.Size(50, 50)
      : new window.google.maps.Size(40, 40);

    return (
      <Marker
        position={position}
        title={title}
        icon={{ url: KominkaMarker, scaledSize }}
        onClick={() => handleMarkerClick(id)}
        onMouseOver={() => handleMouseOver(id)}
        onMouseOut={handleMouseOut}
      />
    );
  }
);

const MapComponent = ({ prefectureName, apiKey, akiyaData }) => {
  const [markers, setMarkers] = useState([]);
  const [prefectureMarker, setPrefectureMarker] = useState(null);
  const [hoveredMarkerId, setHoveredMarkerId] = useState(null);
  const [initialCenter, setInitialCenter] = useState({ lat: 35.682839, lng: 139.759455 });

  useEffect(() => {
    const validMarkers = akiyaData
      .filter((x) => x.Coordinate && x.Coordinate.length === 2)
      .map((x) => ({
        position: { lat: x.Coordinate[0], lng: x.Coordinate[1] },
        title: `${x.cityName}, ${x.prefectureName}`,
        id: x.post_id,
      }));
    setMarkers(validMarkers);

    if (prefectureName) {
      const prefecture = akiyaData.find(
        (x) => x.prefectureName === prefectureName && x.Coordinate && x.Coordinate.length === 2
      );
      if (prefecture) {
        const { coordinate } = prefecture;
        setPrefectureMarker({ position: { lat: coordinate[0], lng: coordinate[1] }, title: prefectureName });
        setInitialCenter({ lat: coordinate[0], lng: coordinate[1] });
      }
    }
  }, [akiyaData, prefectureName]);

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const handleMarkerClick = (id) => {
    const url = "/individual_kominka/" + id;
    window.open(url, "_blank");
  };

  const handleMouseOver = (id) => {
    setHoveredMarkerId(id);
  };

  const handleMouseOut = () => {
    setHoveredMarkerId(null);
  };

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    gestureHandling: "greedy",
  };

  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={7}
        center={initialCenter}
        options={mapOptions}
      >
        {markers.map((marker) => (
          <CustomMarker
            key={marker.id}
            position={marker.position}
            title={marker.title}
            id={marker.id}
            isHovered={hoveredMarkerId === marker.id}
            handleMarkerClick={handleMarkerClick}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
        ))}
        {prefectureMarker && (
          <CustomMarker
            position={prefectureMarker.position}
            title={prefectureMarker.title}
            id={null}
            isHovered={false}
            handleMarkerClick={handleMarkerClick}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
