import React from "react";
const images = ["/images/buy-an-akiya/img01.webp", "/images/buy-an-akiya/img02.webp", "/images/buy-an-akiya/img03.webp", "/images/buy-an-akiya/img04.webp", "/images/buy-an-akiya/img05.webp", "/images/buy-an-akiya/img06.webp"];

function importAll(r) {
  r.keys().forEach((key) => images.push(r(key)));
}

// try {
//   importAll(
//     require.context(
//       "/images/buy-an-akiya",
//       false,
//       /\.(png|jpe?g|svg)$/
//     )
//   );
// } catch (error) {
//   console.error(error);
// }

export const headingData = [
  {
    heading: "Acquire an Akiya with ease",
    info: "In Japan, an abundance of abandoned or vacant properties awaits your consideration for purchase. While pinpointing exact figures remains challenging, rest assured, they exist. However, acquiring these properties has traditionally posed substantial challenges. The complexities span issues of data governance, cultural narratives, and industry norms, forming formidable obstacles for all but the most determined buyers. Our role is to simplify this process—facilitating your search, assessment, and acquisition of Akiya tailored to your preferences.",
  },
  {
    heading: "Purchase your Akiya today!",
    info: "Ready to transform abandoned spaces into your dream property? Take the first step by exploring unique Akiya opportunities. For personalized assistance or inquiries, Contacting Us is just a message away. Let's start your Akiya journey together!",
  },
];

export const subHeadingdata = [

  {
    number: "01",
    heading: "Acquire an Akiya with ease",
    title: "Akiya2.0 can only succeed by focus on restoring Akiya/ Kominka with traditional techniques rather than replacing with new hotels",
    subtitle: [
      "",
      "",
      "",
    ],
    content: [
      <>Why do we insist on refurbishing old Akiya and traditional Japanese Kominka?<br /><br /> </>,
      <>The land is good, why not just build a new hotel atop of it? Why go to the trouble of renovating old, abandoned buildings?<br /><br /></>,
      <>Akiya2.0 sees the architectural and historical value in these often abandoned homes. By restoring them instead of building new hotels, we keep the unique charm and beauty of both the house and the destination in which they belong. This  approach revitalizes rural areas, attracts tourists seeking authentic experiences, especially in secondary and tertiary tourism areas not suffering “over-tourism” and which would benefit from increased interest.</>,
    ],
    imageUrls: [images[0], images[1]],
  },
  {
    number: "02",
    title: "The Akiya problem can only be solved by working together with local communities",
    subtitle: [
      "",
      "",
      "",
    ],
    content: [
      <>The Akiya problem, with many abandoned homes scattered across Japan, can only be solved by working together with local communities. Akiya2.0 is proud to partner with many Japanese institutions and businesses, big or small, and will continue to find new ways to collaborate.<br /><br /> </>,
      "",
      <>Our bilingual staff, who have been in Japan for a long time, spend as much time talking to clients as they do talking to locals to ensure restoration projects respect cultural heritage and align with community goals. Joint efforts foster a sense of ownership and pride, turning abandoned properties into vibrant community assets and investments that last longer than short term efforts that do not have a vision into the future.</>,
    ],
    imageUrls: [images[2], images[3]],
  },
  {
    number: "03",
    title: "Akiya2.0 can help bring about revitalisation use of the “clusters and destinations” in selected secondary/tertiary tourism destinations",
    subtitle: [
      // "Architecture expertise",
      // "Sustainable architecture plans",
      // "Transparent budgeting",
    ],
    content: [
      // "Draw upon the mastery of our in-house architects, seasoned in the art of renovation. With rich experience, they will skillfully transform your akiya house into a personalized, stunning living space, embracing the beauty of tradition.",
      // "We invite you to partner with our in-house architects to craft personalized renovation blueprints, aligning with your preferences. We strive to maintain the traditional aesthetics of the akiya house, ensuring a unique blend of your style and timeless tradition.",
      // "Enjoy transparent budgeting for your renovation project, with clear cost breakdowns and regular updates, allowing you to plan effectively and make informed decisions throughout the renovation process.",
    ],
    imageUrls: [
      // images[4], images[5]
    ],
  },
];

