import { useState, useEffect } from 'react';

const useFetchSVG = (url) => {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    const fetchSVG = async () => {
      try {
        const response = await fetch(url);
        const text = await response.text();
        setSvgContent(text);
      } catch (error) {
        console.error("Error fetching SVG:", error);
      }
    };

    fetchSVG();
  }, [url]);

  return svgContent;
};

export default useFetchSVG;