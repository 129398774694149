import React from "react";
import { HashLink } from "react-router-hash-link";

function DownloadProspectus() {
  return (
    <div className="flex flex-col items-center md:flex-row my-5 md:mx-3">
      <div className="w-full md:mr-5">
        <h2 className="text-center md:text-right text-[24px] font-[800] mb-3 md:mb-0">
          Download the full services price list.
        </h2>
        <p className="text-center md:text-right text-[#949995] text-[24px] font-[300] leading-[36px]">
          ※ Detailed figures are provided in our full Akiya2.0 services and
          price list. You can download it here.
        </p>
      </div>

      <div className="w-full mt-3 md:mt-0 md:ml-5 flex justify-center">
        <div className="text-center md:text-right">
          <HashLink smooth to="/about-us/#form">
            <button className=" w-[100vw] md:w-auto md:rounded-md bg-[#5ab963] px-6 py-4 text-white text-lg font-bold">
              Download Now
            </button>
          </HashLink>
        </div>
      </div>
    </div>
  );
}

export default DownloadProspectus;