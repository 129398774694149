import React from "react";
import Test2 from "./Test2";
import HeadingForAll from "../../HeadingForAll/HeadingForAll";
import OuServiceIcon from "./OurServicesSvg";
import Form from "../../akiyaForm/Form";
import Heading from "../../headingContainer/Heading";
import { howItWorksData } from "./data";
import { headingData } from "../../buyAnAkiya/BuyAnAkiyaHook";

function HowItWorks() {
  const { heading, paragraph, firstHeading, secondHeading, services } = howItWorksData;

  return (
    <>
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
      <div className="flex justify-center">
        <div className="max-w-[1440px] w-full px-4">
          <div className="text-justify text-[18px] sm:text-[22px] leading-[28px] sm:leading-[36px] my-8">
            <h3 className="font-[800] text-[24px] sm:text-[32px] mb-3">{heading}</h3>
            <h5 className="text-[#949995] font-[300]">{paragraph}</h5>
          </div>

          {/* Responsive SVG Icon with additional margin */}
          <div className="relative flex justify-center items-center h-auto my-[10rem] mx-[30px]"> {/* Adjust margins for small devices */}
            <OuServiceIcon />

            {/* Responsive Points around the icon */}
            <div className="absolute top-[-8rem] left-0 text-left w-[40%] sm:w-[18%] text-[10px] mdlg:text-[18px]">
              <h3 className="font-[800]">4) Leave your Akiya better than you first invested in it.</h3>
              <div className="font-[400] text-[10px] mdlg:text-[15px] lg:text-[25px]">
                <p>Marketing and Listing</p>
                <p>Moving out/Deep Clean</p>
                <p>Contract Liaison</p>
              </div>
            </div>

            <div className="absolute top-[-8rem] right-0 text-right w-[40%] sm:w-[18%] text-[10px] mdlg:text-[18px]">
              <h3 className="font-[800]">1) Find and Purchase your Akiya</h3>
              <div className="font-[400] text-[10px] mdlg:text-[15px]">
                <p>Search Assistance</p>
                <p>Market Analysis (Optional)</p>
                <p>Site Inspection</p>
                <p>Regulatory Advisory</p>
                <p>Buyer Side Negotiations</p>
                <p>Contract Liaison</p>
                <p>Other Language Services</p>
              </div>
            </div>

            <div className="absolute bottom-[-8rem] left-0 text-left w-[40%] sm:w-[18%] text-[10px] mdlg:text-[18px]">
              <h3 className="font-[800]">3) Activate/Operate your Akiya investment</h3>
              <div className="font-[400] text-[10px] mdlg:text-[15px]">
                <p>Market Analysis</p>
                <p>Marketing</p>
                <p>Property Management</p>
                <p>Reservation Management (Optional)</p>
                <p>Corporate Presence (Optional)</p>
              </div>
            </div>

            <div className="absolute bottom-[-8rem] right-0 text-right w-[40%] sm:w-[18%] text-[10px] mdlg:text-[18px]">
              <h3 className="font-[800]">2) Renovate your dream Japanese home</h3>
              <div className="font-[400] text-[10px] mdlg:text-[15px]">
                <p>Project Scoping</p>
                <p>Visualisation Service</p>
                <p>Costing/Contractors/Timetable</p>
                <p>Renovation and monitoring</p>
                <p>Contractor management</p>
                <p>Buyer Visit Coordination</p>
                <p>Moving in/Local Activation</p>
              </div>
            </div>
          </div>

          {/* Additional Content */}
          <Test2 />

          {/* Spacer */}
          <div className="h-10 w-full"></div>

          {/* Form Section */}
          <div className="w-full flex items-center justify-center">
            <div className="flex flex-col md:flex-row w-full max-w-[1440px] my-5 gap-12">
              <div className="md:w-[50%] w-full p-2">
                <Heading data={headingData[1]} />
              </div>
              <div className="md:w-[50%] w-full">
                <Form />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorks;