import React from 'react';

function Text() {
    const content = [
        {
            heading: "Privacy Policy",
        },
        {
            heading: "Overview",
            paragraph: (
                <ul>
                    <li>At Akiya2.0, your privacy is criti cally important to us and is guided by the following principles:</li><br/>
                    <li>- Your data is yours – we are thoughtful about the personal information we ask you to provide and the personal information that we collect about you through the operation of our services.</li>
                    <li>- We store personal information as per our data retention policy.</li>
                    <li>- We aim to make it as simple as possible for you to control what information in your profile is shared publicly (or kept private).</li>
                    <li>- We aim for full transparency on how we gather, use, and share your personal information.</li><br/>
                    <li>Our Privacy Policy below incorporates and clarifies these principles in more detail, and applies to all information that we collect about you across our website (www.akiya2.com),</li>
                </ul>
            )
        },
        {
            heading: "How does Akiya2.0 collect data about me?",
            paragraph: (
                <ul>
                    <li>We only collect information about you if we have a reason to do so – for example, to provide our Services, to communicate with you, or to make our Services better.</li><br/>
                    <li>- Basic Account Information: We ask for basic information from 
                        you in order to set up your account. For example, we require individuals
                         who sign up for a Akiya2.0 account to provide your name and email address. You may provide us with more information–on your profile–but we do not require that information to create a Akiya2.0 account.</li><br/>
                    <li>Public Profile Information: If you have an account with us, we collect the information that you provide for your public profile. For example, if you have a Akiya2.0 account, your name is part of that public profile, along with any other information you put into your public profile, such as a photo or an “About Me” description. Your public profile information is just that–public–so please keep that in mind when deciding what information you would like to include.</li><br/>
                    <li>Transaction and Billing Information: If you buy something from
                         us—a tour or activity from our shop, or a custom tour requiring accommodation and/or transportation, for example—you will provide additional personal and payment information that is required to process the transaction and your payment, such as your name, credit card information, and contact information.‍</li><br/>
                    <li>Customer Personal Information: We keep a portion of personal information 
                        provided by customers in travel requests and other transactions as personal data. Akiya2.0 also handles personal data entrusted to it by other travel companies in order to provide travel arrangements requested by those companies.</li><br/>
                    <li>Communications with us: You may also provide us information when you respond to surveys, communicate with our support team, or apply for a role with us. Technical, Usage and Location Information: We automatically collect information on how you interact with the Services, such as the IP address from which you access the Services, date and time, information about your browser, operating system and computer or device, pages viewed, and items clicked. We may also collect location information, including location information automatically provided by your computer or device. We use cookies and similar technologies to collect some of this information.</li>
                </ul>
                    
            )
        },
        {
            heading: "Sensitive Information",
            paragraph:"Akiya2.0 adheres to a policy of not collecting, using, or disclosing user-identifiable information related to political opinions, faith (religion, philosophy, or creed), race or ethnicity, medical treatment, sexual history, or criminal history unless required by law, and we will inform you if such disclosure becomes necessary. Instances where sensitive information may be shared, such as completing a survey as part of a campaign by a third-party sponsor, will always be communicated to you in advance. You will have the autonomy to decide whether to proceed or not. When handling sensitive information, Akiya2.0 takes special care to ensure compliance with Japanese laws, avoiding any collection, use, or disclosure that deviates from legal requirements."
        },
        {
            heading: "Why does Akiya2.0 collect this information?",
            paragraph: (
                <ul>
                    <li>We use information about you as mentioned above and for the purposes listed below:</li>
                    <li>- To provide, maintain and improve our Services including to operate certain features and functionality of the Services</li>
                    <li>- To further develop and improve our Services–for example by adding new features that we think our users will enjoy;</li>
                    <li>- To monitor and analyze trends and better understand how users interact with our Services, which helps us improve our Services and make them easier to use;</li>
                    <li>- To measure, gauge, and improve the effectiveness of our advertising, and better understand user retention and attrition;</li>
                    <li>- To communicate with you, for example through an email, about offers and promotions and others we think will be of interest to you, solicit your feedback, or keep you up to date on Akiya2.0 and our products; and</li>
                    <li>- To personalize your experience using our Services, provide content recommendations, target our marketing messages to groups of our users, and serve relevant advertisements.</li>
                </ul>
            )
        },
        {
            heading: "Legal Bases for Collecting and Using Information",
            paragraph: (
                <ul>
                    <li>For users based in the European Union, the following outlines our legal grounds for processing information about you under EU data protection laws:</li>
                    <li><span className="dark-header">1]Contractual obligations:</span> The use is necessary in order to fulfill our commitments to you under our Terms of Service or other agreements with you or is necessary to administer your account; or</li>
                    <li><span className="dark-header">2]Legal Compliance:</span> The use is necessary for compliance with a legal obligation; or</li>
                    <li><span className="dark-header">3]Protection of Interests:</span> The use is necessary in order to protect your vital interests or those of another person; or</li>
                    <li><span className="dark-header">4]Legitimate Interest:</span> We have a legitimate interest in using your information–for example, to provide and update our Services, to improve our Services so that we can offer you an even better user experience, to safeguard our Services, to communicate with you, to measure, gauge, and improve the effectiveness of our advertising, and better understand user retention and attrition, to monitor and prevent any problems with our Services, and to personalize your experience; or</li>
                    <li><span className="dark-header">5]Consent:</span> You have given us your consent to process your data–for example when ticking a box to receive email newsletters, actively submitting a survey where we have informed you it will be shared with a sponsor, or agreeing to allow us to place certain cookies on your device and access and analyze them later on, as described in our Cookie Policy.</li>
                </ul>
            )
        },
        {
            heading: "How does Akiya2.0 share your information? ",
            paragraph:(
                
                <ul>
                    <li>Unless stated otherwise, Akiya2.0 does not rent, sell or share personal information with third parties or non-affiliated companies except to provide products or services under the following circumstances:</li>
                    <li>- Akiya2.0 will provide in electronic form a customer’s name, gender, age, address, phone number, e-mail address, passport number, and credit card number to transportation, accommodation, insurance and travel companies as necessary to arrange purchased travel services, or as necessary to fulfill travel agreements or complete insurance formalities to securitize costs when accidents occur.</li>
                    <li>- Where required by law or other legal proceedings to divulge such information to the authorities or where there is suspected fraud or potential threats to the physical safety of any person.</li>
                </ul>
            )
        },
        {
            heading: "In circumstance when personal information is leaked",
            paragraph:"If for some reason Akiya2.0 personal information is disclosed, we will do our best to contact the customer immediately. If necessary, we will temporarily shut down our system to maintain security, or we may promptly announce facts about the situation publicly on our website and newsletter."
        },
        {
            heading: "Court of Jurisdiction",
            paragraph:"The laws of Japan shall be applied to this website and terms of use and visitors consent to the jurisdiction of the Tokyo District Court in any and all disputes."
        },
        {
            heading: "How can I find out about changes?",
            paragraph:"The latest version of our Privacy Policy will always be published here, which should be referred to for the latest information at any time. Akiya2.0 may update this policy. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your Akiya2.0 account or by placing a prominent notice on our site. Akiya2.com, is produced by Akiya2.0 KK, and references to Akiya2.0, Akiya2, Akiya2.com or Akiya2.0 KK shall in general be interchangeable."
        },
        {
            heading: "Contact us",
            paragraph: (
                <p>
                    You can send privacy-related questions, complaints, and requests to Akiya2.0 at{' '}
                    <a href="mailto:akiya2@japaninc.com" style={{ color: 'green' }}>akiya2@japaninc.com</a>.
                </p>
            )
        }
        
        
        // Add more heading and paragraph objects as needed
    ];

    return (
        <div className='text-justify text-[24px] leading-[36px] my-[3rem]'>
            {content.map((item, index) => (
                <div key={index} className="mb-8">
                    <div>
                        <h3 className='dark-header mb-3'>{item.heading}</h3>
                    </div>
                    <div>
                        <p className='light-header r_xs:text-[24px] r_xxs:text-[24px]'>{item.paragraph}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Text;
