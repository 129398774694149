import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import BlogCard from "../blogCard/BlogCard";
import formatDate from "../../FormatDate";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import Loader from "../Loader/Loader";
import CardSlider2 from "../AkiyaOnNews/NewsCards/customCardSlider";
import createSlug from "../../createSlug";

function SingleBlog() {
  const [data, setData] = useState(null);
  const [latestBlogData, setLatestBlogData] = useState([]);
  const [shouldLoadBackground, setShouldLoadBackground] = useState(false);
  const { blogId, blogSlug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Function to check screen width and conditionally load background
    const checkScreenSize = () => {
      setShouldLoadBackground(window.innerWidth >= 1440);
    };

    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize); // Update on resize

    return () => window.removeEventListener("resize", checkScreenSize); // Cleanup
  }, []);

  useEffect(() => {
    // Fetch single blog data
    axios
      .get(`https://app.akiya2.com/blog/${blogId}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
      });

    // Fetch latest blog data
    axios
      .get(`https://app.akiya2.com/blogs`)
      .then((response) => {
        const sortedData = response.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);
        setLatestBlogData(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching latest blogs:", error);
      });
  }, [blogId]);

  useEffect(() => {
    if (data) {
      const generatedSlug = createSlug(data.blog_heading);
      if (blogSlug !== generatedSlug) {
        navigate(`/blogs/${blogId}/${generatedSlug}`, { replace: true });
      }
    }
  }, [data, blogSlug, blogId, navigate]);

  if (!data) {
    return <Loader big={false} />;
  }

  // Generate the correct slug and URL
  const generatedSlug = createSlug(data.blog_heading);
  const currentUrl = `https://www.akiya2.com/blogs/${blogId}/${generatedSlug}`;

  const links = [
    {
      name: "LinkedIn",
      link: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentUrl)}`,
      icon: <FaLinkedin />,
    },
    {
      name: "Twitter",
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`,
      icon: <FaTwitter />,
    },
  ];

  return (
    <div className="font-mono">
      {/* Image Section */}
      <div className="relative w-full mb-12">
        {/* Background Wrapper */}
        <div className="relative w-full h-[300px] md:h-[500px] overflow-hidden">
          {shouldLoadBackground && (
            // Conditionally render blurred background only on screens > 1440px
            <div
              className="absolute top-0 left-1/2 transform -translate-x-1/2 w-screen h-full"
              style={{
                backgroundImage: `url(${data.coverImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                filter: "blur(10px)", // Reduced blur for performance
                zIndex: -1,
              }}
            ></div>
          )}
          {/* Main Image */}
          <div className="flex justify-center items-center">
            <img
              className="w-full max-w-[1440px] h-full object-cover relative z-10 min-h-[300px]"
              style={{ objectPosition: "center 25%" }}
              src={data.coverImage}
              alt={data.blog_heading}
              loading="lazy" // Lazy loading for efficiency
            />
          </div>
        </div>
      </div>
  
      {/* Wrapping content in an article tag */}
      <article className="px-8 max-w-7xl mx-auto">
        <div className="text-center text-4xl font-extrabold leading-tight">
          {data.blog_heading}
        </div>
  
        <div className="flex justify-between mx-10 mt-4">
          <div className="text-[#5ab963] text-xl font-bold">
            {formatDate(data.createdAt)}
          </div>
          <div className="text-[#5ab963] text-xl font-bold">{data.author}</div>
        </div>
  
        {/* Share Links */}
        <div className="flex justify-end m-3 my-8">
          <p className="text-lg leading-5 font-normal mr-2">Share on:</p>
          {links.map((x) => (
            <a
              key={x.name}
              href={x.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mx-2 flex items-center text-[#999]"
            >
              <div>{x.icon}</div>
              <p className="px-1 text-lg leading-5">{x.name}</p>
            </a>
          ))}
        </div>
  
        {/* Blog Content */}
        <div className="text-lg leading-7 font-normal mb-10 overflow-auto">
          <div
            className="box-border"
            style={{ fontFamily: "Libre Franklin" }}
            dangerouslySetInnerHTML={{ __html: data.blog_content }}
          />
        </div>
  
        {/* Latest Blogs Section */}
        <div className="mt-12">
          <div className="text-center text-[#5ab963] text-2xl font-bold my-8">
            Latest Blogs
          </div>
          {latestBlogData.length ? (
            <div className="w-full flex items-center justify-center my-10">
              <div className="w-[95%]">
                <CardSlider2
                  data={latestBlogData}
                  Card={BlogCard}
                  endValues={{
                    xs: 1,
                    md: 2,
                    lg: 3,
                  }}
                />
              </div>
            </div>
          ) : (
            <Loader big={false} />
          )}
        </div>
  
        {/* Call to Action Section */}
        <div className="flex flex-col md:flex-row items-center mb-20 mt-40">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-3xl font-bold">
              Everyone knows there are over 8 million Akiya in Japan.
            </h1>
            <h2 className="text-2xl font-light text-[#949995]">
              Here's the fuss-free way to actually own one.
            </h2>
          </div>
          <div className="md:w-1/2 flex justify-center md:mr-[8rem]">
            <Link to="/services/how-it-works">
              <button className="py-4 px-8 bg-[#5ab963] rounded text-white text-lg font-bold">
                Our Services
              </button>
            </Link>
          </div>
        </div>
      </article>
    </div>
  );
}

export default SingleBlog;