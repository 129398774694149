import React from "react";
import "./Box.css";
import { Link } from "react-router-dom";

function Box() {
  const header = "Our Consultancy Services";

  const services = [
    {
      id: "purchase",
      header: "Purchase an Akiya",
      link: "/buy-an-akiya",
      paragraph: (
        <>
          Let us handle the details and hassle of purchasing foreign property,
          so you can focus on choosing the Akiya that's fit for you. We
          eliminated all the obstacles in the elusive abandoned residence market
          by compiling it all into an easy database for your convenience, so you
          can find exactly what you want.
        </>
      ),
    },
    {
      id: "grow",
      header: "Growing your Akiya",
      link: "/grow-your-akiya",
      paragraph: (
        <>
          Entrust us to take care of your Akiya at every step with our
          comprehensive consultation services, and transform it into your
          imagined vacation home. With our vast connections and resources, we
          are able to provide you with a seamless renovation experience catered
          specifically to you, giving you exactly what you want out of your
          Akiya.
        </>
      ),
    },
  ];

  return (
    <div className="max-w-[100%] px-4 sm:px-6 lg:px-8">
      <div className="img h-full max-w-[100%]">
        <div className="flex justify-center">
          <h1 className="text-white text-[36px] sm:text-[48px] font-bold leading-10 sm:leading-12 underline">
            {header}
          </h1>
        </div>
        <div className="flex flex-col sm:flex-row mx-auto mt-8 sm:mt-12 pb-16 sm:pb-24">
          {services.map((service) => (
            <div
              key={service.id}
              className="w-full sm:w-1/2 p-4 max-w-[500px] mx-auto"
            >
              <div className="bg-black bg-opacity-75 h-full p-6 flex flex-col justify-between">
                <div>
                  <h2 className="text-[#5ab963] text-[13px] sm:text-[26px] font-semibold mb-4 text-center">
                    {service.header}
                  </h2>
                  <p className="text-[#949995] font-[300] text-base sm:text-lg leading-7">
                    {service.paragraph}
                  </p>
                </div>
                <div className="flex justify-center mt-6">
                  <Link to={service.link}>
                    <button className="font-semibold text-sm sm:text-base text-white bg-[#5ab963] rounded px-6 py-3">
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Box;
