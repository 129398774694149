import React, { useState, useEffect, useRef } from "react";

const SliderImagesContainerForInvestor = ({
  images,
  headers,
  subHeaders,
  paragraphs,
  makers,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false); // To track if auto-sliding is paused

  const intervalRef = useRef(null); // To store the interval ID

  // Functions to navigate images
  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Function to stop auto-sliding when user interacts
  const stopAutoSlide = () => {
    setIsPaused(true);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  // useEffect to handle auto-sliding
  useEffect(() => {
    if (!isPaused) {
      intervalRef.current = setInterval(() => {
        showNextImage();
      }, 3000); // Change image every 3 seconds
    }

    // Cleanup function to clear the interval
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isPaused]); // Dependency array includes isPaused

  // Positioning logic for the text box
  const topLeft = [0];
  const bottomRight = [1, 2];

  const positionClass = topLeft.includes(currentIndex)
    ? "md:absolute md:top-0 md:left-0"
    : bottomRight.includes(currentIndex)
    ? "md:absolute md:bottom-0 md:right-0"
    : ""; // default case if the index is not in any array

  return (
    <div className="relative w-11/12 md:w-11/12 mx-auto">
      <div className="relative w-full">
        {/* Image container */}
        <div className="relative w-full h-[15rem] sm:h-[20rem] md:h-[30rem] lg:h-[45rem]">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="invest in Akiya"
              loading="lazy"
              className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}

          {/* Navigation Buttons */}
          <PrevArrow
            onClick={() => {
              stopAutoSlide();
              showPreviousImage();
            }}
          />
          <NextArrow
            onClick={() => {
              stopAutoSlide();
              showNextImage();
            }}
          />

          {/* Indicator Dots */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  stopAutoSlide();
                  setCurrentIndex(index);
                }}
                className={`w-3 h-3 rounded-full ${
                  currentIndex === index ? "bg-white" : "bg-gray-400"
                } focus:outline-none`}
                aria-label={`Slide ${index + 1}`}
              />
            ))}
          </div>
        </div>

        {/* Text Box */}
        <div
          className={`classical pb-8 pt-3 w-[100%] sm:w-[100%] md:w-[50%] lg:w-[38%] bg-white shadow-md z-10 ${positionClass}`}
        >
          <h1 className="text-black text-xl md:text-2xl lg:text-3xl font-bold leading-tight mx-4">
            {headers[currentIndex]}
          </h1>
          <h2 className="mb-4 text-[#5ab963] text-lg md:text-xl lg:text-2xl font-light leading-normal mx-4">
            {subHeaders[currentIndex]}
          </h2>
          <p className="text-justify text-[#949995] text-md font-light leading-6 mx-4">
            {paragraphs[currentIndex]}
          </p>
        </div>
      </div>

      {/* Maker Text */}
      <div className="w-full text-[#949995] mt-4 mr-5flex text-lg">
        {makers[currentIndex]}
      </div>
    </div>
  );
};

// Navigation Arrow Components
const NextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-1/2 right-2 transform -translate-y-1/2 z-20 cursor-pointer"
      onClick={onClick}
    >
      <button className="next-btn text-white bg-[#5ab963] opacity-75 rounded-full p-2 hover:opacity-100 transition-all text-2xl focus:outline-none">
        &#10095;
      </button>
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-1/2 left-2 transform -translate-y-1/2 z-20 cursor-pointer"
      onClick={onClick}
    >
      <button className="prev-btn text-white bg-[#5ab963] opacity-75 rounded-full p-2 hover:opacity-100 transition-all text-2xl focus:outline-none">
        &#10094;
      </button>
    </div>
  );
};

export default SliderImagesContainerForInvestor;