import React, { useState, useEffect, useRef } from "react";

const SliderImagesContainer = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false); // To track if auto-sliding is paused

  const intervalRef = useRef(null); // To store the interval ID

  // Function to show the next image
  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to show the previous image
  const showPreviousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Function to stop auto-sliding when user interacts
  const stopAutoSlide = () => {
    setIsPaused(true);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  // useEffect to handle auto-sliding
  useEffect(() => {
    if (!isPaused) {
      intervalRef.current = setInterval(() => {
        showNextImage();
      }, 3000); // Change image every 3 seconds
    }

    // Cleanup function to clear the interval
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isPaused]); // Dependency array includes isPaused

  return (
    <div className="w-full h-full relative">
      {/* Image Slides */}
      {images.map((image, index) => (
        <img
          loading="lazy"
          key={index}
          className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          }`}
          src={image}
          alt={`Akiya ${index}`}
        />
      ))}

      {/* Previous Button */}
      <button
        onClick={() => {
          stopAutoSlide();
          showPreviousImage();
        }}
        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white text-2xl p-2 rounded-full focus:outline-none z-10"
      >
        &#10094;
      </button>

      {/* Next Button */}
      <button
        onClick={() => {
          stopAutoSlide();
          showNextImage();
        }}
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white text-2xl p-2 rounded-full focus:outline-none z-10"
      >
        &#10095;
      </button>

      {/* Indicator Dots */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              stopAutoSlide();
              setCurrentIndex(index);
            }}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? "bg-white" : "bg-gray-400"
            } focus:outline-none`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default SliderImagesContainer;