import React from 'react';
import { GiTowel } from "react-icons/gi";
import { FaCar } from "react-icons/fa";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import { FaBath } from "react-icons/fa";
import { FaKitchenSet } from "react-icons/fa6";
import { SiNetflix } from "react-icons/si";
import { FaShower } from "react-icons/fa";
import { TbFridge } from "react-icons/tb";
import { FaWifi } from "react-icons/fa";
import { TbAirConditioning } from "react-icons/tb";
import { FaBicycle } from "react-icons/fa";

const icons = [
    { icon: GiTowel, label: 'Towels', name: 'towels' },
    { icon: FaCar, label: 'Car', name: 'car' },
    { icon: PiTelevisionSimpleBold, label: 'Tv', name: 'tv' },
    { icon: FaBath, label: 'Bath', name: 'bath' },
    { icon: FaKitchenSet, label: 'Kitchen', name: 'kitchen' },
    { icon: SiNetflix, label: 'Netflix', name: 'netflix' },
    { icon: FaShower, label: 'Shower', name: 'shower' },
    { icon: TbFridge, label: 'Fridge', name: 'fridge' },
    { icon: FaWifi, label: 'WiFi', name: 'wifi' },
    { icon: TbAirConditioning, label: 'AC', name: 'ac' },
    { icon: FaBicycle, label: 'Bicycle', name: 'bicycle' },
];

function SecondSection({ data }) {
    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {icons.filter(item => data.amenities.includes(item.name)).map((item, index) => (
                <div className="flex items-center" key={index}>
                    <div>
                        <item.icon className="text-2xl md:text-3xl lg:text-4xl" />
                    </div>
                    <p className="text-[#949995] text-base md:text-lg lg:text-xl font-normal ml-2">
                        {item.label}
                    </p>
                </div>
            ))}
        </div>
    );
}

export default SecondSection;