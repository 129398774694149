import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

function TeamList({ name, work, about, linkedin, image }) {
  return (
    <div className="bg-white w-full sm:w-[45%] lg:w-[30%] flex flex-col justify-between shadow-[rgba(0,0,0,0.05)_0px_0px_20px_0px] mb-8">
      <div className="px-6 py-8">
        <img
          className="w-full h-[22rem] object-cover object-[left_0rem_bottom_-11.5rem] mb-6"
          src={image}
          alt="Akiya2.0 Team Member"
          loading="lazy"
        />
        <div className="flex justify-between items-center mb-6">
          <div>
            <h3 className="text-[#5ab963] text-2xl font-bold">{name}</h3>
            <p className="text-[#949995] text-base">{work}</p>
          </div>
          <div>
            <Link to={linkedin} target="_blank">
              <FaLinkedin className="text-3xl text-[#5ab963] hover:text-[#0077b5] transition duration-300" />
            </Link>
          </div>
        </div>
        <p className="text-sm text-[#949995]">{about}</p>
      </div>
    </div>
  );
}

export default TeamList;
