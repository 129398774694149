export const renovationData = {
    refurbishmentManagement: [
      {
        service: "Standard Project Management",
        level: "R1",
        duration: "Duration of Project",
        price: "3% of renovation fees",
        notes: "(Introduction to local architect/contractor)\n(All consultations translated to english)\n(Fortnightly meeting)"
      },
      {
        service: "Specialist Project Management",
        level: "R2",
        duration: "Duration of Project",
        price: "5% of renovation fees",
        notes: "(Includes free visualisation service + Analysis)"
      }
    ],
    visualisationService: [
      {
        service: "2 Exterior + 1 Interior",
        level: "R3",
        duration: "3 weeks",
        price: "¥60,000 / Set",
        notes: "(Full 3d modelling of house, and then professional rendering)"
      },
      {
        service: "3 Exterior + 3 Interior",
        level: "Level 1",
        duration: "4 weeks",
        price: "¥100,000 /Set",
        notes: ""
      }
    ],
    renovationStageAnalysis: [
      {
        service: "Expert analysis of property",
        plan: "Up to 3 properties in 1 prefecture",
        level: "AR1",
        duration: "2 weeks",
        price: "¥50,000 / Report",
        notes: "(Analysis of DAOability / fund ability and ROI)\n(Analysis of management/ marketing options)"
      }
    ]
  };