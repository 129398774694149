import React from "react";

const Menu = () => {
  return (
    <svg
      className=""
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 6H20M4 12H20M4 18H20" stroke="#ffffff" />
    </svg>
  );
};

export default Menu;
