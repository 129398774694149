import React from "react";
import { sliderImages } from "../community/useCommunityHook";
import TimeOutImages from "../slidImgCont/SlidImgCont";
import { shimanamiKaido } from "./our-project-data";

export default function FourthSOV() {
    const boxHeader = "Shimanami Kaido";
    const boxHeader2 = "‍Cycling Paradise of the Seto Inland Sea";
    const boxPara = "The Shimanami Kaido unfolds as a cycling paradise connecting the islands of the Seto Inland Sea. Celebrated for its scenic landscapes and well-crafted cycling routes, it provides a harmonious blend of nature and infrastructure. Whether an avid enthusiast or a casual rider, the Shimanami Kaido offers an unforgettable journey through the breathtaking beauty of the Seto Inland Sea.";
  return (
    <div className="w-[100%] h-[fit-content] relative extra_spacing flex-col">
      <div className="w-[100%] h-[92%] mdlg:absolute bottom-0 classical2">
        <TimeOutImages images={shimanamiKaido.imagesLinks} />
      </div>
      <div className="classical mdlg:mt-0 mt-[2rem] mdlg:pb-[4rem] mdlg:pt-[1rem] w-[100%] mdlg:w-[35%] h-[fit-content] bg-white mdlg:absolute top-0 right-0 shadow-[rgba(0,0,0,0.05)_0px_0px_20px_0px]">
        <h1 className="dark-header mb-0">{boxHeader}</h1>
        <h2 className="mb-[2rem] text-[#5ab963] text-[24px] font-[300] leading-[36px] mx-[30px] mt-0">
          {boxHeader2}
        </h2>
        <p className="text-justify text-[#949995] text-[16px] font-[300] leading-[24px] mx-[30px] mt-0">
          {boxPara}
        </p>
      </div>
    </div>

  );
}


