import React from 'react'
import FamousPlaces from './FamousPlaces'
import { shinanamiKaidoFamousPlaces } from './our-project-data'

export default function FifthSOV() {
  return (
<div className='flex flex-wrap mdlg:mt-[60%] r_lg:mt-[50rem] mt-[30px]'>
  {shinanamiKaidoFamousPlaces.map((singlePlace) => (
    <div key={singlePlace.id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4 flex justify-center">
      <FamousPlaces data={singlePlace} />
    </div>
  ))}
</div>
  )
}

