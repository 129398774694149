import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { fetchData } from "../../Utils/CommonFunctions";
import { UserContext, useUpdateUser, useUser } from "../../Context/UserContext";
import { useNavigate } from "react-router-dom";

const useAkiyaSearchHook = () => {
  const [listingData, setListingData] = useState();
  const [selectedAkiya, setSelectedAkiya] = useState();
  const [allPrefectures, setAllPrefectures] = useState([]);
  const [headingData, setHeadingData] = useState({});
  const [headingForAllData, setHeadingForAllData] = useState({});

  const { setShowModal } = useContext(UserContext);
  const user = useUser();
  const navigate = useNavigate();

  async function fetchImage(url) {
    try {
      const response = await fetch(url);
      return response.ok; // returns true if the image exists and is accessible
    } catch (error) {
      return false; // returns false if there's an error fetching the image
    }
  }
  
  useEffect(() => {
    fetchData("https://app.akiya2.com/all_listing").then((data) => {
      setListingData(data);
    
      // Shuffle the data array to randomize it
      const shuffledData = data.sort(() => 0.5 - Math.random());
    
      // Initialize an empty array to hold the selected Akiyas
      const selectAkiya = [];
    
      // Loop through the shuffled data to find items that meet the criteria
      for (let i = 0; i < shuffledData.length && selectAkiya.length < 5; i++) {
        const item = shuffledData[i];
    
        // Check if the item has the required keys
        if (
          item.Address &&
          item.Area_of_House &&
          item.Area_of_Land &&
          item.Images &&
          item.Images.length > 0
        ) {
          // Check if the first image URL is working (basic validation for non-empty strings)
          const firstImageUrl = item.Images[0];
          if (firstImageUrl && typeof firstImageUrl === "string" && firstImageUrl.trim() !== "") {
            // Add the item to the selected Akiya array
            selectAkiya.push(item);
          }
        }
      }
    
      setSelectedAkiya(selectAkiya);
    });
    
    

    setHeadingForAllData({
      subheading: "Search for Akiya",
      heading: <>Discover Japan's hidden gems.<br /> Akiya awaits.</>,
    });
    // "Discover Japan's hidden gems. Akiya awaits."
    setHeadingData([
      {
        heading: "Discover by Prefecture",
        info: "There are 47 prefectures in Japan, Navigate through a comprehensive database of akiya listings from local municipalities",
      },
      {
        heading: "Explore your favorites",
        info: "Unlock a world of opportunity to find your dream home in Japan. Save them in your favorites now",
      },
      {
        heading: "Recommended Kominkas",
        info: "Kominka are beautifully built using traditional Japanese craftsmanship. These high-grade listings come courtesy of our partner, the Japan Kominka Association",
      },
      {
        heading: "Some Akiya to begin your search with",
        info: "",
      },
    ]);

    setAllPrefectures([
      { prefecture_name: "Aichi" },
      { prefecture_name: "Akita" },
      { prefecture_name: "Aomori" },
      { prefecture_name: "Chiba" },
      { prefecture_name: "Ehime" },
      { prefecture_name: "Fukui" },
      { prefecture_name: "Fukuoka" },
      { prefecture_name: "Fukushima" },
      { prefecture_name: "Gifu" },
      { prefecture_name: "Gunma" },
      { prefecture_name: "Hiroshima" },
      { prefecture_name: "Hokkaido" },
      { prefecture_name: "Hyogo" },
      { prefecture_name: "Ibaraki" },
      { prefecture_name: "Ishikawa" },
      { prefecture_name: "Iwate" },
      { prefecture_name: "Kagawa" },
      { prefecture_name: "Kagoshima" },
      { prefecture_name: "Kanagawa" },
      { prefecture_name: "Kochi" },
      { prefecture_name: "Kumamoto" },
      { prefecture_name: "Kyoto" },
      { prefecture_name: "Mie" },
      { prefecture_name: "Miyagi" },
      { prefecture_name: "Miyazaki" },
      { prefecture_name: "Nagano" },
      { prefecture_name: "Nagasaki" },
      { prefecture_name: "Nara" },
      { prefecture_name: "Niigata" },
      { prefecture_name: "Oita" },
      { prefecture_name: "Okayama" },
      { prefecture_name: "Okinawa" },
      { prefecture_name: "Osaka" },
      { prefecture_name: "Saga" },
      { prefecture_name: "Saitama" },
      { prefecture_name: "Shiga" },
      { prefecture_name: "Shimane" },
      { prefecture_name: "Shizuoka" },
      { prefecture_name: "Tochigi" },
      { prefecture_name: "Tokushima" },
      { prefecture_name: "Tokyo" },
      { prefecture_name: "Tottori" },
      { prefecture_name: "Toyama" },
      { prefecture_name: "Wakayama" },
      { prefecture_name: "Yamagata" },
      { prefecture_name: "Yamaguchi" },
      { prefecture_name: "Yamanashi" },
    ]);
  }, []);
  const handleFavoriteClick = () => {
    if (!user) {
      setShowModal(true);
    } else {
      navigate("/profile-page");
    }
  };
  const handleKominkaClick = () => {

    navigate("/kominka");
    
  };
  

  return {
    headingForAllData,
    headingData,
    allPrefectures,
    listingData,
    selectedAkiya,
    handleFavoriteClick,
    handleKominkaClick
  };
};

export default useAkiyaSearchHook;
