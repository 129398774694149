import React from "react";
import Listing from "./Listing";

function FirstSOV() {
  const mainHeader = `The "Akiya2.0" Vision`;
  const mainPara = (
    <>
      Japan has an “Akiya” problem. An Akiya (空き家) is a vacant or abandoned house in Japan, 
      and due to the aging population and urban migration, millions of akiyas—abandoned houses—are 
      being left behind. This number is still growing, contributing to the slow and quiet demise of 
      hundreds of small historical villages across Japan.

      <br />
      <br />
      Plenty of people in Japan and all over the world want to help. But the
      path is not straightforward nor is it cheap. Current efforts usually
      involve the very rich and the very few; and feature more demolishing and
      less conservation.
      <br />
      <br />
      ‍ Akiya2.0 vision is to make it feasible for ordinary native landowners
      and newcomers alike to renovate individual properties, improve their
      assets and so reinvigorate their communities. Our unique model will
      multiply small localized efforts to create new sustainable communities
      that are rooted in history but also secure in their future.
      <br />
      <br />
      This will succeed only if all stakeholders feel truly engaged, and so
      Akiya2.0 is committed to providing the best possible experience to all
      akiya owners old and new. We leave no stone unturned with boutique
      architectural design, omotenashi concierge services and an unprecedented
      owner feedback development system.
    </>
  );

  const data = [
    {
      ser_no: "01",
      header: "Ensure quality",
      content:
        "Kodawari - the Japanese word for a pursuit of perfection. Akiya2.0 is all about commitment, persistence and attention to detail. Couple that with famous Japanese hospitality and we ensure a quality product that will keep everyone coming again and again.",
    },
    {
      ser_no: "02",
      header: "Insist sustainable, insist local",
      content:
        "It is painless to rebuild from scratch, or to ignore local heritage in favor of modern comforts. But to protect the past is to secure a more lasting future. Akiya 2.0 will insist on doing the right thing, not the easy thing.",
    },
    {
      ser_no: "03",
      header: "Foster community",
      content:
        "We may work with properties, but at the heart of it, Akiya2.0 is about people and communities. We are here to stay, and we want everyone to stay. Building long lasting communities  is the only way to reverse the akiya problem, one village at a time.",
    },
  ];
  return (
    <>
      <div className="pr-[30px]">
        <h1 className="dark-header my-0">{mainHeader}</h1>
        <p className="light-header my-0">{mainPara}</p>
      </div>
      <div className="flex flex-wrap justify-center gap-6 md:justify-between md:m-[30px]">
        {data.map((x) => (
          <Listing {...x} key={x.ser_no} />
        ))}
      </div>

    </>
  );
}

export default FirstSOV;
