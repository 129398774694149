import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Gallery from "./Gallery";
import AllInOne from "./AllInOne";
import Section from "./Section";
import FirstSection from "./Three_Sections_Data/FirstSection";
import ThirdSection from "./Three_Sections_Data/ThirdSection";
import Loader from "../Loader/Loader";
import propertyData from "./../akiyaSearch/SpecialListings/data";
import HeaderQuery from "../individualAkiya/HeaderQuery";
import QueryForm from "../individualAkiya/QueryForm";

function SpecialIndividProp() {
  const { propertyId } = useParams();
  const headingData = {
    heading: "Interested in this Akiya?",
    info: "Akiya2.0 can assist you from purchase to renovation and investments.Contact us and our friendly staff will come back to you as soon as possible.",
  }
  if (!propertyData) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-[1440px] px-4">
        <Gallery images={propertyData[0].images} video={propertyData[0].video} />
        <AllInOne property={propertyData[0]} />
        <Section
          header="Property Introduction"
          data={<FirstSection data={propertyData[0]} />}
          isOpen={true}
        />
                {/* <Section
          header="AMENITIES"
          data={<SecondSection data={propertyData[0]} />}
          isOpen={openSectionIndex === 1}
          onClick={() => handleSectionClick(1)}
        /> */}
        <Section
          header="Listing Details"
          data={<ThirdSection data={propertyData[0]} />}
          isOpen={true}
        />
        <div className="flex justify-center items-center w-full h-full mt-8 mb-8">
          <Link to={"/akiya-search"}>
            <button className="bg-[#5ab963] rounded font-bold text-white px-6 py-3">
              Go Back
            </button>
          </Link>
        </div>
      </div>


      <div className="mx-[20px] md:mx-[40px] mb-[3rem]">
      <div className="flex flex-col md:flex-row w-full mt-[5rem] gap-12 ">
            <div className=" md:w-[50%] w-full p-2">
              <HeaderQuery data={headingData} />
            </div>
            <div className=" md:w-[50%] w-full">
              <QueryForm />
            </div>
          </div>
      </div>
    </div>
  );
}

export default SpecialIndividProp;