import React from "react";
import useAkiyaSearchHook from "./useAkiyaSearchHook";
import useWindowDimensions from "../useWindowDimensions";
import Properties_listing from "./SpecialListings/Listings";



function SpecialListings() {
    const {
        headingData,
      } = useAkiyaSearchHook();
    
  return (
    <>
                  <div className="mt-[10px] mx-5 mb-[12px]">
            {headingData && headingData[0] && (
              <div className="ml-[30px] font-[700] text-[26px]">
                Hand picked high grade property on sale

              </div>
              // <HeadingContainer data={headingData[3]} />
            )}
          </div>

          <div className="w-full flex items-center justify-center mb-10">
          <Properties_listing />

          </div>
    </>
  )
}

export default SpecialListings