import React, { useState } from 'react';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgetPasswordForm from './ForgetPasswordForm'; // Import the ForgetPasswordForm component

const Modal = ({ show, onClose }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);

  const handleToggle = () => {
    setIsRegister(!isRegister);
    setIsForgetPassword(false);  // Ensure "Forget Password" is closed
  };

  const handleToggleSetPass = () => {
    setIsRegister(false);  // Ensure "Register" is closed
    setIsForgetPassword(!isForgetPassword);  // Toggle "Forget Password"
  };

  return show ? (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 max-w-md relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>

        {/* Conditional rendering based on state */}
        {isForgetPassword ? (
          <ForgetPasswordForm onToggle={handleToggleSetPass} />
        ) : isRegister ? (
          <RegisterForm onToggle={handleToggle} />
        ) : (
          <LoginForm onToggle={handleToggle} onForgetPassword={handleToggleSetPass} />
        )}
      </div>
    </div>
  ) : null;
};

export default Modal;
