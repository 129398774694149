import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Purchase from './Purchase';
import Seller from './Seller';
import Renovation from './Renovation';
import Commercialization from './Commercialization';

function Tables() {
  const [activeTab, setActiveTab] = useState('Purchase');
  const location = useLocation();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab && tabs.includes(tab)) {
      setActiveTab(tab);
    }
  }, [location.search]);

  const tabs = ['Purchase', 'Renovation', 'Commercialization'];
  // const tabs = ['Purchase', 'Seller', 'Renovation', 'Commercialization'];
  
  const tabComponents = {
    'Purchase': <Purchase />,
    'Seller': <Seller />,
    'Renovation': <Renovation />,
    'Commercialization': <Commercialization />
  };

  return (
    <div className="flex flex-col items-center max-h-[100%]">
      <div className="relative w-5/6 flex flex-col sm:flex-row items-center my-4 bg-green-500 rounded">
        {tabs.map((tab) => (
          <Link key={tab} to={tab !== 'Commercialization' ? `?tab=${tab}` : '#'} className="flex-1 w-full sm:w-auto">
            <button
              onClick={() => tab !== 'Commercialization' && setActiveTab(tab)}
              className={`relative py-3 px-2 sm:py-5 sm:px-4 w-full ${activeTab === tab ? 'bg-gradient-to-t bg-green-700' : 'bg-transparent'} ${tab === 'Commercialization' ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} text-white font-[700] rounded transition duration-300 ease-in-out`}
              disabled={tab === 'Commercialization'}
            >
              {tab}
            </button>
          </Link>
        ))}
      </div>

      <div className="relative w-4/5 overflow-hidden">
        {tabs.map((tab) => (
          <div key={tab} className={`${activeTab === tab ? 'block' : 'hidden'}`}>
            {tabComponents[tab]}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tables;
