import React from "react";
import TimeOutImages from "../slidImgCont/SlidImgCont";
import { noto } from "./our-project-data";

function SecondSOV() {
  const boxHeader = "Noto Peninsula";
  const boxHeader2 = "A hidden gem of the Sea of Japan";
  const boxPara =
    "The Noto Peninsula (能登半島) in Ishikawa Prefecture extends about 100 kilometers into the Sea of Japan. The peninsula is known for its coastal scenery, particularly along the Okunoto Coast and the Kongo Coast, as well as for its rural atmosphere. Off the beaten path for even experience, it provides a quality seaside experience for the entire family.";
  
  return (
    <div className="w-[100%] h-[fit-content] relative extra_spacing flex-col">
      {/* Image Section */}
      <div className="w-[100%] h-[92%] mdlg:absolute bottom-0 classical2">
        <TimeOutImages images={noto.imagesLinks} />
      </div>
      {/* Text Content Section */}
      <div className="classical mdlg:mt-0 mt-[2rem] mdlg:pb-[4rem] mdlg:pt-[1rem] w-[100%] mdlg:w-[35%] h-[fit-content] bg-white mdlg:absolute top-0 right-0 shadow-[rgba(0,0,0,0.05)_0px_0px_20px_0px]">
        <h1 className="dark-header mb-0">{boxHeader}</h1>
        <h2 className="mb-[2rem] text-[#5ab963] text-[24px] font-[300] leading-[36px] mx-[30px] mt-0">
          {boxHeader2}
        </h2>
        <p className="text-justify text-[#949995] text-[16px] font-[300] leading-[24px] mx-[30px] mt-0">
          {boxPara}
        </p>
      </div>
    </div>
  );
}

export default SecondSOV;