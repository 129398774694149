import React from "react";

const Card = ({ number, heading, paragraph }) => {
  return (
    <div className="max-w-[100%] mx-[3rem] my-[2rem] r_xs:w-[95%] md:w-[30%]">
      <div className="text-[#5ab963] text-[24px] font-[800] max-w-full">{number} </div>
      <div className="flex justify-center text-[#5ab963] text-[24px] font-[800] max-w-full">
        {heading}{" "}
      </div>
      <div className="text-[#949995] text-[16px] max-w-full">{paragraph} </div>
    </div>
  );
};

const App = () => {
  return (
    <div className="flex flex-nowrap r_xxs:flex-wrap r_xs:flex-wrap r_sm:flex-wrap justify-center r_xs:justify-center items-center bg-white">
      <Card
        number="01"
        heading="International exposure"
        paragraph="Acquire global expertise while assimilating into a distinct cultural environment."
      />
      <Card
        number="02"
        heading="Refine your skills"
        paragraph="Enhance your skills in photojournalism, leaving a lasting impression on prospective employers."
      />
      <Card
        number="03"
        heading="Challenge yourself"
        paragraph="Step out of your comfort zone and embrace the challenges of living abroad in Japan."
      />
    </div>
  );
};

export default App;
