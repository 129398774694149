import React,{useState, useEffect} from "react";
import useIndivdiualAkiyaHook from "../individualAkiya/useIndividualAkiyaHook";
import AkiyaGallery from "./AkiyaGallery";
import Heart from "../Svgs/Heart";
import MapIComponent from "./MapIndividual";
import Loader from "../Loader/Loader";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import QueryForm from "./QueryForm";
import HeaderQuery from "./HeaderQuery";
let currentUrl = '';
if (typeof window !== 'undefined') {
  currentUrl = window.location.href; // Runs only in the browser
}

const headingData = {
  heading: "Interested in this Akiya?",
  info: "Akiya2.0 can assist you from purchase to renovation and investments. Contact us and our friendly staff will come back to you as soon as possible.",
};

const IndividualAkiya = () => {
  const {
    individualAkiya,
    tableData,
    municipality,
    property_no,
    handleToggleFavorite,
    isFavorite,
  } = useIndivdiualAkiyaHook();

  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentUrl(window.location.href);
    }
  }, []);

  const links = [
    {
      name: "LinkedIn",
      link: `https://www.linkedin.com/shareArticle?url=${currentUrl}`,
      icon: <FaLinkedin />,
    },
    {
      name: "Twitter",
      link: `https://twitter.com/intent/tweet?url=${currentUrl}`,
      icon: <FaTwitter />,
    },
  ];

  return (
    <div className="flex justify-center">
      <div className="max-w-[1080px] w-full mx-2 h-full lg:px-10 md:px-5 sm:px-2 px-2 pb-4">
        {/* Gallery Section */}
        <div className=" ">
          {individualAkiya && individualAkiya.Images ? (
            <AkiyaGallery images={individualAkiya.Images} />
          ) : (
            <Loader big={false} />
          )}
        </div>

        {/* Map Section */}
        <div className="maps_indi">
          {individualAkiya && (
            <MapIComponent
              apiKey="AIzaSyDhd_bCluPIx2cSHS7pGd5wVPkIRU7rpxQ"
              coordinates={individualAkiya.Coordinates}
            />
          )}
        </div>

        {/* Property Heading */}
        <div className="w-full h-[6rem] flex items-center justify-center">
          <p className="ml-2 w-full md:text-5xl font-bold sm:text-3xl text-2xl">
            {`${municipality} Listing No. ${property_no}`}
          </p>
          <div className="w-[60%] flex justify-end mr-5">
            <button
              onClick={handleToggleFavorite}
              className={`${isFavorite ? "text-[#5AB963]" : "text-white"} px-2 m-1 stroke-[#5AB963]`}
            >
              <div className="w-16 h-16">
                <Heart />
              </div>
            </button>
          </div>
        </div>

        {/* Share Links */}
        <div className="flex justify-start mx-3 mb-3">
          <p className="text-[18px] leading-[20px] font-[400]">Share on:</p>
          {links.map((x, index) => (
            <Link key={index} to={x.link} className="mx-2">
              <div className="flex items-center">
                <div>{x.icon}</div>
                <p className="text-[#999] px-1 text-[18px] leading-[20px]">{x.name}</p>
              </div>
            </Link>
          ))}
        </div>

        {/* Listing Details */}
        <div className="w-full">
          <div className="w-full border-[5px] border-[--medium-sea-green] shadow-2xl shadow-[--medium-sea-green]">
            <div className="h-[4rem] text-2xl px-3 flex items-center text-white bg-[--medium-sea-green]">
              Listing Details
            </div>
            {tableData &&
              Object.entries(tableData).map(([field, value]) => (
                <div key={field} className="flex gap-2 border-b-2 md:py-3 py-1 sm:text-lg font-extralight text-[#333333]">
                  <div className="w-[30%] pl-2">{field}</div>
                  <div className="w-[65%]">
                    {field === "Link to Official page" && value !== "N/A" ? (
                      <button
                        onClick={() => window.open(value, "_blank")}
                        className="custom-button bg-[var(--medium-sea-green)] hover:bg-[var(--sea-green)] inline-block rounded-md text-white font-semibold py-2 px-4"
                      >
                        <span>Official site</span>
                      </button>
                    ) : (
                      <p className="text-[#333333]">{value}</p>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Contact and Query Form */}
        <div className="flex flex-col md:flex-row w-full mt-[5rem] gap-12">
          <div className="md:w-[50%] w-full p-2">
            <HeaderQuery data={headingData} />
          </div>
          <div className="md:w-[50%] w-full">
            <QueryForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualAkiya;