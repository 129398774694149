import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaTrash, FaArrowUp, FaArrowDown } from 'react-icons/fa6';

function AkiyaTweak() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    Address: '',
    Area_of_House: '',
    Area_of_Land: '',
    Contact_Info: '',
    Coordinates: [],
    Empty_or_Not: '',
    Images: [],
    Link_to_Page: '',
    No_of_Floors: '',
    Price: '',
    Room_Type: '',
    Serial_Number: '',
    Year_of_Construction: '',
    property_id: ''
  });
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    axios.get(`https://app.akiya2.com/one_listing/${id}`)
      .then(response => setFormData(response.data))
      .catch(error => console.error("There was an error fetching the data!", error));
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`https://app.akiya2.com/property_tweak/${id}`, formData)
      .then(() => setMessage('Data has been updated successfully.'))
      .catch(() => setMessage('There was an error updating the data.'));
  };

  const handleCoordinatesChange = (index, value) => {
    const updatedCoordinates = [...formData.Coordinates];
    updatedCoordinates[index] = value;
    setFormData(prevState => ({ ...prevState, Coordinates: updatedCoordinates }));
  };

  const handleImageChange = (index, newIndex) => {
    if (newIndex < 0 || newIndex >= formData.Images.length) return;

    const updatedImages = [...formData.Images];
    const [movedImage] = updatedImages.splice(index, 1);
    updatedImages.splice(newIndex, 0, movedImage);

    setFormData(prevState => ({ ...prevState, Images: updatedImages }));
  };

  const handleImageDelete = (index) => {
    const updatedImages = formData.Images.filter((_, i) => i !== index);
    setFormData(prevState => ({ ...prevState, Images: updatedImages }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append('ID', id);
    formData.append('image', selectedFile);

    axios.post(`https://app.akiya2.com/addImageToListings`, formData)
      .then(response => {
        // console.log(response);
        setFormData(prevState => ({
          ...prevState,
          Images: [...prevState.Images, "https://app.akiya2.com/"+response.data.image_path]
        }));
        setSelectedFile(null);
        setMessage('Image uploaded successfully.');
      })
      .catch(() => setMessage('There was an error uploading the image.'));
  };

  const renderInput = (label, name, type = 'text') => (
    <div className="mb-4 md:w-1/3 sm:w-1/2 w-full px-2">
      <label className="text-sm font-medium text-gray-800">{label}</label>
      <input
        type={type}
        name={name}
        value={formData[name]}
        onChange={handleChange}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#5ab963] focus:border-[#5ab963] sm:text-sm"
      />
    </div>
  );

  return (
    <div className='w-full flex justify-center items-center'>
      <div className="flex-col max-w-[1440px] w-full">
        <p className="text-2xl font-bold mb-10 text-center">Akiya ID: {id}</p>
        <form onSubmit={handleSubmit} className="flex flex-wrap">
          {renderInput('Address', 'Address')}
          {renderInput('Area of House', 'Area_of_House')}
          {renderInput('Area of Land', 'Area_of_Land')}
          {renderInput('Contact Info', 'Contact_Info')}
          {renderInput('Empty or Not', 'Empty_or_Not')}
          {renderInput('Link to Page', 'Link_to_Page')}
          {renderInput('No of Floors', 'No_of_Floors')}
          {renderInput('Price', 'Price')}
          {renderInput('Room Type', 'Room_Type')}
          {renderInput('Serial Number', 'Serial_Number')}
          {renderInput('Year of Construction', 'Year_of_Construction')}
          {renderInput('Property ID', 'property_id')}
          <div className="mb-4 md:w-1/3 sm:w-1/2 w-full px-2">
            <label className="text-sm font-medium text-gray-800">Latitude</label>
            <input
              type="text"
              value={formData.Coordinates[0] || ''}
              onChange={(e) => handleCoordinatesChange(0, e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#5ab963] focus:border-[#5ab963] sm:text-sm"
            />
          </div>
          <div className="mb-4 md:w-1/3 sm:w-1/2 w-full px-2">
            <label className="text-sm font-medium text-gray-800">Longitude</label>
            <input
              type="text"
              value={formData.Coordinates[1] || ''}
              onChange={(e) => handleCoordinatesChange(1, e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#5ab963] focus:border-[#5ab963] sm:text-sm"
            />
          </div>
          <div className="w-full px-2 mb-4">
            <label className="text-lg font-bold text-gray-800">Images</label>
            <table className="w-full mt-2 border border-gray-300 rounded-md">
              <thead>
                <tr className="border-b flex justify-around pl-[5rem]">
                  <th className="py-2 ">Image</th>
                  <th className="py-2 pl-[5rem]">Actions</th>
                </tr>
              </thead>
              <tbody>
                {formData.Images.map((image, index) => (
                  <tr className='flex justify-around' key={index}>
                    <td className="px-4 py-2 border-b flex items-center space-x-2">
                      <button
                        type="button"
                        onClick={() => handleImageChange(index, index - 1)}
                        disabled={index === 0}
                        className={`bg-gray-300 text-black p-2 rounded-md hover:bg-gray-400 ${index === 0 ? 'cursor-not-allowed' : ''}`}
                      >
                        <span className="text-2xl"><FaArrowUp /></span> {/* Up Arrow */}
                      </button>
                      <img src={image} alt={`image-${index + 1}`} className="w-24 h-24 object-cover mx-2" />
                      <button
                        type="button"
                        onClick={() => handleImageChange(index, index + 1)}
                        disabled={index === formData.Images.length - 1}
                        className={`bg-gray-300 text-black p-2 rounded-md hover:bg-gray-400 ${index === formData.Images.length - 1 ? 'cursor-not-allowed' : ''}`}
                      >
                        <span className="text-2xl"><FaArrowDown /></span> {/* Down Arrow */}
                      </button>
                    </td>
                    <td className="px-4 py-2 border-b flex items-center space-x-2">
                      <button
                        type="button"
                        onClick={() => handleImageDelete(index)}
                        className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mb-4 md:w-1/3 sm:w-1/2 w-full px-2">
            <label className="text-sm font-medium text-gray-800">Upload New Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#5ab963] focus:border-[#5ab963] sm:text-sm"
            />
            <button
              type="button"
              onClick={handleFileUpload}
              disabled={!selectedFile}
              className="mt-2 px-4 py-2 bg-[#5ab963] text-white rounded-md hover:bg-[#4ea257]"
            >
              Upload Image
              </button>
      </div>
      <button
        type="submit"
        className="w-[60%] mx-auto py-4 my-4 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#5ab963] hover:bg-[#4ea257] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5ab963]"
      >
        Submit
      </button>
    </form>
    {message && (
      <div className='w-full flex justify-center items-center'>
        <p className="px-3 py-2 bg-[#5ab96323] my-4 text-green-600 rounded">{message}</p>
      </div>
    )}
  </div>
</div>

);
}

export default AkiyaTweak;