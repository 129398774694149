import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import createSlug from "../../createSlug";

const BlogCards = ({ item }) => {
  const navigate = useNavigate();
  
  const slug = useMemo(() => createSlug(item.title), [item.title]);
  const { title, imageUrls, date, content, index, author } = item;

  return (
    <div
      className="flex shadow-lg lg:h-[36rem] md:h-[33rem] lg:w-[100%] cursor-pointer"
      onClick={() => navigate(`/blogs/${index}/${slug}`)}
    >
      <div className="flex flex-col">
        <div className="h-[18rem]">
          <img
            loading="lazy"
            src={imageUrls} // Use optimized image if possible
            alt="Akiya2.0 blogs"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="mx-3 px-[0.8rem]">
          <div className="flex justify-between mt-[2rem]">
            <p className="text-[--medium-sea-green]">{author}</p>
            <p className="text-[--medium-sea-green]">{date}</p>
          </div>
          <h2 className="mt-0 text-center leading-[36px] text-[24px] font-[800]">
            {title}
          </h2>

          <p className="text-[#949995] text-[16px] font-[300] leading-[24px] pb-5">
            {content}...
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogCards;