import React from "react";
import { useNavigate } from "react-router-dom";
import Setting from "../Svgs/Setting";
import "./styles.css";

const HeadingForAll = (props) => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#5ab963] flex justify-center relative ">
      {props.isProfile && (
        <button
          onClick={() => navigate("/profile-settings")}
          className="absolute right-2 bottom-2    "
        >
          <div className="h-10 w-10 stroke-white">
            <Setting />
          </div>
        </button>
      )}
      <div className="md:ml-[3rem] ml-[1.5rem] mr-[1.5rem] text-[#fff] md:pb-[2.5rem] w-[100%] max-w-[1440px]">
        <h2 className="m-0 md:text-[24px] text-[15px] font-[800] pt-[0.5rem]">
          {props.smallHeading}
        </h2>
        <h2 className="md:text-[72px] text-[32px] font-[800] md:leading-[78px] leading-[35px] md:py-[0.5rem] ">
          {props.bigHeading}
        </h2>
      </div>
    </div>
  );
}

export default HeadingForAll;
