import React, { useState } from 'react';
import axios from 'axios';
import MessageBox from './MessageBox';

const ForgetPasswordForm = ({ onToggle }) => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      setMessageType('error');
      return;
    }

    try {
      await axios.post('https://app.akiya2.com/reset-password', { email, newPassword });
      setMessage('Password has been reset successfully.');
      setMessageType('success');
      setEmail('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      setMessageType('error');
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Reset Password</h2>
      {message && <MessageBox message={message} type={messageType} />}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border border-gray-400 p-2 w-full focus:outline-[#5ab963]"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="border border-gray-400 p-2 w-full focus:outline-[#5ab963]"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="border border-gray-400 p-2 w-full focus:outline-[#5ab963]"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-[#5ab963] text-white py-2 px-4 rounded hover:bg-[#48944f]"
        >
          Reset Password
        </button>
      </form>
      <p className="mt-4">
        Remembered your password?{' '}
        <button onClick={onToggle} className="text-[#5ab963] hover:underline">
          Login
        </button>
      </p>
    </div>
  );
};

export default ForgetPasswordForm;
