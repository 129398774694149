import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
function QuestionBox() {
  return (
    <div className="flex flex-col items-center md:flex-row my-5">
  <div className="w-full md:w-1/2 md:mr-5">
    <h2 className="text-center md:text-right text-[24px] font-[800] mb-3 md:mb-0">Have a question?</h2>
    <h3 className="text-center md:text-right text-[#949995] text-[24px] font-[300] leading-[36px]">Feel free to get in touch with us!</h3>
  </div>

  <div className="w-full md:w-1/2 mt-3 md:mt-0 md:ml-5 flex justify-center">
    <div className="text-center md:text-right">
      <HashLink smooth to="/about-us/#form">
        <button className=" w-[100vw] md:mr-[8rem] md:w-auto md:rounded-md bg-[#5ab963] px-6 py-4 text-white text-lg font-bold">
          Contact Us
        </button>
      </HashLink>
    </div>
  </div>
</div>

  );
}

export default QuestionBox;
