// checker.js
const checkAndTrimData = (dataArray) => {
    return dataArray.filter((item) => {
      const keys = Object.keys(item);
      let nullCount = 0;
  
      keys.forEach((key) => {
        if (
          item[key] === null ||
          item[key] === "N/A" ||
          item[key] === "none" ||
          item[key] === "" ||
          item[key] === "None"
        ) {
          nullCount += 1;
        }
      });
  
      return nullCount < 7;
    });
  };
  
  export default checkAndTrimData;
  