import React from "react";
import MapComponent from "./MapIndividualProp";

function FirstSection(props) {
  const data = props.data;

  return (
    <div className="flex flex-col lg:flex-row h-[100%]">
      {/* Left Section */}
      <div className="w-full lg:w-7/12 lg:pr-8">
        <div className="mb-6">
          <h2 className="text-2xl md:text-3xl font-extrabold">
            {data.smallAdress}
          </h2>
          <p className="text-justify text-base md:text-lg font-light text-[#949995] mt-2">
            {data.about}
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl md:text-3xl font-extrabold">Access</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
            <div>
              <p className="text-base md:text-lg font-light text-[#949995]">
                {data.nearestAirport}
              </p>
            </div>
            <div>
              <p className="text-base md:text-lg font-light text-[#949995]">
                {data.distanceFromAirport}
              </p>
            </div>
            <div>
              <p className="text-base md:text-lg font-light text-[#949995]">
                Check In Time: 3:00 PM
              </p>
            </div>
            <div>
              <p className="text-base md:text-lg font-light text-[#949995]">
                Check Out Time: 10:00 AM
              </p>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl md:text-3xl font-extrabold">Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
            <div>
              <p className="text-base md:text-lg font-light text-[#949995]">
                Size: {data.size}m<sup>2</sup>
              </p>
            </div>
            <div>
              <p className="text-base md:text-lg font-light text-[#949995]">
                Accommodate: {data.accommodation}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Right Section - Maps */}
      <div className="w-full lg:w-5/12 flex justify-center items-center mt-8 lg:mt-0">
        <MapComponent
          apiKey={"AIzaSyDhd_bCluPIx2cSHS7pGd5wVPkIRU7rpxQ&"}
          address={data.fullAdress}
        />
      </div>
    </div>
  );
}

export default FirstSection;