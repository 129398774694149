import React from 'react'

function HeadingforSmall(props) {
  return (
    <div className='bg-[#5ab963] flex justify-center'>
        <div className='ml-[3rem] text-[#fff] pb-[1rem] w-[100%] max-w-[1440px]'>
            <h2 className='text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-[900] leading-tight py-[0.5rem]'>{props.bigHeading}</h2>
        </div>
    </div>
  )
}

export default HeadingforSmall;
