import React from "react";
import Text from "./Text";
import HeadingForAll from "../HeadingForAll/HeadingForAll";

function Terms() {
  const firstHeading = "Keeping it transparent for you";
  const secondHeading = "Policies on Akiya2.0";

  return (
    <>
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
        <div className="w-full flex flex-col items-center">
      {/* HeadingForAll remains unaffected */}

      {/* Center Text component */}
      <div className="w-full max-w-[1440px] px-4 mt-8 flex justify-center">
        <Text />
      </div>
    </div>
    </>

  );
}

export default Terms;
