import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Loader from "../../Loader/Loader";
import axios from "axios";

const MapComponent = ({ address, apiKey }) => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const geocodeAddress = async () => {
      if (!address) return;

      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${apiKey}`
        );

        if (response.data.status !== "OK") {
          throw new Error("Failed to fetch");
        }

        const { lat, lng } = response.data.results[0].geometry.location;
        setMarkerPosition({ lat, lng });
        setIsLoading(false);
      } catch (error) {
        setError("Error fetching data");
        setIsLoading(false);
      }
    };

    geocodeAddress();
  }, [address, apiKey]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  if (isLoading) {
    return <Loader big={false} />;
  }

  if (error) {
    return null;
  }

  return (
    <div className="w-full h-0 pb-[56.25%] relative">
      {/* Aspect Ratio Box (16:9) */}
      <div className="absolute top-0 left-0 w-full h-full">
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={15}
            center={markerPosition}
          >
            <Marker position={markerPosition} />
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default MapComponent;