import React from "react";
import ReactDOM from "react-dom/client";
import { hydrate, render } from "react-dom"
import "./index.css";
import App from "./App";
import { UserProvider } from "./Context/UserContext";
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById("root");
const helmetContext = {}; // Assuming you're managing helmet context globally

const app = (
  <BrowserRouter>
    <UserProvider>
      <HelmetProvider context={helmetContext}>
        <App />
      </HelmetProvider>
    </UserProvider>
  </BrowserRouter>
);

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement); // For SSR
} else {
  render(app, rootElement); // For CSR
}