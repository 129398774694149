import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const FirstSOVFullImage = "/images/Home/FirstSOVFullImage.webp";

function FirstSOV() {
  const [backgroundPosition, setBackgroundPosition] = useState("center -120px");
  const [shouldLoadBackground, setShouldLoadBackground] = useState(false);

  useEffect(() => {
    // Function to check screen width and update background loading conditionally
    const checkScreenSize = () => {
      if (window.innerWidth >= 1440) {
        setShouldLoadBackground(true);
      } else {
        setShouldLoadBackground(false);
      }
    };

    // Set initial background load state and listen for screen resizing
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    // Adjust background position based on screen size and height
    const updateBackgroundPosition = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (width >= 1440 && height <= 820 && height >= 600) {
        setBackgroundPosition("center -180px");
      } else if (width >= 1440 && height <= 600) {
        setBackgroundPosition("center -250px");
      } else {
        if (width >= 1024) {
          setBackgroundPosition("center -120px");
        } else if (width >= 768) {
          setBackgroundPosition("center -80px");
        } else if (width >= 640) {
          setBackgroundPosition("center -40px");
        } else {
          setBackgroundPosition("center 0");
        }
      }
    };

    // Use debounce to reduce resize calls
    const debounceUpdatePosition = debounce(updateBackgroundPosition, 100);
    window.addEventListener("resize", debounceUpdatePosition);
    updateBackgroundPosition();

    return () => window.removeEventListener("resize", debounceUpdatePosition);
  }, []);

  return (
    <section className="home-container w-full flex justify-center items-center">
      <div className="background-wrapper w-full relative">
        {shouldLoadBackground && (
          <div
            className="blurred-background absolute top-0 left-1/2 transform -translate-x-1/2 w-screen h-full"
            style={{
              backgroundImage: `url(${FirstSOVFullImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "blur(10px)",
              zIndex: -1,
            }}
          ></div>
        )}

        <div
          className="firstSOVImage max-w-[1440px] w-full max-h-[700px] min-h-[450px] h-[56vh] md:h-[70vh] lg:h-[63vh] flex items-center px-4 py-8 md:px-6 lg:px-8 mx-auto relative"
          style={{
            backgroundImage:`url(${FirstSOVFullImage})`,
            backgroundSize: "cover",
            backgroundPosition: backgroundPosition,
            backgroundRepeat: "no-repeat",
            loading: "lazy",
          }}
        >
          <div className="w-full text-white flex justify-center items-center">
            <div className="flex flex-col w-full justify-center max-w-[1440px] ">
              <h1 className="text-[2rem] font-bold leading-tight text-left mb-[10px]">
                Own a slice of your own Japanese akiya countryside
              </h1>

              <div className="text-left">
                <Link to="/services/how-it-works">
                  <button className="px-[3rem] md:px-[5rem] py-3 text-xl bg-green-500 text-white font-[700] rounded-md hover:bg-green-600">
                    Akiya2.0 Services
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// Debounce function for efficiency
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default FirstSOV;