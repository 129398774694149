import axios from "axios";


const admins = ["rohit16nov1999@gmail.com"];

export const fetchData = async (url) => {
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    // console.log(err);
    return [];
  }
};
export const fetchDataWithToken = async (url, access_token) => {
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    // console.log(res.data);

    if (admins.includes(res.data.email)) {
      localStorage.setItem("isAdmin", true);
    } else {
      localStorage.setItem("isAdmin", false);
    }
    return res.data;
  } catch (err) {
    // console.log(err);
    return null;
  }
};
export const updateDataWithToken = async (url, access_token, data) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const updateImageWithToken = async (url, access_token, file) => {
  // console.log("here");
  try {
    // Create FormData object to send file
    const formData = new FormData();
    formData.append('file', file);

    // Make a PUT request with the file data and token
    const res = await axios.put(url, formData, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'multipart/form-data', // Set content type for file upload
      },
    });

    // Log response data and return
    // console.log(res.data);
    return res.data;
  } catch (error) {
    // Handle errors
    console.error(error);
    return null;
  }
};


