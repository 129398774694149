import React from "react";
import HeadingForAll from "../../HeadingForAll/HeadingForAll";
import { Link } from "react-router-dom";
function DownloadProspectusFile() {
  const smallHeading = "A step closer to us";
  const bigHeading = "Download the prospectus";

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      {/* <header className="bg-black text-white p-4">
        <nav className="container mx-auto flex justify-between items-center">
          <div className="text-2xl font-bold">PlanetDAO</div>
          <div className="hidden md:flex space-x-4 items-center">
            <a href="#" className="hover:text-gray-300">WHAT WE DO</a>
            <a href="#" className="hover:text-gray-300">HOW IT WORKS</a>
            <a href="#" className="hover:text-gray-300">PROPERTIES</a>
            <a href="#" className="hover:text-gray-300">NEWS</a>
            <a href="#" className="hover:text-gray-300">日本語</a>
            <button className="bg-[#d3e561] text-black px-4 py-2 rounded font-bold">
              BECOME INVESTOR
            </button>
          </div>
        </nav>
      </header> */}

      {/* Main Content */}
      <main className="flex-grow">
        {/* Title Section */}
        {/* <div className="bg-[#d3e561] py-12">
          <h1 className="text-4xl font-bold text-center text-black">Download presentation</h1>
        </div> */}

        <div>
          <HeadingForAll smallHeading={smallHeading} bigHeading={bigHeading} />
        </div>

        {/* Form Section */}
        <div className=" px-4 py-8 w-[100%]">
          <div className="mr-[30px]">
            <h2 className="dark-header">
              Interested in Exploring Unique Property Opportunities or Joining
              Our Exclusive Travel Club?
            </h2>
            <p className="light-header">
              Please fill in the form below to receive an email with detailed
              download instructions and more information about our offerings.
              Whether you're looking to invest in charming, abandoned houses
              "Akiya" or experience luxurious stays through our travel club,
              we're here to assist you every step of the way.
            </p>
          </div>
          <div className="mx-[30px]">

          <form className="space-y-6">
            <div>
              <label className="block mb-2 font-bold">
                <span className="bg-black text-white text-xs px-1 mr-1">
                  Req*
                </span>
                Your name:
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded bg-gray-100"
                required
              />
            </div>

            <div>
              <label className="block mb-2 font-bold">
                <span className="bg-black text-white text-xs px-1 mr-1">
                  Req*
                </span>
                Your email:
              </label>
              <input
                type="email"
                className="w-full p-2 border rounded bg-gray-100"
                required
              />
            </div>

            <div>
              <label className="block mb-2 font-bold">
                <span className="bg-black text-white text-xs px-1 mr-1">
                  Req*
                </span>
                Please confirm your email:
              </label>
              <input
                type="email"
                className="w-full p-2 border rounded bg-gray-100"
                required
              />
            </div>

            <div>
              <label className="flex items-start">
                <span className="bg-black text-white text-xs px-1 mr-2 mt-1">
                  Req*
                </span>
                <span className="font-bold">
                  Handling of personal information
                </span>
              </label>
              <div className="flex items-center mt-2">
                <input type="checkbox" className="mr-2" required />
                <span>I have read and accepted the following documents:</span>
              </div>
              <div className="ml-6 mt-2">
                <div>
                  <Link
                    to="terms"
                    target="blank"
                    className="text-green-700 hover:underline"
                  >
                    Terms of Service
                  </Link>
                </div>
                <div>
                  <Link
                    to="terms"
                    target="blank"
                    className="text-green-700 hover:underline"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="bg-black text-white px-8 py-3 rounded font-bold"
              >
                CONFIRM
              </button>
            </div>
          </form>

          </div>
        </div>
      </main>
    </div>
  );
}

export default DownloadProspectusFile;
