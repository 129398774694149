export const prefectures = [
    {
      "name": "Hokkaido",
      "coordinates": [43.0646, 141.3468]
    },
    {
      "name": "Aomori",
      "coordinates": [40.824, 140.74]
    },
    {
      "name": "Iwate",
      "coordinates": [39.5933, 141.3695]
    },
    {
      "name": "Miyagi",
      "coordinates": [38.4459, 140.928]
    },
    {
      "name": "Akita",
      "coordinates": [39.7481, 140.4086]
    },
    {
      "name": "Yamagata",
      "coordinates": [38.4482, 140.1024]
    },
    {
      "name": "Fukushima",
      "coordinates": [37.7595, 140.4728]
    },
    {
      "name": "Ibaraki",
      "coordinates": [36.3075, 140.319]
    },
    {
      "name": "Tochigi",
      "coordinates": [36.689, 139.819]
    },
    {
      "name": "Gunma",
      "coordinates": [36.486, 139.068]
    },
    {
      "name": "Saitama",
      "coordinates": [35.9966, 139.3476]
    },
    {
      "name": "Chiba",
      "coordinates": [35.6047, 140.1233]
    },
    {
      "name": "Tokyo",
      "coordinates": [35.6895, 139.6917]
    },
    {
      "name": "Kanagawa",
      "coordinates": [35.4478, 139.6425]
    },
    {
      "name": "Niigata",
      "coordinates": [37.9022, 139.0236]
    },
    {
      "name": "Toyama",
      "coordinates": [36.6953, 137.2113]
    },
    {
      "name": "Ishikawa",
      "coordinates": [36.5947, 136.6256]
    },
    {
      "name": "Fukui",
      "coordinates": [36.0652, 136.2215]
    },
    {
      "name": "Yamanashi",
      "coordinates": [35.6122, 138.6116]
    },
    {
      "name": "Nagano",
      "coordinates": [36.1106, 138.0576]
    },
    {
      "name": "Gifu",
      "coordinates": [35.7772, 137.0553]
    },
    {
      "name": "Shizuoka",
      "coordinates": [34.9769, 138.3831]
    },
    {
      "name": "Aichi",
      "coordinates": [35.1802, 136.9066]
    },
    {
      "name": "Mie",
      "coordinates": [34.7303, 136.5086]
    },
    {
      "name": "Shiga",
      "coordinates": [35.2153, 136.1386]
    },
    {
      "name": "Kyoto",
      "coordinates": [35.0116, 135.7681]
    },
    {
      "name": "Osaka",
      "coordinates": [34.6937, 135.5023]
    },
    {
      "name": "Hyogo",
      "coordinates": [34.6913, 135.183]
    },
    {
      "name": "Nara",
      "coordinates": [34.6851, 135.8049]
    },
    {
      "name": "Wakayama",
      "coordinates": [34.2261, 135.1675]
    },
    {
      "name": "Tottori",
      "coordinates": [35.355, 133.8486]
    },
    {
      "name": "Shimane",
      "coordinates": [35.0706, 132.5561]
    },
    {
      "name": "Okayama",
      "coordinates": [34.8964, 133.7952]
    },
    {
      "name": "Hiroshima",
      "coordinates": [34.3853, 132.4553]
    },
    {
      "name": "Yamaguchi",
      "coordinates": [34.1781, 131.4738]
    },
    {
      "name": "Tokushima",
      "coordinates": [33.9192, 134.2515]
    },
    {
      "name": "Kagawa",
      "coordinates": [34.2439, 133.9922]
    },
    {
      "name": "Ehime",
      "coordinates": [33.6248, 132.856]
    },
    {
      "name": "Kochi",
      "coordinates": [33.4215, 133.3669]
    },
    {
      "name": "Fukuoka",
      "coordinates": [33.5903, 130.4017]
    },
    {
      "name": "Saga",
      "coordinates": [33.2864, 130.115]
    },
    {
      "name": "Nagasaki",
      "coordinates": [32.7448, 129.8735]
    },
    {
      "name": "Kumamoto",
      "coordinates": [32.7897, 130.7417]
    },
    {
      "name": "Oita",
      "coordinates": [33.2382, 131.6126]
    },
    {
      "name": "Miyazaki",
      "coordinates": [31.9111, 131.4239]
    },
    {
      "name": "Kagoshima",
      "coordinates": [31.5602, 130.5581]
    },
    {
      "name": "Okinawa",
      "coordinates": [26.2124, 127.6809]
    }
  ];
  