import React, { useState, useEffect, memo } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import { prefectures } from "./data"; // Verify the path and file name



const AkiyaAkiya = "/images/prefecture/AkiyaAkiya.png";

const CustomMarker = memo(
  ({ position, title, id, isHovered, handleMarkerClick, handleMouseOver, handleMouseOut }) => {
    const scaledSize = isHovered ? new window.google.maps.Size(50, 50) : new window.google.maps.Size(40, 40);

    return (
      <Marker
        position={position}
        title={title}
        icon={{ url: AkiyaAkiya, scaledSize }}
        onClick={() => handleMarkerClick(id)}
        onMouseOver={() => handleMouseOver(id)}
        onMouseOut={handleMouseOut}
      />
    );
  }
);

const MapComponent = ({ prefectureName, apiKey, akiyaData }) => {
  const [markers, setMarkers] = useState([]);
  const [hoveredMarkerId, setHoveredMarkerId] = useState(null);
  const [initialCenter, setInitialCenter] = useState(null);

  useEffect(() => {
    // Find coordinates from the prefectures array using a for loop
    let foundPrefecture = null;
    for (let i = 0; i < prefectures.length; i++) {
      if (prefectures[i].name === prefectureName) {
        foundPrefecture = prefectures[i];
        break;
      }
    }

    if (foundPrefecture) {
      setInitialCenter({ lat: foundPrefecture.coordinates[0], lng: foundPrefecture.coordinates[1] });
    } else {
      console.error(`Coordinates not found for ${prefectureName}`);
    }

    // Update markers based on akiyaData (if needed)
    if (akiyaData && Array.isArray(akiyaData)) {
      const updatedMarkers = akiyaData.map((item) => ({
        position: { lat: item.Coordinates[0], lng: item.Coordinates[1] },
        title: item.Address,
        id: item.property_id,
      }));
      setMarkers(updatedMarkers);
    } else {
      // Handle case where akiyaData is not an array or undefined
      setMarkers([]);
    }
  }, [prefectureName, apiKey, akiyaData]);

  const handleMarkerClick = (id) => {
    const url = "/individual-akiya/" + id;
    window.open(url, "_blank");
  };

  const handleMouseOver = (id) => {
    setHoveredMarkerId(id);
  };

  const handleMouseOut = () => {
    setHoveredMarkerId(null);
  };

  const containerStyle = {
    width: "100%",
    height: "490px",
  };

  if (!initialCenter) {
    return null; // Return null or a loading indicator until initialCenter is set
  }

  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap mapContainerStyle={containerStyle} zoom={9} center={initialCenter}>
        {markers.map((marker) => (
          <CustomMarker
            key={marker.id}
            position={marker.position}
            title={marker.title}
            id={marker.id}
            isHovered={hoveredMarkerId === marker.id}
            handleMarkerClick={handleMarkerClick}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
