import React, { useState } from 'react';
import { pricingData } from '../data.js'; // Make sure the path is correct

function Test1() {
  const [activeTab, setActiveTab] = useState('Search');
  const tabs = ['Search', 'Contract Signing', 'Market Analytics'];
  const colors = {
    'Search': 'bg-white',
    'Contract Signing': 'bg-white',
    'Market Analytics': 'bg-white'
  };

  const renderTable = (data) => (
    <div className="p-4 overflow-auto text-black">
      <table className="table-auto w-full text-left border-collapse border border-gray-200">
        <thead>
          <tr className="bg-green-200 text-black font-[800]">
            <th className="px-4 py-2 border-r border-white">Service</th>
            {/* <th className="px-4 py-2 border-r border-white">Plan</th>
            <th className="px-4 py-2 border-r border-white">Level</th> */}
            <th className="px-4 py-2 border-r border-white">Description</th>
            <th className="px-4 py-2 border-r border-white">Duration</th>
            <th className="px-4 py-2 border-r border-white">Price</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className={`${index % 2 === 0 ? 'bg-green-500 text-white' : 'bg-white text-black'}`}>
              <td className="border px-4 py-2">{item.service}</td>
              {/* <td className="border px-4 py-2">{item.plan}</td>
              <td className="border px-4 py-2">{item.level}</td> */}
              <td className="border px-4 py-2">{item.notes}</td>
              <td className="border px-4 py-2">{item.duration}</td>
              <td className="border px-4 py-2">{item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const pricingDetails = {
    'Search': renderTable(pricingData.search),
    'Contract Signing': renderTable(pricingData.contractSigning),
    'Market Analytics': renderTable(pricingData.marketAnalytics)
  };

  return (
    <div className="flex flex-col items-center max-h-screen">
      <div className="relative w-4/5 flex items-center my-4 bg-green-500 rounded">
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`relative flex-1 py-5 px-4 mx-0 ${activeTab === tab ? 'bg-gradient-to-t bg-green-700' : 'bg-transparent'} text-white font-[700] rounded transition duration-300 ease-in-out`}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="relative w-4/5 h-[30rem] overflow-hidden">
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`absolute top-0 left-0 w-full transition-opacity duration-800 ${activeTab === tab ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} ${colors[tab]}`}
          >
            <div className="relative">
              {pricingDetails[tab]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Test1;
