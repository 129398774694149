import React from "react";
import { Link } from "react-router-dom";

const op1 = "/images/ourProjects/op1.webp";
const op2 = "/images/ourProjects/op2.webp";
const op3 = "/images/ourProjects/op3.webp";
const op4 = "/images/ourProjects/op4.webp";
const op5 = "/images/ourProjects/op5.webp";
const op6 = "/images/ourProjects/op6.webp";
const op7 = "/images/ourProjects/op7.webp";
const op8 = "/images/ourProjects/op8.webp";
const op9 = "/images/ourProjects/op9.webp";
const op10 = "/images/ourProjects/op10.webp";
const op11 = "/images/ourProjects/op11.webp";
const op12 = "/images/ourProjects/op12.webp";
const op13 = "/images/ourProjects/op13.webp";
const op14 = "/images/ourProjects/op14.webp";
const slider2 = "/images/investInAkiya/slider1.webp";
const slider1 = "/images/investInAkiya/slider2.webp";
const slider3 = "/images/investInAkiya/slider3.webp";
const News1 = "/images/investInAkiya/JTNews/News1.webp";
const News2 = "/images/investInAkiya/JTNews/News2.webp";

const newsJT1 = "Standing Together with Noto";
const newsJT2 =<>
Akiya2.0 firmly supports local recovery and rebuilding efforts in the Noto Peninsula and Ishikawa Prefecture. We have paused our construction plans and will resume in 2025. Our sister company, JapanTravel, has partnered with the Japan Tourism Board Kanazawa to create a 3-day Ishikawa Tour aimed at revitalizing the region's tourism scene and showcasing its beauty to the world.
<br /><br />
For more details on this initiative, please check out the link below.
<br /><br />
#SupportingNoto #能登と共に
</>;


const Circle = ({ color = "blue", size = 7.5 }) => {
  const sizeValue = `${size * 4}px`;
  return (
    <div
      style={{
        width: sizeValue,
        height: sizeValue,
        backgroundColor: color,
        borderRadius: "50%",
      }}
    />
  );
};

const Square = ({ color = "red", size = 7.5 }) => {
  const sizeValue = `${size * 4}px`;
  return (
    <div
      style={{
        width: sizeValue,
        height: sizeValue,
        backgroundColor: color,
      }}
    />
  );
};

const Triangle = ({ color = "yellow", size = 4 }) => {
  const sizeValue = `${size * 4}px`;
  const borderSize = `${size * 2}px`;
  return (
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: `${sizeValue} solid transparent`,
        borderRight: `${sizeValue} solid transparent`,
        borderBottom: `25px solid ${color}`,
      }}
    />
  );
};

export { Circle, Square, Triangle };

export const noto = {
  data: {
    title: "Noto Peninsula",
    about: "A hidden gem of the Sea of Japan",
    largeAbout:
      "The Noto Peninsula (能登半島) in Ishikawa Prefecture extends about 100 kilometers into the Sea of Japan. The peninsula is known for its coastal scenery, particularly along the Okunoto Coast and the Kongo Coast, as well as for its rural atmosphere. Off the beaten path for even experience, it provides a quality seaside experience for the entire family.",
    maker: (
      <div className="">
        <div className="flex">
          <p className="ml-2 mb-2">
            A Akiya2.0 Travel Club Cluster comprises of no more than 5-10
            Akiya2.0 properties, in this case the Shimanami Kaido Cluster.{" "}
          </p>
        </div>
        <div className="flex">
          <Square color={"rgb(112,178,96)"} />
          <p className="ml-2 mb-2">Omi Grove</p>
        </div>
        <div className="flex">
          <Square color={"rgb(230,104,38)"} />
          <p className="ml-2 mb-2">
            Akiya in a cluster for investment  (We work with the local city call and village chief to ensure that we put out only the right akiya that locals want to sell.)

          </p>
        </div>
        <div className="flex">
          <Square color={"rgb(214,79,84)"} />
          <p className="ml-2">
          Local retailers/ restaurants

          </p>
        </div>
      </div>
    ),
  },
};

export const shimanamiKaido = {
  imagesLinks: [slider1, slider2, slider3],
  data: {
    title: "Shimanami Kaido",
    about: "‍Cycling Paradise of the Seto Inland Sea",
    largeAbout:
      "The Shimanami Kaido unfolds as a cycling paradise connecting the islands of the Seto Inland Sea. Celebrated for its scenic landscapes and well-crafted cycling routes, it provides a harmonious blend of nature and infrastructure. Whether an avid enthusiast or a casual rider, the Shimanami Kaido offers an unforgettable journey through the breathtaking beauty of the Seto Inland Sea.",
    maker: (
      <div className="">
        <div className="flex">
          <Triangle color={"rgb(96,160,56)"} />
          <p className="ml-2 mb-2">
            An Akiya2.0 Travel Club Destination, in this case Shimanami Kaido,
            will have 4-5 clusters.
          </p>
        </div>
        <div className="flex">
          <Circle color={"rgb(242,163,88)"} />
          <p className="ml-2">Our Local Partners (Restaurants/ Cafes/ Shops)</p>
        </div>
      </div>
    ),
    maker1: (
      <div className="">
        <div className="flex">
          <Triangle color={"rgb(96,160,56)"} />
          <p className="ml-2 mb-2">
            An Akiya2.0 Travel Club Destination, in this case Noto Peninsula,
            will have 4-5 clusters.
          </p>
        </div>
        <div className="flex">
          <Circle color={"rgb(242,163,88)"} />
          <p className="ml-2">Our Local Partners (Restaurants/ Cafes/ Shops)</p>
        </div>
        <div>



        <div className="flex justify-center items-center">

        </div>

          Our deepest sympathies go out to those affected by the Noto earthquake
          on January 1st, 2024. Japan faces numerous natural challenges, and we
          understand the importance of resilience. That's why, when we first
          purchased our properties prior to the earthquake, we carefully
          assessed them structurally , and are relieved to see that all our
          properties have withstood the earthquake with minimal cosmetic damage.
          <br />
          <br />
          In the spirit of community, we reached out to our neighbors to ensure
          their safety and then immediately halted all construction efforts.
          This decision was made to avoid competing for resources during this
          critical time. We will restart only in 2025.
          <br />
          <br />
          Before the Earthquake, the cities on the Peninsula had been committed
          in the rebranding of Noto Peninsula in a concerted effort to bring in
          greater tourism flows, revitalise the region and improve the cultural
          and economic situation in the prefecture. As the region gets back onto
          its feet following the initial rebuilding efforts - redoubling the
          original effort to promote Noto would be a even tougher and greater
          endeavour.
          <br />
          <br />
          We are committed to supporting the rebuilding effort and standing with
          the community as it recovers. #能登と共に
        </div>
        <div className="mt-[5rem] ">
          <h1 className="text-center mt-4 text-[#000] text-[24px] font-[800] leading-[24px]">
            {newsJT1}
          </h1>
          <h2 className="mx-4 md:mx-[4rem] text-center mt-4 text-[#949995] text-[24px] font-[300] leading-[36px]">
            {newsJT2}
          </h2>
          <div className="flex justify-center items-center pb-[2rem]">
            <Link to={"https://en.japantravel.com/feature/ishikawa"} target="_blank">
            <button className=" w-[100vw] md:w-auto md:rounded-md bg-[#5ab963] px-[30px] py-[18px] text-white text-[16px] font-[800]"
            >
              Support Noto
            </button>
            </Link>

          </div>
        </div>
      </div>
    ),
  },
};

export const shimanamiKaido1 = {
  data: {
    title: "Shimanami Kaido",
    about: "‍Cycling Paradise of the Seto Inland Sea",
    largeAbout:
      "The Shimanami Kaido unfolds as a cycling paradise connecting the islands of the Seto Inland Sea. Celebrated for its scenic landscapes and well-crafted cycling routes, it provides a harmonious blend of nature and infrastructure. Whether an avid enthusiast or a casual rider, the Shimanami Kaido offers an unforgettable journey through the breathtaking beauty of the Seto Inland Sea.",
    maker: (
      <div className="">
        <div className="flex">
          <Triangle color={"rgb(96,160,56)"} />
          <p className="ml-2 mb-2">
            An Akiya2.0 Travel Club Destination, in this case Shimanami Kaido,
            will have 4-5 clusters.
          </p>
        </div>
        <div className="flex">
          <Circle color={"rgb(242,163,88)"} />
          <p className="ml-2">Our Local Partners (Restaurants/ Cafes/ Shops)</p>
        </div>
      </div>
    ),
  },
};
