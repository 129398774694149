import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import HouseBuy from "../Svgs/HouseBuy";
// import HouseRent from "../Svgs/HouseRent";
import gallery from "../Svgs/gallery.svg";
import { UserContext, useUpdateUser, useUser } from "../../Context/UserContext";
import Heart from "../Svgs/Heart";
import Trash from "../Svgs/Trash";

const perfectUrl = (url)=>{
  return "https://" + url;
}
const AkiyaCard = ({ item, isFavorite = false, onDelete }) => {
  const {
    images,
    prefectureName,
    cityName,
    total_area,
    land_area,
    plans,
    built,
    price,
    post_id,
    Empty_or_Not,
    stairs
  } = item;
//   // console.log("data for kominka")
// // console.log(images)
//   // console.log(item);
  // const newprice = price.replace(/[^\d]/g, '');

  const [newprice, setNewprice] = useState(0);
  const [isValidImage, setIsValidImage] = useState(true);
  const navigate = useNavigate();
  const [formatedAreaOfLand, setFormatedAreaOfLand] = useState();
  const [formatedAreaOfHouse, setFormatedAreaOfHouse] = useState();
  const [isFavoritePresent, setIsFavoritePresent] = useState(false);
  const [Address, setAddress] = useState(cityName + ", " + prefectureName);
  const user = useUser();
  const UpdateUser = useUpdateUser();
  const { showModal, setShowModal } = useContext(UserContext);

  // useEffect(() => {
  //   if (price) {
  //     setNewprice(price.replace(/[^\d]/g, ""));
  //   }
  // }, []);
  useEffect(() => {
    //pending
    if (user && user.favorite.length) {
      const isPresent = user.favorite.find((f) => f === post_id);
      setIsFavoritePresent(isPresent);
    }

    if (land_area) {
      let land = land_area.replace("m", "").replace(" ", "");
      const isLastCharDigit = !isNaN(land.slice(-1));

      if (isLastCharDigit) {
        land += "m²";
      }
      setFormatedAreaOfLand(land);
    }

    if (total_area) {
      let house = total_area.replace("m", "").replace(" ", "");
      const isLastCharDigitHouse = !isNaN(house.slice(-1));

      if (isLastCharDigitHouse) {
        house += "m²";
      }

      setFormatedAreaOfHouse(house);
    }
  }, [land_area, total_area]);
  const handleImageLoad = () => {
    setIsValidImage(true);
  };

  // Function to handle image error
  const handleImageError = () => {
    setIsValidImage(false);
  };
  const handleToggleFavorite = (event) => {
    // // console.log(user)
    event.stopPropagation();
    if (!user) {
      setShowModal(true);
    } else if (isFavoritePresent) {
      setIsFavoritePresent(false);

      const favoriteData = user.favorite.filter((f) => f !== post_id);
      UpdateUser({ ...user, favorite: favoriteData });
    } else {
      setIsFavoritePresent(true);
      const favoriteData = [...user.favorite, post_id];
      UpdateUser({ ...user, favorite: favoriteData });
    }
  };
  // Remove last three characters from the price
  let priceWithoutLastThreeDigits;
  let formattedprice;
  if (price) {
    priceWithoutLastThreeDigits = price.toString().slice(0, -3);
    formattedprice = priceWithoutLastThreeDigits.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  }

  // Format the price with commas as thousand separators

  const japaneseYenSymbol = "\u00A5";


  return (
    <div
      className=" shadow-xl h-[32rem] w-full cursor-pointer relative group overflow-hidden transition-transform duration-300 transform hover:scale-105 "
      onClick={() => navigate(`/individual_kominka/${post_id}`)}
    >

      <div className="h-[40%] w-full relative">
        {/* lg:w-[19%] md:w-[32%] sm:w-[100%] h-[30rem] */}

        {!isFavorite && (
          <div className="absolute right-0 flex   w-[4rem] justify-end   h-[3rem] opacity-100 ">
            <button
              onClick={handleToggleFavorite}
              className={`${isFavoritePresent ? " text-[#e09321]  " : "text-white "} px-2 m-1  stroke-[#e09321] `}
            >
              <div className="w-10 h-10 ">
                <Heart />
              </div>
            </button>
          </div>
        )}

        <div className="absolute bottom-0   bg-black/60 w-full h-[3rem] text-white flex justify-center items-center ">
          {/* {price &&   `${japaneseYenSymbol} ${newprice}`} */}
          {/* {// console.log(price)} */}
          {price ? `${japaneseYenSymbol} ${price*10000}`: "Consult with administrator"}

          {/* {// console.log(price)} */}
        </div>

        {images && images[0] ? (
          <img
            loading="lazy"
            className="h-full w-full"
            src={perfectUrl(images[0])}
            alt="Kominka Akiya"
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        ) : (
          <img
            loading="lazy"
            className="h-full w-full"
            src={gallery}
            alt="Kominka Akiya"
          />
        )}
      </div>
      <div className="my-5 mx-2 flex flex-col gap-6 ">
        <div>
          <p className="mb-2">Address:</p>
          {Address && (
            <p className="text-[#333333] text-sm">{Address.slice(0, 90)}</p>
          )}
        </div>
        <div>
          <p className="mb-2">Floor Area:</p>
          <p className="text-[#333333] text-sm">{formatedAreaOfLand}</p>
        </div>
        <div>
          <p className="mb-2">House Area:</p>
          <p className="text-[#333333] text-sm">{formatedAreaOfHouse}</p>
        </div>
        {isFavorite && (
          <button
            className="bg-red-100 rounded-xl absolute bottom-2 right-[40%] "
            onClick={(event) => {
              event.stopPropagation();
              onDelete(post_id);
            }}
          >
            {/* put the delete icons here */}

            <Trash />
          </button>
        )}
      </div>
    </div>
  );
};

export default AkiyaCard;

{
  /* <div className="absolute bottom-1 right-2 text-[--sea-green]"> */
}

// </div>
