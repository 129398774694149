import React from "react";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import FirstSOV from "./FirstSOV";
import SecondSOV from "./SecondSOV";
import ThirdSOV from "./ThirdSOV";
import FourthSOV from "./FourthSOV";
function AboutUs() {
  const firstHeading = "About Us";
  const secondHeading = "A team dedicated to Japan's rich heritage";

  return (
    <>
      <div className="bg-[#5ab963] w-full">
        <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
      </div>

      <div className="flex justify-center">
        <div className="max-w-[1440px]">
          <div className="mt-10"></div>
          <FirstSOV />
          <SecondSOV />
          <ThirdSOV />

          <FourthSOV />
        </div>
      </div>
    </>
  );
}

export default AboutUs;
