import React from 'react';
const ailywarner = "/images/clients/AilyWarner.webp";



const Test2 = () => {
  return (
    <div className="text-center rounded-lg">
      <h2 className="dark-header text-left mb-4 ">What our clients say</h2>
      <p className=" text-[#5ab963] mb-4 text-[25px]">
        “Again thank you, honestly amazing job, <br />
        we are so very happy.<br />
        I think 3 months is excellent from 1st contact to keys”
      </p>
      <div className='flex justify-center items-center'>
        <div className='w-[3rem] h-[3rem]'>
        <img src={ailywarner} className="rounded-full w-full h-full" alt="Akiya2.0 clients" />
        </div>
        <div>
          <p className="font-[300]">Aily and Warner Lamb</p>
        </div>

      </div>
    </div>
  );
};

export default Test2;
