import React from "react";

const DropdownSVG = () => (
  <svg
    className="svg-icon"
    style={{
      width: "1.666015625em",
      height: "1em",
      verticalAlign: "middle",
      fill: "currentColor",
      overflow: "hidden",
    }}
    viewBox="0 0 1706 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M782.02272703 706.46395178L457.91614086 317.53604822h648.21317271z" />
  </svg>
);

export default DropdownSVG;
