import React from "react";
import PropTypes from "prop-types";
import { FaPlus } from "react-icons/fa";
import { motion, useInView } from "framer-motion";

function GreenBoxes({ data = [], color }) {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false });

  if (data.length < 3) {
    console.warn("Insufficient data provided to GreenBoxes");
  }

  return (
    <div ref={ref} className="m-4 text-white relative">
      <div className="relative flex flex-wrap">
        <motion.div
          className="w-full md:w-1/2 rounded-t-[1rem] md:rounded-tr-[5rem] md:rounded-tl-none flex-grow p-4 md:p-8 z-10"
          style={{ backgroundColor: color[0] }}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -100 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          <div className="m-4 md:m-8">
            <h1 className="text-lg md:text-2xl font-bold">{data[0]?.heading || "Default Heading"}</h1>
            <p className="text-sm md:text-base">{data[0]?.content || "Default Content"}</p>
          </div>
        </motion.div>

        <motion.div
          className="w-full md:w-1/2  md:rounded-tr-none md:rounded-tl-[5rem] flex-grow p-4 md:p-8 z-10"
          style={{ backgroundColor: color[1] }}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 100 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          <div className="m-4 md:m-8">
            <h1 className="text-lg md:text-2xl font-bold">{data[1]?.heading || "Default Heading"}</h1>
            <p className="text-sm md:text-base">{data[1]?.content || "Default Content"}</p>
          </div>
        </motion.div>
      </div>

      {/* Icon container */}
      <div className="absolute text-transparent bg-transparent md:bg-white md:text-[rgb(67,146,89)] w-10 h-10 md:w-16 md:h-16 left-1/2 top-[calc(50%-4rem)] md:top-[calc(50%-8rem)] transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center text-xl md:text-2xl rounded-full z-20">
        <FaPlus />
      </div>

      <motion.div
        className="w-full h-60 rounded-b-[1rem] md:rounded-t-none md:rounded-b-[5rem] mt-1 md:mt-2 flex justify-center items-center z-10"
        style={{ backgroundColor: color[1] }}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 100 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
      >
        <div className="flex-col text-center mt-[-0.5rem] p-4">
          <div>
            <h1 className="text-lg md:text-xl font-bold">{data[2]?.smallHeader || "Default Small Header"}</h1>
          </div>

          <div className="mt-2 md:mt-4">
            <h2 className="text-2xl md:text-4xl font-bold">{data[2]?.bigHeader || "Default Big Header"}</h2>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

GreenBoxes.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.string,
      smallHeader: PropTypes.string,
      bigHeader: PropTypes.string,
    })
  ),
  color: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default GreenBoxes;
