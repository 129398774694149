import React from 'react'

function Listing({ ser_no, header, content }) {
  return (
    <div className='w-full sm:w-[50%] md:w-[38%] mdlg:w-[28%] mb-6 mx-4'>
      <div className='text-[#5ab963] text-2xl font-bold'>{ser_no}</div>
      <div className='flex justify-center text-[#5ab963] text-2xl font-bold'>{header}</div>
      <div className='text-[#949995] text-base'>{content}</div>
    </div>
  )
}

export default Listing
