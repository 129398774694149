import React, { useState, useEffect } from "react";
import CardSlider from "../../cardSlider/CardSlider";
import BlogCard from "../../blogCard/BlogCard";
import { fetchData } from "../../../Utils/CommonFunctions";

function ThirdSection() {
  const [latestBlogData, setLatestBlogData] = useState([]);
  useEffect(() => {
    async function getData() {
      try {
        const response = await fetchData(`https://app.akiya2.com/blogs`);

        // Sort and slice the data
        const sortedData = response
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);

        setLatestBlogData(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    getData();
  }, []);

  if (latestBlogData.length === 0) {
    return null;
  }

  return (
    <div className="">
      <CardSlider
        data={latestBlogData}
        Card={BlogCard}
        endValues={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
        }}
      />
    </div>
  );
}

export default ThirdSection;