import axios from "axios";

// Fetch blog data
const fetchBlogData = async (blogId) => {
  try {
    const url = `https://app.akiya2.com/blog/${blogId}`;
    // console.log('Fetching blog data from URL:', url);

    const response = await axios.get(url, { validateStatus: false });
    // console.log('Response status:', response.status);

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = response.data;
    // console.log('Fetched blog data:', data);
    return data;
  } catch (error) {
    console.error('Failed to fetch blog data:', error.message);
    return null;
  }
};

// Fetch akiya data
const fetchAkiyaData = async (akiyaId) => {
  // return {
  //   municipalityName: "Shizuoka",
  //   serialNo: "12345",
  //   images: ["https://www.akiya2.com/images/sample-akiya.jpg"],
  // };

  try {
    const url = `https://app.akiya2.com/one_listing/${akiyaId}`;
    // console.log('Fetching akiya data from URL:', url);

    const response = await axios.get(url, { validateStatus: false });

    // console.log('Response status:', response.status);

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = response.data;
    // console.log('Fetched akiya data:', data);

    return data;
  } catch (error) {
    console.error('Failed to fetch akiya data:', error.message);
    return null;
  }
};

// Fetch property data
const fetchPropertyData = async (propertyId) => {
  // return {
  //   name: "Traditional Japanese House",
  //   smallAbout: "A beautiful traditional house in rural Japan.",
  //   images: ["https://www.akiya2.com/images/sample-property.jpg"],
  // };
  try {
    const url = `https://app.akiya2.com/one_property/${propertyId}`;
    // console.log('Fetching property data from URL:', url);

    const response = await axios.get(url, { validateStatus: false });

    // console.log('Response status:', response.status);

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = response.data;
    // console.log('Fetched property data:', data);

    return data;
  } catch (error) {
    console.error('Failed to fetch property data:', error.message);
    return null;
  }
};

// Fetch property data
const fetchKominkaData = async (kominkaId) => {
  // return {
  //   name: "Traditional Japanese House",
  //   smallAbout: "A beautiful traditional house in rural Japan.",
  //   images: ["https://www.akiya2.com/images/sample-property.jpg"],
  // };
  try {
    const url = `https://app.akiya2.com/individual_kominka/${kominkaId}`;
    // console.log('Fetching kominka data from URL:', url);

    const response = await axios.get(url, { validateStatus: false });

    // console.log('Response status:', response.status);

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = response.data;
    // console.log('Fetched kominka data:', data);

    return data;
  } catch (error) {
    console.error('Failed to fetch kominka data:', error.message);
    return null;
  }
};

export const defaultMetaTags = {
  title: 'Akiya2.0',
  description: 'Revitalizing Japan’s Akiya Homes and Communities',
  keywords: 'akiya, japan, real estate, abandoned homes, sustainable development',
  ogTitle: 'Akiya2.0',
  ogDescription: 'Revitalizing Japan’s Akiya Homes and Communities',
  ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
  url: 'https://www.akiya2.com/',
  ogUrl: 'https://www.akiya2.com/',
};


export const staticMetaTags = {
  '/': {
    title: 'Akiya2.0 - Buy Japan Houses and Invest in Akiya Renovation',
    description:
      'Discover Akiya2.0: Buy Japan houses and invest in akiya renovation. Revitalize abandoned akiya houses through sustainable investment.',
    keywords: 'akiya, japan houses, buy japan houses, akiya investment, akiya renovation, abandoned homes, sustainable development',
    ogTitle: 'Akiya2.0 - Buy Japan Houses and Invest in Akiya Renovation',
    ogDescription:
      'Discover Akiya2.0: Buy Japan houses and invest in akiya renovation. Revitalize abandoned akiya houses through sustainable investment.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/',
    ogUrl: 'https://www.akiya2.com/',
  },
  '/akiya-search': {
    title: 'Akiya2.0 Search - Find Akiya Houses for Sale in Japan',
    description:
      'Search Akiya2.0 to find akiya houses for sale in Japan. Buy and invest in akiya renovation across all prefectures.',
    keywords: 'akiya search, akiya houses for sale, japan properties, buy japan houses, akiya renovation, akiya investment',
    ogTitle: 'Akiya2.0 Search - Find Akiya Houses for Sale in Japan',
    ogDescription:
      'Search Akiya2.0 to find akiya houses for sale in Japan. Buy and invest in akiya renovation across all prefectures.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/akiya-search',
    ogUrl: 'https://www.akiya2.com/akiya-search',
  },
  '/services/how-it-works': {
    title: 'Akiya2.0 - How to Buy and Renovate Akiya Houses in Japan',
    description:
      'Learn how to buy and renovate akiya houses in Japan with Akiya2.0. We offer seamless services for your akiya investment and renovation projects.',
    keywords: 'akiya services, how it works, buy japan houses, akiya renovation, akiya investment, japan real estate',
    ogTitle: 'Akiya2.0 - How to Buy and Renovate Akiya Houses in Japan',
    ogDescription:
      'Learn how to buy and renovate akiya houses in Japan with Akiya2.0. We offer seamless services for your akiya investment and renovation projects.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/services/how-it-works',
    ogUrl: 'https://www.akiya2.com/services/how-it-works',
  },
  '/services/what-it-costs': {
    title: 'Akiya2.0 - Transparent Costs for Buying Japan Houses',
    description:
      'Discover the costs to buy and renovate akiya houses in Japan with Akiya2.0. Transparent pricing from purchase to renovation—no hidden fees.',
    keywords: 'akiya costs, buy japan houses, akiya renovation, akiya investment, japan real estate, transparent pricing',
    ogTitle: 'Akiya2.0 - Transparent Costs for Buying Japan Houses',
    ogDescription:
      'Discover the costs to buy and renovate akiya houses in Japan with Akiya2.0. Transparent pricing from purchase to renovation—no hidden fees.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/services/what-it-costs',
    ogUrl: 'https://www.akiya2.com/services/what-it-costs',
  },
  '/our-projects': {
    title: 'Akiya2.0 Projects - Revitalizing Akiya Houses in Japan',
    description:
      'Explore Akiya2.0\'s projects revitalizing akiya houses in Japan. Invest in akiya renovation and support sustainable community growth.',
    keywords: 'akiya projects, akiya renovation, akiya investment, community growth, japan houses',
    ogTitle: 'Akiya2.0 Projects - Revitalizing Akiya Houses in Japan',
    ogDescription:
      'Explore Akiya2.0\'s projects revitalizing akiya houses in Japan. Invest in akiya renovation and support sustainable community growth.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/our-projects',
    ogUrl: 'https://www.akiya2.com/our-projects',
  },
  '/invest-in-akiya': {
    title: 'Akiya2.0 - Invest in Akiya Houses in Japan',
    description:
      'Invest in akiya with Akiya2.0. Help revitalize Japan\'s abandoned houses into cultural treasures. Make a sustainable investment in Japan.',
    keywords: 'invest in akiya, akiya investment, buy japan houses, akiya renovation, japan houses',
    ogTitle: 'Akiya2.0 - Invest in Akiya Houses in Japan',
    ogDescription:
      'Invest in akiya with Akiya2.0. Help revitalize Japan\'s abandoned houses into cultural treasures. Make a sustainable investment in Japan.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/invest-in-akiya',
    ogUrl: 'https://www.akiya2.com/invest-in-akiya',
  },
  '/community': {
    title: 'Akiya2.0 Community - Join Our Mission in Japan',
    description:
      'Join Akiya2.0\'s community to revitalize akiya houses in Japan. Stay updated with news, blogs, and internships on akiya investment and renovation.',
    keywords: 'akiya community, akiya investment, akiya renovation, japan houses, internships',
    ogTitle: 'Akiya2.0 Community - Join Our Mission in Japan',
    ogDescription:
      'Join Akiya2.0\'s community to revitalize akiya houses in Japan. Stay updated with news, blogs, and internships on akiya investment and renovation.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/community',
    ogUrl: 'https://www.akiya2.com/community',
  },
  '/news': {
    title: 'Akiya2.0 News - Updates on Akiya Houses in Japan',
    description:
      'Stay updated with Akiya2.0\'s news on revitalizing akiya houses in Japan. Follow our journey in akiya investment and renovation.',
    keywords: 'akiya news, akiya investment, akiya renovation, japan houses, akiya banks',
    ogTitle: 'Akiya2.0 News - Updates on Akiya Houses in Japan',
    ogDescription:
      'Stay updated with Akiya2.0\'s news on revitalizing akiya houses in Japan. Follow our journey in akiya investment and renovation.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/news',
    ogUrl: 'https://www.akiya2.com/news',
  },
  '/about-us': {
    title: 'About Akiya2.0 - Revitalizing Japan Houses',
    description:
      'Learn about Akiya2.0\'s mission to revitalize akiya houses in Japan. Our team is dedicated to akiya renovation and sustainable community growth.',
    keywords: 'about akiya2.0, akiya renovation, akiya investment, japan houses, cultural heritage',
    ogTitle: 'About Akiya2.0 - Revitalizing Japan Houses',
    ogDescription:
      'Learn about Akiya2.0\'s mission to revitalize akiya houses in Japan. Our team is dedicated to akiya renovation and sustainable community growth.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/about-us',
    ogUrl: 'https://www.akiya2.com/about-us',
  },
  '/akiya-concierge': {
    title: 'Akiya2.0 Concierge - Your Guide to Buying Akiya Houses',
    description:
      'Discover Akiya2.0 Concierge, your personalized guide to buying and renovating akiya houses in Japan. Experience seamless akiya investment services.',
    keywords: 'akiya concierge, buy japan houses, akiya renovation, akiya investment, japan houses',
    ogTitle: 'Akiya2.0 Concierge - Your Guide to Buying Akiya Houses',
    ogDescription:
      'Discover Akiya2.0 Concierge, your personalized guide to buying and renovating akiya houses in Japan. Experience seamless akiya investment services.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/akiya-concierge',
    ogUrl: 'https://www.akiya2.com/akiya-concierge',
  },
  '/terms': {
    title: 'Akiya2.0 Terms of Use and Privacy Policy',
    description:
      'Read Akiya2.0\'s Terms of Use and Privacy Policy. Learn how we protect your data and our commitment to transparency.',
    keywords: 'terms of use, privacy policy, akiya2.0, akiya',
    ogTitle: 'Akiya2.0 Terms of Use and Privacy Policy',
    ogDescription:
      'Read Akiya2.0\'s Terms of Use and Privacy Policy. Learn how we protect your data and our commitment to transparency.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/terms',
    ogUrl: 'https://www.akiya2.com/terms',
  },
  '/kominka': {
    title: 'Akiya2.0 - Buy Japan\'s Kominka and Akiya Houses',
    description:
      'Discover special grade Kominkas across Japan, curated by Akiya2.0 and Kominka Smile. Buy these heritage homes directly from Kominka Smile.',
    keywords: 'kominka, traditional homes, buy japan houses, heritage homes, akiya renovation, akiya investment',
    ogTitle: 'Akiya2.0 - Buy Japan\'s Kominka and Akiya Houses',
    ogDescription:
      'Discover special grade Kominkas across Japan, curated by Akiya2.0 and Kominka Smile. Buy these heritage homes directly from Kominka Smile.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/kominka',
    ogUrl: 'https://www.akiya2.com/kominka',
  },
  '/blogs': {
    title: 'Akiya2.0 Blogs - Insights on Buying Akiya Houses',
    description:
      'Read Akiya2.0\'s blogs for insights on buying and renovating akiya houses in Japan. Get expert tips on akiya investment and renovation.',
    keywords: 'akiya blogs, akiya investment, akiya renovation, buy japan houses, abandoned houses japan',
    ogTitle: 'Akiya2.0 Blogs - Insights on Buying Akiya Houses',
    ogDescription:
      'Read Akiya2.0\'s blogs for insights on buying and renovating akiya houses in Japan. Get expert tips on akiya investment and renovation.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/blogs',
    ogUrl: 'https://www.akiya2.com/blogs',
  },
  '/special-property/SP0': {
    title: 'Akiya2.0 - Renovated Akiya House for Sale in Japan',
    description:
      'Buy a renovated akiya house in Japan with Akiya2.0. Traditional charm meets modern comfort—a ready-to-move-in home ideal for akiya investment.',
    keywords: 'akiya house for sale, buy japan houses, akiya renovation, akiya investment, renovated akiya house',
    ogTitle: 'Akiya2.0 - Renovated Akiya House for Sale in Japan',
    ogDescription:
      'Buy a renovated akiya house in Japan with Akiya2.0. Traditional charm meets modern comfort—a ready-to-move-in home ideal for akiya investment.',
    ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
    url: 'https://www.akiya2.com/special-property/SP0',
    ogUrl: 'https://www.akiya2.com/special-property/SP0',
  },
};

// export const staticMetaTags = {
//   '/': {
//     title: 'Akiya2.0 - Revitalizing Japan’s Akiya Houses and Communities',
//     description:
//       'Discover Akiya2.0’s mission to revitalize Japan’s akiya—abandoned Japanese houses—through sustainable investment and community projects.',
//     keywords: 'akiya, japan, real estate, abandoned homes, sustainable development, community projects, renovation',
//     ogTitle: 'Akiya2.0 - Revitalizing Japan’s Akiya Houses and Communities',
//     ogDescription:
//       'Discover Akiya2.0’s mission to revitalize Japan’s akiya—abandoned Japanese houses—through sustainable investment and community projects.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/',
//     ogUrl: 'https://www.akiya2.com/',
//   },
//   '/akiya-search': {
//     title: 'Akiya2.0 Search - Japan’s Akiya Banks, all compiled into one.',
//     description:
//       'Discover your dream home in Japan\'s 47 prefectures with Akiya2.0. Reviving traditional Akiya, making cheap old houses vibrant again. Start your journey with Akiya2.0.',
//     keywords: 'akiya search, japan properties, kominka, traditional homes, cheap houses, akiya',
//     ogTitle: 'Akiya2.0 Search - Japan’s Akiya Banks, all compiled into one.',
//     ogDescription:
//       'Discover your dream home in Japan\'s 47 prefectures with Akiya2.0. Reviving traditional Akiya, making cheap old houses vibrant again. Start your journey with Akiya2.0.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/akiya-search',
//     ogUrl: 'https://www.akiya2.com/akiya-search',
//   },
//   '/services/how-it-works': {
//     title: 'Akiya2.0 Services - How it all works.',
//     description:
//       'Akiya2.0: Experts in Japan\'s real estate. We handle buying, renovating, and managing akiyas. Seamless service from search to ownership. Start your journey with us!',
//     keywords: 'akiya services, how it works, real estate, japan, akiya renovation, akiya',
//     ogTitle: 'Akiya2.0 Services - How it all works.',
//     ogDescription:
//       'Akiya2.0: Experts in Japan\'s real estate. We handle buying, renovating, and managing akiyas. Seamless service from search to ownership. Start your journey with us!',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/services/how-it-works',
//     ogUrl: 'https://www.akiya2.com/services/how-it-works',
//   },
//   '/services/what-it-costs': {
//     title: 'Akiya2.0 Services - Transparent Pricing in every step.',
//     description:
//       'Akiya2.0: Transparent pricing from purchase to renovation. No hidden fees, just clear costs for owning and transforming cheap rural Japanese houses into your dream home.',
//     keywords: 'akiya costs, akiya, services pricing, japan real estate, renovation, akiya renovation',
//     ogTitle: 'Akiya2.0 Services - Transparent Pricing in every step.',
//     ogDescription:
//       'Akiya2.0: Transparent pricing from purchase to renovation. No hidden fees, just clear costs for owning and transforming cheap rural Japanese houses into your dream home.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/services/what-it-costs',
//     ogUrl: 'https://www.akiya2.com/services/what-it-costs',
//   },
//   '/our-projects': {
//     title: 'Akiya2.0 Projects - Our Rural Revitalisation projects in secondary tourism regions.',
//     description:
//       'Explore Akiya2.0’s projects: revitalizing Japan’s unwanted abandoned houses into serene living spaces. Invest in history and support sustainable community growth.',
//     keywords: 'akiya, akiya projects, revitalization, community growth, japan homes',
//     ogTitle: 'Akiya2.0 Projects - Our Rural Revitalisation projects in secondary tourism regions.',
//     ogDescription:
//       'Explore Akiya2.0’s projects: revitalizing Japan’s unwanted abandoned houses into serene living spaces. Invest in history and support sustainable community growth.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/our-projects',
//     ogUrl: 'https://www.akiya2.com/our-projects',
//   },
//   '/invest-in-akiya': {
//     title: 'Akiya2.0 Investment - Join in Akiya2.0’s Approach to Sustainable Real Estate.',
//     description:
//       'Invest in akiya with Akiya2.0: Help us revitalize these undervalued cheap offerings and turn them into cultural and monetary treasures, an investment into Japan’s rich traditions. Join us in this journey!',
//     keywords: 'invest in akiya, akiya, japan properties, revitalization, japan cheap houses',
//     ogTitle: 'Akiya2.0 Investment - Join in Akiya2.0’s Approach to Sustainable Real Estate.',
//     ogDescription:
//       'Invest in akiya with Akiya2.0: Help us revitalize these undervalued cheap offerings and turn them into cultural and monetary treasures, an investment into Japan’s rich traditions. Join us in this journey!',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/invest-in-akiya',
//     ogUrl: 'https://www.akiya2.com/invest-in-akiya',
//   },
//   '/community': {
//     title: 'Akiya2.0 Community - Keep up with news, blogs and internships on Akiya2.0.',
//     description:
//       'Join Akiya2.0\'s mission to save Japan\'s cultural heritage by reviving cheap abandoned akiyas. Stay connected with our community through news, blogs, and internships.',
//     keywords: 'akiya community, cultural heritage, japan, internships, akiya',
//     ogTitle: 'Akiya2.0 Community - Keep up with news, blogs and internships on Akiya2.0.',
//     ogDescription:
//       'Join Akiya2.0\'s mission to save Japan\'s cultural heritage by reviving cheap abandoned akiyas. Stay connected with our community through news, blogs, and internships.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/community',
//     ogUrl: 'https://www.akiya2.com/community',
//   },
//   '/news': {
//     title: 'Akiya2.0 News - Spotlight on Revitalizing Japan’s Akiya Properties.',
//     description:
//       'Akiya2.0: Making waves in the media for our efforts to revive Japan’s cheap abandoned houses. Stay updated on our journey to restore them into unique cultural investments.',
//     keywords: 'akiya news, media features, japan real estate, akiya',
//     ogTitle: 'Akiya2.0 News - Spotlight on Revitalizing Japan’s Akiya Properties.',
//     ogDescription:
//       'Akiya2.0: Making waves in the media for our efforts to revive Japan’s cheap abandoned houses. Stay updated on our journey to restore them into unique cultural investments.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/news',
//     ogUrl: 'https://www.akiya2.com/news',
//   },
//   '/about-us': {
//     title: 'About Akiya2.0 - Meet the team behind Akiya2.0.',
//     description:
//       'Meet Akiya2.0: A dedicated team on a mission to preserve Japan’s cultural heritage by restoring traditional akiyas and fostering sustainable communities.',
//     keywords: 'about akiya2.0, cultural heritage, japan, akiya',
//     ogTitle: 'About Akiya2.0 - Meet the team behind Akiya2.0.',
//     ogDescription:
//       'Meet Akiya2.0: A dedicated team on a mission to preserve Japan’s cultural heritage by restoring traditional akiyas and fostering sustainable communities.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/about-us',
//     ogUrl: 'https://www.akiya2.com/about-us',
//   },
//   '/akiya-concierge': {
//     title: 'Akiya2.0 Concierge - Key to the Akiya2.0 Travel Club experience.',
//     description:
//       'Meet Akiya2.0: A dedicated team working tirelessly to preserve Japan’s cultural heritage by restoring traditional akiyas and fostering sustainable communities.',
//     keywords: 'akiya concierge, services, japan, akiya',
//     ogTitle: 'Akiya2.0 Concierge - Key to the Akiya2.0 Travel Club experience.',
//     ogDescription:
//       'Meet Akiya2.0: A dedicated team working tirelessly to preserve Japan’s cultural heritage by restoring traditional akiyas and fostering sustainable communities.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/akiya-concierge',
//     ogUrl: 'https://www.akiya2.com/akiya-concierge',
//   },
//   '/terms': {
//     title: 'Akiya2.0 Terms of Use and Privacy Policy.',
//     description:
//       'Read Akiya2.0\'s Terms of Use and Privacy Policy to know how we collect, use, and protect your data. Learn your rights and our commitment to transparency.',
//     keywords: 'terms of use, privacy policy, akiya2.0, akiya',
//     ogTitle: 'Akiya2.0 Terms of Use and Privacy Policy.',
//     ogDescription:
//       'Read Akiya2.0\'s Terms of Use and Privacy Policy to know how we collect, use, and protect your data. Learn your rights and our commitment to transparency.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
//     url: 'https://www.akiya2.com/terms',
//     ogUrl: 'https://www.akiya2.com/terms',
//   },
//   '/kominka': {
//     title: 'Akiya2.0 - Kominka Smile Recommendations',
//     description:
//       'Discover special grade Kominkas across Japan, curated by Akiya2.0 and Kominka Smile. Buy these heritage homes directly from Kominka Smile.',
//     keywords: 'kominka, traditional homes, japan properties, heritage homes, kominka smile, Akiya2.0 partnership, akiya',
//     ogTitle: 'Akiya2.0 - Kominka Smile Recommendations',
//     ogDescription:
//       'Explore special grade Kominkas in Japan, available through Akiya2.0’s partnership with Kominka Smile. Purchase these heritage homes directly.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',  // Default image for now
//     url: 'https://www.akiya2.com/kominka',
//     ogUrl: 'https://www.akiya2.com/kominka',
//   },
//   '/blogs': {
//     title: 'Akiya2.0 Blogs - Insights and Updates on Japan’s Akiya Properties',
//     description:
//       'Stay tuned to Akiya2.0’s blog for daily updates on abandoned akiyas across Japan. Get expert insights and tips on owning your own akiya.',
//     keywords: 'akiya, kominka, cheap houses, japan cheap houses, japan, house, abandoned houses japan',
//     ogTitle: 'Akiya2.0 Blogs - Insights and Updates on Japan’s Akiya Properties',
//     ogDescription:
//       'Stay tuned to Akiya2.0’s blog for daily updates on abandoned akiyas across Japan. Get expert insights and tips on owning your own akiya.',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',  // Default image for now
//     url: 'https://www.akiya2.com/blogs',
//     ogUrl: 'https://www.akiya2.com/blogs',
//   },
//   '/special-property/SP0': {
//     title: 'Fully Renovated and Furnished Akiya - Akiya2.0 Special Property for Sale in Japan',
//     description:
//       'Discover an exclusive opportunity with Akiya2.0’s fully renovated and furnished akiya. This beautifully restored property combines traditional Japanese charm with modern comforts, offering you a ready-to-move-in home in Japan. Perfect for those seeking a unique investment or a serene residence',
//     keywords: 'akiya, kominka, cheap houses, japan cheap houses, japan, house, abandoned houses japan',
//     ogTitle: 'Fully Renovated and Furnished Akiya - Akiya2.0 Special Property for Sale in Japan',
//     ogDescription:
//       'Discover an exclusive opportunity with Akiya2.0’s fully renovated and furnished akiya. This beautifully restored property combines traditional Japanese charm with modern comforts, offering you a ready-to-move-in home in Japan. Perfect for those seeking a unique investment or a serene residence',
//     ogImage: 'https://www.akiya2.com/images/MainLogo.webp',  // Default image for now
//     url: 'https://www.akiya2.com/special-property/SP0',
//     ogUrl: 'https://www.akiya2.com/special-property/SP0',
//   },
// };


// Function to strip HTML tags from a string
function stripHtmlTags(content) {
  return content.replace(/<\/?[^>]+(>|$)/g, ''); // Regular expression to remove HTML tags
}
function sanitizeHtmlContent(content) {
  // Remove <meta>, <title>, and <style> tags and their content
  const sanitizedContent = content
    .replace(/<meta[^>]*>/gi, '')   // Remove <meta> tags
    .replace(/<title[^>]*>.*<\/title>/gi, '')   // Remove <title> tags and content
    .replace(/<style[^>]*>.*<\/style>/gi, '')   // Remove <style> tags and content
    .replace(/<\/?[^>]+(>|$)/g, '');  // Remove all remaining HTML tags

  return sanitizedContent.trim();
}

export const dynamicMetaTagGenerators = {
  '/blogs/:id/:slug': async (params) => {
    const blog = await fetchBlogData(params.id);
    if (!blog){
      // console.log("failed blog");
      return defaultMetaTags;
    } 

    const sanitizedContent = sanitizeHtmlContent(blog.blog_content);
    const description = sanitizedContent
      ? `${sanitizedContent.split(' ').slice(0, 22).join(' ')}...`
      : '';

    return {
      title: blog.blog_heading,
      description: description,
      keywords: blog.keywords || 'akiya, japanese akiya, japanese cheap houses, renovation, japanese abandoned houses, japan',
      ogTitle: blog.blog_heading,
      ogDescription: description,
      ogImage: blog.coverImage,
      url: `https://www.akiya2.com/blogs/${params.id}/${params.slug}`,
      ogUrl: `https://www.akiya2.com/blogs/${params.id}/${params.slug}`,
    };
  },

  '/prefecture-listing/:prefectureName': async (params) => {
    const prefectureName = params.prefectureName;
    return {
      title: `${prefectureName} akiya listings in Japan`,
      description: `Explore exclusive Akiya listings from municipal websites in ${prefectureName}, a prime region for traditional Japanese homes and cultural heritage.`,
      keywords: `${prefectureName}, akiya listings, japan, traditional homes`,
      ogTitle: `${prefectureName} akiya listings in Japan`,
      ogDescription: `Explore exclusive Akiya listings from municipal websites in ${prefectureName}.`,
      ogImage: 'https://www.akiya2.com/images/MainLogo.webp',
      url: `https://www.akiya2.com/prefecture-listing/${prefectureName}`,
      ogUrl: `https://www.akiya2.com/prefecture-listing/${prefectureName}`,
    };
  },
  '/individual-akiya/:akiyaId': async (params) => {
    // Fetch the Akiya data from the backend
    const akiya = await fetchAkiyaData(params.akiyaId);
    
    if (!akiya) return defaultMetaTags;
  
    // Extract municipality name and serial number from property_id
    const propertyIdParts = akiya.property_id.split('-');
    
    // The third part is the municipality name, capitalized
    const municipalityName = propertyIdParts[2];
    
    // The last part (fourth) is a string number, so we increment it by 1 to get the serial number
    const serialNo = (parseInt(propertyIdParts[3], 10) + 1).toString();
  
    return {
      title: `${municipalityName}’s Listing No:${serialNo}`,
      description: `Explore traditional Japanese houses in ${municipalityName}, compiled and translated by the Akiya2.0 team.`,
      keywords: `${municipalityName}, akiya, listing, traditional home, heritage`,
      ogTitle: `${municipalityName}’s Listing No:${serialNo}`,
      ogDescription: `Explore traditional Japanese houses in ${municipalityName}, compiled and translated by Akiya2.0.`,
      ogImage: akiya.Images && akiya.Images.length > 0 ? akiya.Images[0] : 'https://www.akiya2.com/images/MainLogo.webp',
      url: `https://www.akiya2.com/individual-akiya/${akiya.property_id}`,
      ogUrl: `https://www.akiya2.com/individual-akiya/${akiya.property_id}`,
    };
  },
  '/property/:propertyId': async (params) => {
    const property = await fetchPropertyData(params.propertyId);
    if (!property) return defaultMetaTags;
    return {
      title: property.propertyName,
      description: property.smallAbout,
      keywords: `${property.serialNumber} a property of Akiya2.0, akiya` || 'A property of Akiya2.0, akiya',
      ogTitle: property.propertyName,
      ogDescription: property.smallAbout,
      ogImage:
        property.images && property.images.length > 0
          ? property.images[0]
          : 'https://www.akiya2.com/images/MainLogo.webp',
      url: `https://www.akiya2.com/property/${params.propertyId}`,
      ogUrl: `https://www.akiya2.com/property/${params.propertyId}`,
    };
  },
  '/individual_kominka/:kominka_id': async (params) => {
    // Fetch the Kominka data from the backend
    const kominka = await fetchKominkaData(params.kominka_id); // Assuming you have a fetch function for this
  
    if (!kominka) return defaultMetaTags;
  
    const { prefectureName, cityName, price, built, mainLink, images } = kominka;
  
    return {
      title: `${cityName}, ${prefectureName} - Kominka Listing No:${kominka.post_id}`,
      description: `Explore this beautiful Kominka in ${cityName}, ${prefectureName}, built about ${built}. Priced at ¥${price}, available for purchase on Kominka Smile.`,
      keywords: `${prefectureName}, ${cityName}, kominka, traditional homes, Japan properties, heritage homes, Akiya2.0 partnership, kominka listings, akiya`,
      ogTitle: `${cityName}, ${prefectureName} - Kominka Listing No:${kominka.post_id}`,
      ogDescription: `Beautiful traditional Kominka in ${cityName}, ${prefectureName}. Priced at ¥${price}, built about ${built}, available on Kominka Smile.`,
      ogImage: images && images.length > 0 ? `https://app.akiya2.com/${images[0]}` : 'https://www.akiya2.com/images/MainLogo.webp',
      url: `https://www.akiya2.com/individual_kominka/${kominka.post_id}`,
      ogUrl: `https://www.akiya2.com/individual_kominka/${kominka.post_id}`,
    };
  },
};

export async function getMetaTags(path, params = {}) {

  // Remove trailing slash if not root
  if (path.length > 1 && path.endsWith('/')) {
    path = path.slice(0, -1);
  }
  
  // Check if it's a static route
  if (staticMetaTags[path]) {
    return staticMetaTags[path];
  }

  // Check if it's a dynamic route
  for (const [route, generator] of Object.entries(dynamicMetaTagGenerators)) {
    const match = matchPath(route, path);
    if (match) {
      return await generator(match.params);
    }
  }
  // console.log("No match found")
  // Return default tags if no match found
  return defaultMetaTags;
}

// Simple path matching function
function matchPath(route, path) {
  const routeParts = route.split('/');
  const pathParts = path.split('/');

  if (routeParts.length !== pathParts.length) return null;

  const params = {};
  for (let i = 0; i < routeParts.length; i++) {
    if (routeParts[i].startsWith(':')) {
      params[routeParts[i].slice(1)] = pathParts[i];
    } else if (routeParts[i] !== pathParts[i]) {
      return null;
    }
  }

  return { params };
}

// module.exports = { getMetaTags };
export default getMetaTags;