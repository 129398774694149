import React from "react";

const ImgContent = ({ data }) => {
  const { image, title, subtitle, content } = data;
  return (
    <div className="flex flex-col md:flex-row w-full">
      {/* Image Section */}
      <div className="w-full md:w-1/2 flex justify-center items-center mb-4 md:mb-0">
        <img
          src={image}
          className="w-full h-64 object-cover"
          alt="Akiya and Akiya2.0"
        />
      </div>
      {/* Text Content */}
      <div className="w-full md:w-1/2 flex flex-col justify-center px-4">
        <h1 className="text-2xl font-bold mt-3">{title}</h1>
        <h2 className="text-[#5ab963] text-2xl font-light">{subtitle}</h2>
        <p className="text-justify mt-4 text-[#949995] text-base font-normal leading-6">
          {content}
        </p>
      </div>
    </div>
  );
};

export default ImgContent;