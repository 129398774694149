import React from "react";
import Internshipform from "../Form/InternshipForm";
import Mid from "../Middle/Mid";
import HeadingforSmall from "../../HeadingforSmall/HeadingforSmall";
import Card from "../InfoCards/infocards";
import Box from "../Boxes/Box";
import InternshipCard from "../Interncard/InternshipCard";
// // console.log("================================")
// // console.log("Internshipform",Internshipform)
// // console.log("Middle",Middle)
// // console.log("HeadingforSmall",HeadingforSmall)
// // console.log("Card",Card)
// // console.log("Boxes",Boxes)
// // console.log("InternCard",InternCard)
// // console.log("================================")

function Intern() {
  const secondHeading = "Akiya 2.0 Internship Programmes";
  return (
    <div>
      <HeadingforSmall bigHeading={secondHeading} />

      <div className="w-[100vw] flex justify-center">
        <div className=" max-w-[1440px] lg:mx-[3rem]  md:mx-[2rem] mx-[1rem] flex flex-col gap-8  ">
          <Mid />
          <Card />
          <Box />
          <InternshipCard />
          <Internshipform />
        </div>
      </div>
    </div>
  );
}

export default Intern;
