import React, { useEffect } from "react";
import TeamList from "./TeamList";

const terrie = "/images/about/terrie.webp";
const lester = "/images/about/lester.webp";

function SecondSOV() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#founders") {
      const element = document.getElementById("founders");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const head = "Introducing the Akiya2.0 team";
  const para =
    "We've been in Japan for a long time, and we think we know a thing or two about this beautiful country. Our Tokyo-based team combines a local expertise and a deep network with a global perspective and outreach to guide you in the world's most complicated bureaucracy and achieve your countryside Akiya dreams.";

  const founderInfo = [
    {
      name: "Terrie Lloyd",
      work: "Co-founder, CEO",
      linkedin: "https://www.linkedin.com/in/terrielloyd/",
      image: terrie,
      about:
        "Terrie is a 65-year dual-national of Australia and New Zealand, who has lived in Japan for over 40 years. During that time he has established 19 companies of his own in Japan and many others for clients. He specializes in business development, problem solving, recruiting talent, sales and networking.",
    },
    {
      name: "Lester Goh",
      work: "Co-founder, COO",
      linkedin: "https://www.linkedin.com/in/lestergohjy/",
      image: lester,
      about:
        "Lester is a 31-year old national of Singapore, who has lived in Japan for nearly 5 years. He was trained as a professional architect, graduating with a Masters of Architecture and has accrued a portfolio of award-winning built works across Japan and Taiwan. He is effectively trilingual in English, Mandarin and Japanese.",
    },
  ];

  return (
    <div id="founders" className="my-8">
      <h2 className="dark-header mb-4">{head}</h2>
      <p className="light-header">{para}</p>

      <div className="flex flex-wrap justify-around mt-8">
        {founderInfo.map((founder) => (
          <TeamList {...founder} key={founder.name} />
        ))}
      </div>
    </div>
  );
}

export default SecondSOV;