import React,{useState, useEffect} from "react";
import useIndivdiualAkiyaHook from "../individualKominkaAkiya/useIndividualAkiyaHook";
import AkiyaGallery from "./AkiyaGallery";
import Heart from "../Svgs/Heart";
import MapIComponent from "./MapIndividual";
import Loader from "../Loader/Loader";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

let currentUrl = '';

if (typeof window !== 'undefined') {
  currentUrl = window.location.href;  // This runs only in the browser
}
const IndiviualKominkaAkiya = () => {

  const {
    individualAkiya,
    setIndividualAkiya,
    tableData,
    municipality,
    property_no,
    handleToggleFavorite,
    isFavorite,
  } = useIndivdiualAkiyaHook();
  
  const links = [
    {
      name: "LinkedIn",
      link: `https://www.linkedin.com/shareArticle?url=${currentUrl}`,
      icon: <FaLinkedin />,
    },
    {
      name: "Twitter",
      link: `https://twitter.com/intent/tweet?url=${currentUrl}`,
      icon: <FaXTwitter />,
    },
  ];
  return (
    <div className="flex justify-center">
      <div className="max-w-[1080px] w-[1080px] mx-2  h-full lg:px-10 md:px-5 sm:px-2 px-2  pb-4  ">
        <div className=" ">
          {individualAkiya && individualAkiya.images ? (
            <AkiyaGallery images={individualAkiya.images} />
          ) : (
            <Loader big={false} />
          )}
        </div>


        <div className="maps_indi">
          {individualAkiya && <div >
          <MapIComponent
            apiKey={"AIzaSyDhd_bCluPIx2cSHS7pGd5wVPkIRU7rpxQ&"}
            coordinates={individualAkiya.Coordinate}
          />
        </div>}

        </div>
        <div className="w-full h-[6rem] flex items-center justify-center">
          <p className=" ml-2 w-full md:text-5xl font-bold sm:text-3xl text-2xl  items-center">{"Kominka Smile Listing"}</p>
          <div className="w-[60%] flex justify-end mr-5">
            
            <div className="  opacity-100 ">
              <button
                onClick={handleToggleFavorite}
                className={`${isFavorite ? " text-[#e09321]  " : "text-white "} px-2 m-1  stroke-[#e09321] `}
              >
                <div className="w-16 h-16  ">
                  <Heart />
                </div>
              </button>
            </div>
            
          </div>
          
        </div>
                        {/* Share links */}
          <div className="flex justify-start mx-3 mb-3">
          <p className="text-[18px] leading-[20px] font-[400]">Share on:</p>
          {links.map((x) => (
            <Link to={x.link} className="mx-2">
              <div className="flex justify-around items-center">
                <div>{x.icon}</div>
                <p className="text-[#999] px-1 text-[18px] leading-[20px]">
                  {x.name}
                </p>
              </div>
            </Link>
          ))}
        </div>
        {/* link share end */}


        <div className="w-full ">
          <div className="w-full  border-[5px] border-[#e09321] shadow-2xl shadow-[#e09321]  ">
            <div className="h-[4rem] text-2xl px-3 flex items-center text-white bg-[#e09321]">
              Listing Details
            </div>
            {/* {console.log(tableData)} */}
            {tableData &&
              Object.entries(tableData).map(([field, value]) => (
                <div className="flex gap-2 border-b-2 md:py-3 py-1   sm:text-lg font-extralight  text-[#333333] ">
                  <div className="w-[30%] pl-2 flex">{field}</div>
                  <div className="w-[65%]">
                    {field === "Link to Official page" && value !== "N/A" ? (
                      <button
                        onClick={() => window.open(value, "_blank")}
                        className={`custom-button bg-[#e09321] hover:bg-[#916420] inline-block rounded-md text-white font-semibold py-2 px-4`}
                      >
                        <span>{`Official site`}</span>
                      </button>
                    ) : (
                      <p className="text-[#333333]">{value}</p>
                    )}
                  </div>
                </div>
              ))}
            {}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndiviualKominkaAkiya;
