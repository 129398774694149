const testimonials = [
    {
      id: 1,
      text: "Having a car at the ready makes all the difference in the world. I would never have been able to visit Noto if this service was not available!",
      author: "Conan O'Brien",
      image: "https://assets-in.bmscdn.com/iedb/artist/images/website/poster/large/conan-o-brien-iein000404-31-03-2017-13-04-26.jpg"
    },
    {
      id: 2,
      text: "The convenience and reliability of this service have truly transformed my travel experiences. It's a game-changer!",
      author: "Emma Watson",
      image: "https://image.tmdb.org/t/p/original/dMbd2Rx9ZD5Gl9VXAkGcIKGrAxJ.jpg"
    },
    {
      id: 3,
      text: "I'm impressed by the seamless booking process and the quality of vehicles. It's made my business trips so much easier.",
      author: "Elon Musk",
      image: "https://ichef.bbci.co.uk/images/ic/1200x675/p03c84wz.jpg"
    },
    {
      id: 4,
      text: "As a frequent traveler, I can confidently say this service has exceeded my expectations. The flexibility it offers is unmatched.",
      author: "Serena Williams",
      image: "https://static01.nyt.com/images/2019/09/09/opinion/09Hunter1/09Hunter1-superJumbo.jpg"
    }
  ];
  
  export default testimonials;